import axios from 'axios';
import { createHash } from 'crypto';
import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getAuth = () => cookies.get('AUTH');

export const setAuth = (authObject) => {
    cookies.set('AUTH', JSON.stringify(authObject), { path: '/', secure: true });
    return authObject;
};

export const removeAuth = () => {
    cookies.remove('AUTH', { path: '/' });
};

export const isInRole = (role, user) => user.roles && user.roles.includes(role);

export const isAuthenticated = (user) => user != null && user.token;

export const removeSession = () => {
    removeAuth();

    const redirectUri = `/login?redirect_uri=${window.location.pathname}`;

    return window.location.replace(redirectUri);
};

export const isTokenExpired = (auth) => {
    const { token } = auth;

    if (token) {
        const decodedJwt = jwt_decode(token);

        if (decodedJwt.exp * 1000 < Date.now()) {
            removeAuth();
            return true;
        }
    }
    return false;
};

export const isInvalidSession = async (auth) => {
    const { id, token } = auth;
    const tokenHash = createHash('sha256').update(token).digest('hex');
    const url = `${process.env.NEXT_PUBLIC_API_URL}/user-session?userId=${id}&tokenHash=${tokenHash}`;
    return axios.get(url).then(() => false).catch(() => true);
};

export const isUnauthorizedRequest = (auth) => !auth || !isAuthenticated(JSON.parse(auth)) || isTokenExpired(JSON.parse(auth)) || isInvalidSession(JSON.parse(auth));
