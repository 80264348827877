import { useState } from "react"
import { Icon, Text } from "../atoms"

const icon = {
    cube: '/images/cubeve1.svg',
    vendor: '/images/vendorv1.svg'
}

const ShowAndHideData = ({ data, type = 'cube', className }) => {
    const [showItem, setShowItem] = useState(false)
    return (
        <div className={`${className}`}>
            <div className="flex items-center gap-1.5">
                <div className="flex items-center gap-x-1.5">
                    <Icon src={`${icon[type]}`} />
                    {data.slice(0, 2).map((e, index) => (
                        <div className="flex items-center gap-x-1" key={index}>
                            <Text type="sm400">{e.name}</Text>
                            {index !== data.slice(0, 2).length - 1 && (
                                <div className="h-[3px] w-[3px] bg-Black-300 rounded-full flex-shrink-0"></div>
                            )}
                        </div>
                    ))}
                </div>
                {data.length > 2 && <div onClick={() => setShowItem(!showItem)} className="flex items-center gap-x-px">
                    <Text type="sm400" className='!font-medium'>+{data.length - 2} more</Text>
                    <Icon src="/images/Chevron-downv2.svg" className={`${showItem ? 'rotate-180' : ''}`} />
                </div>}
            </div>
            {showItem && (
                <div className="flex items-center gap-x-1.5">
                    <Icon src="/images/cubeve1.svg" className="invisible" />
                    {data.slice(2).map((e, index) => (
                        <div className="flex items-center gap-x-1" key={index}>
                            <Text type="sm400">{e.name}</Text>
                            {index !== data.length - 3 && (
                                <div className="h-[3px] w-[3px] bg-Black-300 rounded-full flex-shrink-0"></div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default ShowAndHideData