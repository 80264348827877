/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    vendorTncValues: {},
    customerTncValues: {},
    isVendorTncChanged: false,
    isCustomerTncChanged: false
};

const tncSlice = createSlice({
    name: 'tnc',
    initialState,
    reducers: {
        updateVendorTnc(state, action) {
            state.vendorTncValues = { ...action.payload.vendorTncValues };
            state.isVendorTncChanged = action.payload.isVendorTncChanged;
        },
        updateCustomerTnc(state, action) {
            state.customerTncValues = { ...action.payload.customerTncValues };
            state.isCustomerTncChanged = action.payload.isCustomerTncChanged;
        }
    }
});

export const tncActions = tncSlice.actions;

export default tncSlice.reducer;
