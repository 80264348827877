import { Button, Icon } from '../atoms';
import Text from '../atoms/Text';

const CommonModal = ({
    onCancel, children, isAction = false, borderBottom = true, isCrossIcon, isCloseIcon = true, className, containerClassName = '', z, bg, heading, bottom, contentClassName, isStatic = true, mx, animationSlideClass = 'animate-slideToTop', maxWidth, pb, mb, buttonName, disableSubmit = false, ...props
}) => (
    <div className={`fixed z-30 inset-0 overflow-y-auto  ${!props.show && 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="flex items-center justify-center h-screen">
            <div className={`fixed inset-0 ${!props.notBlur && 'bg-gray-500'} bg-opacity-75 transition-opacity" aria-hidden="true`}></div>
            <div className={`relative z-40 pt-5 mx-4 overflow-hidden inline-block rounded-2xl ${borderBottom ? ' border-b-[7px] border-primary-502' : ''} bg-white text-left shadow-xl  align-middle max-h-[80vh] min-h-[80vh] w-full max-w-[800px] ${props.width ? props.width : ''} ${props.className} `}>
                <div className='w-full border-gray-200 bg-white px-7'>
                    <div className='flex justify-between items-center w-full border-b pb-5' >
                        <Text className="text-lg font-bold text-Black-300 ManropeFontFamily">{heading}</Text>
                        <Icon onClick={onCancel} className='cursor-pointer' src={` ${isCrossIcon ? '/images/Closev1.svg' : '/images/cancelWithCircle.svg'}`} />
                    </div>
                </div>
                <div id='scrollableDiv' className={`overflow-y-auto ${props.maxHeight ? props.maxHeight : 'max-h-[80vh]'} h-auto modalscrollbar mt-6 ${pb} ${containerClassName}`}>
                    {children}
                    {isAction && <div className='bg-white w-full absolute z-50 bottom-0 right-0 pb-6 flex justify-end pr-6 '><Button onClick={props.onSubmit} variant={disableSubmit ? 'PrimaryNewDisabled' : 'PrimaryNew'} disabled={disableSubmit} >{buttonName}</Button></div>}
                </div>

            </div>
        </div>
    </div>
);

export default CommonModal;
