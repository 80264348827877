import { Button } from '@/atoms';
import { reqCqSoPoPage } from '@/helpers/constants';
import { DocumentManagerEvents } from '@/helpers/mixpanel-events';
import DealSearchPopup from '@/organism/DealSearchPopup';
import { handleApiResponse } from '@/services/helper-function.service';
import { track } from '@/services/mixpanel.service';
import { getDealSearchRequirementDetails, getDealSearchRequirements } from '@/services/requirement.service';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import Avatar from '../../atoms/Avatar';
import ProfileDropdown from '../../molecules/ProfileDropdown';
import { getAuth } from '../../services/identity.service';
import { getSidebarConfig } from '../Sidebar/SidebarConfiguration';

const getFilterResults = async (dealSearchValue, skip, limit, reqCqSoPoId) => {
    const res = await getDealSearchRequirements({
        search: dealSearchValue, offset: skip, limit, reqCqSoPoId
    });
    const { details, apiError } = handleApiResponse(res);
    return { details, apiError };
};
function Header({ className }) {
    const router = useRouter();
    const [show, setShow] = useState(false);
    const [user, setUser] = useState();
    const [showDealSearch, setShowDealSearch] = useState(false);
    const [dealSearchValue, setDealSearchValue] = useState('');
    const drawerRef = useRef(null);
    const [dropdownStatus, setDropdownStatus] = useState(false);
    const [requirements, setRequirements] = useState({ count: 0, rows: [] });
    const [hasMore, setHasMore] = useState(true);
    const [showRequirementDetails, setShowRequirementDetails] = useState(null);
    const [sidebarItems, setSidebarItems] = useState([]);
    const [lastSearch, setLastSearch] = useState();
    const showSideMenu = (e) => {
        e.stopPropagation();
        setShow(!show);
    };
    const onSearch = async (value, openDealSearchPopUp, reqCqSoPoId) => {
        if (!openDealSearchPopUp && value) {
            setLastSearch(false);
        }
        if (value?.length > 3) {
            const { details, apiError } = await getFilterResults(value, 0, 10, reqCqSoPoId);
            if (apiError.length === 0) {
                // Function to check if any ID matches reqCqSoPoId
                const matchesReqCqSoPoId = (req) => {
                    if (req.id === reqCqSoPoId) return true;
                    if (req.salesOrder?.id === reqCqSoPoId) return true;
                    if (req.customerQuotation?.id === reqCqSoPoId) return true;
                    if (req.purchaseOrder.some((po) => po.id === reqCqSoPoId)) return true;
                    return false;
                };
                // Sorting so that the record with matching reqCqSoPoId comes first
                const ordered = details.rows.sort((a, b) => {
                    const aMatches = matchesReqCqSoPoId(a);
                    const bMatches = matchesReqCqSoPoId(b);
                    return (aMatches === bMatches) ? 0 : aMatches ? -1 : 1;
                });
                setRequirements({ count: details.count, rows: ordered });
            }
        }
    };
    const setLocalStorage = () => {
        if (dealSearchValue) {
            localStorage.setItem('dealSearchHistory', dealSearchValue);
        }
    };
    const onDealSearchClick = () => {
        setShowDealSearch((d) => {
            if (d) {
                // setLocalStorage()
                setRequirements({ count: 0, rows: [] });
                setShowRequirementDetails(null);
                setLastSearch(false);
            } else {
                const value = localStorage?.getItem('dealSearchHistory') || '';
                setDealSearchValue();
                setLastSearch(value);
                const isOnReqCqSoPoPage = reqCqSoPoPage.includes(router.pathname);
                if (isOnReqCqSoPoPage) {
                    onSearch(value, true, router.query.id);
                } else {
                    onSearch(value, true);
                }
            }
            return !d;
        });
        track(DocumentManagerEvents.Deal_Search);
    };

    const handleClick = (items) => {
        if (items.type === 'atomic') {
            router.push(items.url);
        }
    };
    const handleClose = () => {
        setShow(false);
    };
    const fetchMoreData = async () => {
        const skip = requirements.rows.length;
        const limit = 10;
        const { details, apiError } = await getFilterResults(dealSearchValue, skip, limit);
        if (apiError.length === 0) {
            setRequirements((old) => ({
                count: details.count,
                rows: [...old.rows, ...details.rows]
            }));
            setHasMore(requirements.rows.length < details.count);
        }
    };
    const onRequirementClick = async (requirement) => {
        const res = await getDealSearchRequirementDetails(requirement.id);
        const { details, apiError } = handleApiResponse(res);
        if (apiError.length === 0) {
            setShowRequirementDetails(details);
        }
    };
    useEffect(() => {
        const auth = getAuth();
        if (auth) {
            setUser(auth);
            setSidebarItems(getSidebarConfig('ADMIN'));
        }

        document.addEventListener('click', handleClose, false);
        return () => {
            document.removeEventListener('click', handleClose, false);
        };
    }, []);

    const handleClickOutside = () => {
        setDropdownStatus(false);
    };
    useEffect(() => {
        let timeoutId;
        const debounce = (func, delay) => function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func.apply(this, args), delay);
        };
        const debouncedSearch = debounce(onSearch, 500);
        debouncedSearch(dealSearchValue);
        return () => clearTimeout(timeoutId);
    }, [dealSearchValue]);
    return (
        <div className={`flex w-full h-20 items-center shadow-sm  bg-white  ${className} `}>

            {dropdownStatus && (
                <div ref={drawerRef}>
                    <ProfileDropdown
                        setDropdownStatus={setDropdownStatus}
                        dropdownStatus={dropdownStatus}
                        handleClickOutside={handleClickOutside}
                        userName={user?.fullName}
                    />
                </div>
            )}
            {
                showDealSearch
                && <DealSearchPopup
                    showDealSearch={showDealSearch}
                    onDealSearchClick={onDealSearchClick}
                    showRequirementDetails={showRequirementDetails}
                    isNoSearch
                    setDealSearchValue={setDealSearchValue}
                    dealSearchValue={dealSearchValue}
                    hasMore={hasMore}
                    fetchMoreData={fetchMoreData}
                    requirements={requirements}
                    onRequirementClick={onRequirementClick}
                    setShowRequirementDetails={setShowRequirementDetails}
                    setLocalStorage={setLocalStorage}
                    lastSearch={lastSearch}
                    isOnReqCqSoPoPage={reqCqSoPoPage.includes(router.pathname)}
                />
            }
            <div className={'flex justify-end w-full px-2 lg:px-7 items-center'} >
                <div className={'flex flex-row gap-3 lg:gap-4 items-center'}>
                    <Button variant='Ghostv1' onClick={onDealSearchClick}>Deal search</Button>
                    <Avatar src={user?.picture} className="h-8 w-8 cursor-pointer" onClick={() => setDropdownStatus(!dropdownStatus)} />
                </div>
            </div>
        </div>
    );
}

export default Header;
