import {
    deleteWithAuth, getWithAuth, getWithAuthWithToken, postWithAuth, putWithAuth
} from '@/services/http.service';
import {
    ADD_REQUIREMENT,
    ADD_REQUIREMENT_ITEM,
    CREATE_DEAL_ID_FOR_REQUIREMENT,
    CREATE_RFQ_AND_CUSTOMER_QUOTATION, DELETE_CUSTOMER_QUOTATION_ITEM, DELETE_REQUIREMENT_ITEM, GET_ALL_DEAL_NUMBERS, GET_DEAL_SEARCH_REQUIREMENT, GET_DEAL_SEARCH_REQUIREMENT_DETAILS, GET_REQUIREMENT_DETAILS,
    LIST_REQUIREMENT, UPDATE_CUSTOMER_QUOTATION_ITEM, UPDATE_DEAL_ID, UPDATE_REQUIREMENT, UPDATE_REQUIREMENT_ITEM, UPDATE_REQUIREMENT_ITEM_DESCRIPTION,
    UPDATE_REQUIREMENT_L1L4People,
    VALIDATE_DEAL_ID
} from '@/services/url.service';

export const listRequirements = (offset, limit, search, token) => getWithAuth(LIST_REQUIREMENT(offset, limit, search), token);

export const addRequirement = (details) => postWithAuth(ADD_REQUIREMENT, details);

export const addRequirementItem = (details) => postWithAuth(ADD_REQUIREMENT_ITEM(details.requirementId), details);

export const getRequirementDetails = (requirementId, token) => getWithAuth(GET_REQUIREMENT_DETAILS(requirementId), token);
export const updateRequirement = (details) => putWithAuth(UPDATE_REQUIREMENT(details.id), details);

export const updateRequirementItem = (requirementId, details) => putWithAuth(UPDATE_REQUIREMENT_ITEM(requirementId), details);
export const updateRequirementSalesPeople = (requirementId, details) => putWithAuth(UPDATE_REQUIREMENT_L1L4People(requirementId), details);
export const deleteRequirementItem = (requirementId, details) => deleteWithAuth(DELETE_REQUIREMENT_ITEM(requirementId), details);
export const updateCustomerQuotationItem = (requirementItemId, details) => putWithAuth(UPDATE_CUSTOMER_QUOTATION_ITEM(requirementItemId), details);
export const deleteCustomerQuotationItem = (requirementItemId, customerQuotationId, token) => putWithAuth(DELETE_CUSTOMER_QUOTATION_ITEM(requirementItemId), customerQuotationId, token);
export const generateDealFromRequirement = (id, details) => postWithAuth(CREATE_RFQ_AND_CUSTOMER_QUOTATION(id), details);

export const getDealSearchRequirements = ({
    search, limit, offset, reqCqSoPoId
}) => getWithAuthWithToken(GET_DEAL_SEARCH_REQUIREMENT({
    search, limit, offset, reqCqSoPoId
}));
export const getDealSearchRequirementDetails = (requirementId) => getWithAuthWithToken(GET_DEAL_SEARCH_REQUIREMENT_DETAILS(requirementId));

export const updateRequirementItemDescription = (requirementItemId, details) => putWithAuth(UPDATE_REQUIREMENT_ITEM_DESCRIPTION(requirementItemId), details);

export const getAllDealNumbers = ({
    search, limit, offset, token
}) => getWithAuth(GET_ALL_DEAL_NUMBERS({ search, limit, offset }), token);

export const updateDealId = ({
    dealId, requirementId, readTime
}) => postWithAuth(UPDATE_DEAL_ID({ dealId }), { requirementId, readTime });

export const validateDealId = ({
    dealId, customerId = '', buHeadId = '', token
}) => getWithAuth(VALIDATE_DEAL_ID({ dealId, customerId, buHeadId }), token);

export const createDealIdForRequirement = (details) => postWithAuth(CREATE_DEAL_ID_FOR_REQUIREMENT({ requirementId: details.requirementId }), details);
