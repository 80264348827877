import { getWithAuth, postWithAuth, putWithAuth } from './http.service';
import {
    ADD_VENDOR,
    ADD_VENDOR_PICKUP_LOCATION,
    GET_ALL_VENDORS,
    GET_VENDOR,
    GET_VENDORS,
    UPDATE_VENDOR,
    UPDATE_VENDOR_APPROVER,
    UPDATE_VENDOR_STATUS
} from './url.service';

export const addVendor = (details) => postWithAuth(ADD_VENDOR, details);
export const getVendors = (offset, limit, search, categoryId, onlyActiveVendors, token) => getWithAuth(GET_VENDORS(offset, limit, search, categoryId, onlyActiveVendors), token);
export const getAllVendors = ({
    offset, limit, search, token
}) => getWithAuth(GET_ALL_VENDORS({ offset, limit, search }), token);
export const updateVendor = (id, details) => putWithAuth(UPDATE_VENDOR(id), details);
export const updateVendorApprover = (id, details) => putWithAuth(UPDATE_VENDOR_APPROVER(id), details);
export const updateVendorStatus = (id, details) => putWithAuth(UPDATE_VENDOR_STATUS(id), details);
export const getVendor = (id, token) => getWithAuth(GET_VENDOR(id), token);
export const addVendorPickupLocation = (details, vendorId) => postWithAuth(ADD_VENDOR_PICKUP_LOCATION(vendorId), { ...details });
