import WithAuth from '@/components/Auth/WithAuth';
import getLayout from '@/components/Layout/LayoutConfiguration';
// import Bugsnag from '@bugsnag/js';
// import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';

const App = ({ Component, pageProps, router }) => {
    const layout = getLayout(router.pathname);
    // Bugsnag.start({
    //     apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
    //     plugins: [new BugsnagPluginReact()]
    // });
    // const ErrorBoundary = Bugsnag.getPlugin('react')
    //     .createErrorBoundary(React);

    return (
        <>
            {/* <ErrorBoundary> */}
            <WithAuth router={router}>
                {layout({ children: <Component {...pageProps} /> })}
            </WithAuth>
            {/* </ErrorBoundary> */}
        </>
    );
};

export default App;
