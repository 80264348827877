import { DELIVERY_STATUS, SALES_ORDER_STATUSES } from "@/helpers/constants";
import { useRouter } from "next/router";
import { Icon, Text } from "../atoms";
import Status from "../atoms/Status";
import { status } from '../config';

const SalesOrderDetailsCard = ({ className, salesOrderData, purchaseOrders, cpo, setLocalStorage, onDeliveriesClick }) => {
    let deliveriesCount = 0;
    purchaseOrders.forEach((po) => {
        po.deliveries.forEach((delivery) => {
            if ([DELIVERY_STATUS.DELIVERED, DELIVERY_STATUS.IN_TRANSIT].includes(delivery.deliveryStatus)) {
                deliveriesCount += parseInt(delivery.tripCount)
            }
        })
    })

    const router = useRouter();
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    return (
        <div className={`w-full ${className}`}>
            <Text type='sm500v1' className='pb-1.5'>Sales Order</Text>
            <div className={`flex flex-col  px-4 py-3.5 bg-white  w-full rounded-lg `}>
                <div className="flex items-center justify-between border-b border-dashed border-Black-20 pb-3.5">
                    <div className="flex items-center gap-x-1.5">
                        <Text type='base500'>{salesOrderData?.arisSaleOrderNumber}</Text>
                        <Status variant={salesOrderData?.status === SALES_ORDER_STATUSES.DRAFT ? 'Primaryv1' : 'Secondaryv1'} className='ml-2 text-xs capitalize text-Gray-900'>
                            {status.salesOrder.label[salesOrderData?.status]}</Status>
                    </div>
                    <Icon src='/images/rightArrowv2.svg' onClick={
                        () => {
                            setLocalStorage();
                            router.push(`/sales-orders/${salesOrderData?.id}`);
                        }
                    } className="cursor-pointer" />
                </div>
                <div className="flex items-center gap-x-10 mt-4">
                    <Text type='xs500'>Generated on <span className="font-normal text-Black-300 ml-px">{formatDate(salesOrderData?.createdAt.split('T')[0])}</span> </Text>
                    {cpo ? <Text type='xs500'>CPO <span className="font-normal text-Black-300 ml-px">{cpo}</span> </Text> : null}
                    <Text type='xs500' onClick={onDeliveriesClick}>Deliveries <span className="font-normal text-Black-300 underline ml-px cursor-pointer">{deliveriesCount}</span> </Text>
                </div>
            </div>
        </div>
    );
}

export default SalesOrderDetailsCard;
