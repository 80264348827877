import { useRouter } from "next/router";
import { Icon, Text } from "../atoms";
import Status from "../atoms/Status";
import { status } from "../config";
const RequirementDetailsCard = ({ requirementData, className, setLocalStorage }) => {
    const router = useRouter()
    const variantStatus = () => {
        if (requirementData.isActive === true) {
            if (requirementData.status === 'quotation_approved') return { varStatus: 'Secondaryv1', varLabel: status.requirement.label[requirementData.status] };
            return { varStatus: 'Primaryv1', varLabel: status.requirement.label[requirementData.status] };
        }
        return { varStatus: 'Inactive', varLabel: 'Inactive' };
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }
    return (
        <div className={`w-full ${className}`}>
            <Text type='sm500v1' className='pb-1.5'>Requirement</Text>
            <div className={`flex flex-col justify-between px-4 py-3.5 bg-white h-[160px] w-full rounded-lg`}>
                <div className="flex items-center justify-between border-b border-dashed border-Black-20 pb-3.5">
                    <div className="flex items-center gap-1.5">
                        <Text type='base500'>{requirementData.formattedId}</Text>
                        <Status variant={variantStatus().varStatus}> {variantStatus().varLabel}</Status>

                    </div>
                    <Icon src='/images/rightArrowv2.svg' onClick={
                        () => {
                            setLocalStorage();
                            router.push(`/requirements/${requirementData.id}`);
                        }
                    } className='cursor-pointer' />
                </div>
                <div>
                    <div className="flex items-center gap-1 whitespace-nowrap">
                        <Text type='xs500'>Created on <span className="font-normal text-Black-300 ml-px">{formatDate(requirementData.createdAt)}</span> </Text>
                        <Text type='xs500'> by <span className="font-normal text-Black-300 ml-px">{requirementData.creator.fullName}</span> </Text>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequirementDetailsCard;
