import PropTypes from 'prop-types';

const InputLabel = ({ children, onClick, ...property }) => (
    <div className={`Body-Default text-sm font-medium text-Black-300 ${property.className}`} onClick={onClick}>
        {children}{property.isRequired && <span className="required">*</span>}
    </div>
);

export default InputLabel;

InputLabel.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
};
