import Loader from '@/atoms/Loader';
import Notification from '@/atoms/Notification';
import ScreenLoader from '@/atoms/ScreenLoader';
import Footer from '@/molecules/Footer';
import Router from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Header from '../Login/Header';
import Sidebar from '../Sidebar/Sidebar';

const Layout = ({ children }) => {
    const showLoader = useSelector((state) => state.loader.showLoader);
    const notification = useSelector((state) => state.notification.details);

    const notificationRef = useRef();
    const didMount = useRef(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const start = () => {
            setLoading(true);
        };
        const end = () => {
            setLoading(false);
        };

        Router.events.on('routeChangeStart', start);
        Router.events.on('routeChangeComplete', end);
        Router.events.on('routeChangeError', end);
        return () => {
            Router.events.off('routeChangeStart', start);
            Router.events.off('routeChangeComplete', end);
            Router.events.off('routeChangeError', end);
        };
    }, []);

    useEffect(() => {
        if (didMount.current) {
            notificationRef.current.displayToast(notification.type, notification.message, notification.autoClose);
        }
        didMount.current = true;
    }, [notification]);

    return (
        <div className='flex h-screen'>
            <>
                <Notification ref={notificationRef} />
                {showLoader && <Loader />}
                <div className='flex flex-1 overflow-y-auto scrollbar scrollbar_for_firefox '>
                    <Sidebar />
                    {loading
                        ? <ScreenLoader />
                        : <div className="flex-1 overflow-y-auto ">
                            <Header className='sticky top-0 z-20' />
                            <main className="main-content px-6 pt-6 pb-20 scrollbar scrollbar_for_firefox ">
                                {children}
                            </main>
                            <Footer />
                        </div>
                    }
                </div>
            </>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.node
};

export default Layout;
