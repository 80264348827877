import PropTypes from 'prop-types';

const Breadcrumbs = (props) => (
    <nav className='text-Gray-500'>
        <ol className="flex text-sm font-normal">
            {props.breadcrumbs.map((breadcrumb, i) => (
                <li key={i}>
                    <a href={`${breadcrumb.active ? '#' : breadcrumb.url}`} className={`${breadcrumb.active ? 'cursor-auto text-Black-300' : 'text-Gray-500'}`}>{breadcrumb.title}</a>
                    {!breadcrumb.active && <span className="mx-2">/</span>}
                </li>
            ))}
        </ol>
    </nav>
);

export default Breadcrumbs;

Breadcrumbs.propTypes = {
    className: PropTypes.string,
    children: PropTypes.string
};
