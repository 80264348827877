import { Text } from '../atoms';
import Status from '../atoms/Status';

const CustomerAndProjectNameWithId = ({
  customerName, projectName,
  icons, className, dealSearchValue,
  onClick, lastSearch
}) => {
  return (
    <div className={`border-b pb-4.5 border-Black-20 last:border-0 ${className}`} onClick={onClick}>
      <Text type='sm600'>{customerName}</Text>
      <Text type='xs500' className='mt-0.5'>{projectName}</Text>
      <div className='flex items-center flex-wrap gap-x-1.5 gap-y-2 mt-3'>
        {icons.map((e) => {
          let isActive = '';
          if (lastSearch) {
            isActive = e.toLowerCase().includes(lastSearch.toLowerCase())
          } else {
            isActive = dealSearchValue && e.toLowerCase().includes(dealSearchValue.toLowerCase())
          }
          return (
            <Status
              variant={isActive ? 'active' : 'Inactivev1'}
            >
              {e}
            </Status>
          )
        })}
      </div>
    </div>
  )
}

export default CustomerAndProjectNameWithId