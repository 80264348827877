import App from '@/components/App/App';
import ErrorHandlerWrapper from '@/components/Errors/ErrorHandlerWrapper';
import { getAuth, removeAuth } from '@/services/identity.service';
import { track } from '@/services/mixpanel.service';
import { appWithTranslation } from 'next-i18next';
import Head from 'next/head';
import Script from 'next/script';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { dumpLogsToAwsCloudWatch } from 'services/logger.service';
import '../styles/globals.css';
// eslint-disable-next-line camelcase
import { startBugsnagPerfornaceMonitoring } from '@/lib/bugsnag';
import jwt_decode from 'jwt-decode';
import nextI18NextConfig from '../next-i18next.config';
import store from '../store/index';

function NapsesApp({ Component, pageProps, router }) {
    function logError(msg, url, lineNo, columnNo, error) {
        if (!error) return;

        const errorMessage = msg.toLowerCase();
        let errorType = 'BrowserError: ';

        if (errorMessage.includes('script error')) {
            errorType = 'Script Error: ';
        }
        const body = {
            logMessage: errorType + msg,
            logData: {
                url,
                error: { message: error.message, stack: error.stack },
                lineNo,
                columnNo,
                location: {
                    host: window.location.host,
                    hostname: window.location.hostname,
                    href: window.location.href,
                    origin: window.location.origin,
                    pathname: window.location.pathname
                }
            }
        };
        dumpLogsToAwsCloudWatch(body);
    }

    useEffect(() => {
        window.onerror = logError;
    }, []);

    useEffect(() => {
        const login = '/login';

        const checkToken = (url) => {
            if (url.includes(login)) {
                return;
            }
            const auth = getAuth();

            if (auth) {
                const { token } = auth;

                if (token) {
                    const decodedJwt = jwt_decode(token);

                    // Check if the token is expired. If it is, the user will be redirected to the login page.
                    if (decodedJwt.exp * 1000 < Date.now()) {
                        removeAuth();
                    }
                }
            }
        };

        router.events.on('routeChangeStart', checkToken);
        const done = () => true;
        return () => {
            router.events.off('routeChangeStart', done);
        };
    }, [router]);

    useEffect(() => {
        let id = null;
        let email = null;
        const auth = getAuth();
        if (auth) {
            id = auth.id;
            email = auth.email;
        }
        const handleComplete = (url) => {
            let pageName = url.slice(1);
            if (!pageName) {
                pageName = 'index';
            }
            track('PageViewed', { id, pageName, email });
            return true;
        };
        const handleError = () => true;

        router.events.on('routeChangeComplete', handleComplete);
        router.events.on('routeChangeError', handleError);

        return () => {
            router.events.off('routeChangeComplete', handleComplete);
            router.events.off('routeChangeError', handleError);
        };
    }, [router.events]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            startBugsnagPerfornaceMonitoring();
        }
    }, [router]);

    const gaId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID;
    const gaIdString = `'${gaId}'`;

    return (
        <>
            <Script
                src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
                strategy="lazyOnload"
            />
            <Script id="ga-script" strategy="lazyOnload">
                {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){window.dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', ${gaIdString});
                `}
            </Script>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0"
                />
                <meta name="theme-color" content="#FFC117"/>
                <meta name="apple-mobile-web-app-status-bar-style" content="black"/>

                <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
                />
            </Head>
            <ErrorHandlerWrapper>
                <Provider store={store}>
                    <App
                        Component={Component}
                        pageProps={pageProps}
                        router={router}
                    />
                </Provider>
            </ErrorHandlerWrapper>
        </>
    );
}

export default appWithTranslation(NapsesApp, nextI18NextConfig);
