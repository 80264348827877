import PropTypes from 'prop-types';
import { useState } from 'react';

const TextArea = ({
    register, error, label, disabled, setlabelTop, id, borderColor, p, ...textareaProps
}) => {
    const [labelTop, setLabelTop] = useState(setlabelTop);
    const [focused, setFocued] = useState(false);
    return (
        <div className={`${textareaProps.className} relative w-full`}>
            <textarea
                {...register(id)}
                id={id}
                {...textareaProps}
                onFocus={() => {
                    setLabelTop(true);
                    setFocued(true);
                }}
                onBlur={(e) => {
                    e.target.value.length === 0 && setLabelTop(false);
                    setFocued(false);
                }}
                disabled={disabled}
                className={`w-full text-Black-300 border rounded font-normal outline-none text-sm transition duration-150 ease-in-out bg-white ${borderColor ? `border-[${borderColor}]` : 'border-solid'} outline-none focus:border-primary-500  placeholder-[#A6B0CF]  ${textareaProps.height ? textareaProps.height : 'h-40'} ${p || 'p-3'} focus:border-2  placeholder-iron-gray focus:outline-none border-[#DFE0EB] ${disabled ? 'bg-[#F3F3F9] cursor-not-allowed !text-gray-400' : ''}  ${error ? 'focus:border-error' : ''} ${textareaProps.TextaresClass} `}
            />
            {error && (
                <div className="text-red-500 text-xs font-semibold absolute left-1 pl-3">
                    {error.message}
                </div>
            )}
            <label
                className={`${labelTop ? 'labelTop' : ''
                } px-3 mx-1 transform text-base text-blueGray-500 absolute left-1  top-4 bg-white pointer-events-none transition-all duration-150 origin-top-left ease-out ${focused && error ? 'text-error' : ''
                } ${focused ? 'text-primary' : ''}`}
            >
                {label}
            </label>
        </div>
    );
};
TextArea.propTypes = {
    error: PropTypes.object,
    label: PropTypes.string,
    id: PropTypes.string

};
export default TextArea;
