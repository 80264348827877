import React from 'react';
import PropTypes from 'prop-types';

const Avatar = ({ children, ...property }) => (
    <div className={`flex justify-center text-green-200 items-center w-12 h-12 text-center rounded-full bg-red-200 overflow-hidden  text-xl ${property.className}`}>
        {property.src ? <img onClick={property.onClick} src={property.src} width={property.width} height={property.height} className={`w-full h-full object-cover ${property.imgClassName}`} /> : <span className="font-bold leading-10 align-middle text-primary-600 group-hover:table-cell ">{children}</span>}
    </div>
);

export default Avatar;

Avatar.propTypes = {
    className: PropTypes.string,
    imgClassName: PropTypes.string,
    children: PropTypes.node
};
