import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const Tab = ({
    tabs, selectedTab = 0
}) => {
    const [toggleState, setToggleState] = useState(selectedTab);
    const toggleTab = (index) => {
        setToggleState(index);
    };

    useEffect(() => {
        toggleTab(selectedTab);
    }, [selectedTab]);

    return (
        <>
            <div className="flex items-center">
                {tabs.map((tab, key) => (
                    <div onClick={() => {
                        toggleTab(key);
                        tab.action();
                    }}
                        key={key}
                        className={`${tab?.className} flex cursor-pointer m-2 md:m-4 mb-2.5`}>
                        <div>
                            <span className={` font-normal text-sm md:text-base ${toggleState === key ? 'text-primary-500' : 'text-Gray-301'}`}> {tab.name}</span>
                            <div className={` ${toggleState === key ? 'visible' : 'invisible'} h-1 mt-1 bg-primary-500 rounded-xl`}></div>
                        </div>
                        {key < tabs.length - 1
                            && <img src='/images/Arrow-Right-Gray.svg' className='pl-2 md:pl-5 text-Gray-301' alt='arrow-icon' />
                        }
                    </div>
                ))}
            </div>
        </>
    );
};

export default Tab;

Tab.propTypes = {
    className: PropTypes.string,
    tabName: PropTypes.array
};
