import { Icon, Text } from "../atoms";
import Status from "../atoms/Status";
import { status } from '../config';
const PurchaseOrderDetailsCard = ({ index, totalNoOfCard, poId, poNo, poStatus, generatedOn, cardPageNo,
    vendorName, noOfDeliveries, onHandlePurchaseOrderClick, onDeliveriesClick, className }) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    return (
        <div className={`w-full ${className}`}>
            <Text type='sm500v1' className={`pb-1.5 ${index === 0 ? 'visible' : 'invisible'}`}>{`Purchase Order (${totalNoOfCard}) `}</Text>
            <div className={`flex flex-col  px-4 py-3.5 bg-white  w-full rounded-lg`}>
                <div className="border-b border-dashed border-Black-20 pb-3.5">
                    <div className="flex justify-between w-full">
                        <div className="flex items-center gap-x-1.5">
                            <Text type='base500'>{poId}</Text>
                            <Status variant={poStatus === 'sent' ? 'Secondaryv1' : 'redv1'}>
                                {status.purchaseOrder.label[poStatus]}
                            </Status>
                        </div>
                        <Icon src='/images/rightArrowv2.svg' onClick={onHandlePurchaseOrderClick} className="cursor-pointer" />
                    </div>
                    <Text type='xs400v2' className='mt-[3px]'>{poNo}</Text>
                </div>

                <div className="flex flex-col gap-x-10 mt-4">
                    <Text type='xs500' className={'truncate'}>Vendor <span className="font-normal text-Black-300 ml-px">{vendorName}</span> </Text>
                    <Text type='xs500' onClick={onDeliveriesClick} className='mt-1.5'>Deliveries <span className="font-normal text-Black-300 underline ml-px cursor-pointer">{noOfDeliveries}</span> </Text>
                    <div className="flex justify-between items-center">
                        <Text type='xs500' className='mt-4'>Generated on <span className="font-normal text-Black-300 ml-px">{formatDate(generatedOn)}</span> </Text>
                        <Text type='xs400v2' className='mt-4 !text-Black-300'>{cardPageNo}</Text>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PurchaseOrderDetailsCard