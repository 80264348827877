const FileUploader = ({
    uploadFile, name, id = 'file', error, className, register
}) => {
    const { onChange, ref } = register('file');
    return (<>
        <div className={` ${className} flex justify-between items-center border border-[#DFE0EB] rounded p-1`}>

            <label htmlFor={id}
                className={`flex items-center w-full h-12 pl-3 text-sm font-normal bg-white cursor-pointer ${name ? 'text-Gray-900' : 'text-[#A6B0CF]'}`}>

                {name || 'No file chosen'}
            </label>
            <label htmlFor={id}
                className='flex items-center justify-center mr-2 text-sm font-normal rounded cursor-pointer w-36 h-9 bg-[#DFE0EB] text-Gray-900'>
                Choose File
            </label>
        </div>
        <input
            type="file"
            accept=".jpg,.jpeg,.png,.pdf"
            className="hidden form-control"
            id={id}
            name='file'
            ref={ref}
            onChange={(e) => {
                onChange(e);
                uploadFile(e);
            }}
        />

        {error
            && <div className="absolute pl-1 text-xs text-red-500 text-error mt-1">
                {error.message}
            </div>}
    </>);
};
export default FileUploader;
