import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from '@/atoms/Loader';
import { useSelector } from 'react-redux';
import Notification from '@/atoms/Notification';
import Router from 'next/router';
import ScreenLoader from '@/atoms/ScreenLoader';

const EmptyLayout = ({ children }) => {
    const showLoader = useSelector((state) => state.loader.showLoader);
    const notification = useSelector((state) => state.notification.details);
    const notificationRef = useRef();
    const didMount = useRef(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const start = () => {
            setLoading(true);
        };
        const end = () => {
            setLoading(false);
        };

        Router.events.on('routeChangeStart', start);
        Router.events.on('routeChangeComplete', end);
        Router.events.on('routeChangeError', end);
        return () => {
            Router.events.off('routeChangeStart', start);
            Router.events.off('routeChangeComplete', end);
            Router.events.off('routeChangeError', end);
        };
    }, []);

    useEffect(() => {
        if (didMount.current) {
            notificationRef.current.displayToast(notification.type, notification.message, notification.autoClose, notification.position);
        }
        didMount.current = true;
    }, [notification]);

    return (
        <>
            {
                loading
                    ? <div className='flex h-screen'>
                        <ScreenLoader />
                    </div>

                    : <>
                        <Notification ref={notificationRef} />
                        {showLoader && <Loader />}
                        {children}
                    </>
            }

        </>
    );
};

EmptyLayout.propTypes = {
    children: PropTypes.node
};

export default EmptyLayout;
