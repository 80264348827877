/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { Text } from '@/atoms';
import Status from '@/atoms/Status';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Icon from '../../atoms/Icon';
import { getAuth } from '../../services/identity.service';
import { getSidebarConfig } from './SidebarConfiguration';

const Sidebar = () => {
    const router = useRouter();
    const [sidebarItems, setSidebarItems] = useState([]);
    const [show, setShow] = useState(true);

    const handleClick = (items) => {
        if (items.type === 'atomic') {
            router.push(items.url);
        }
    };

    const handleResize = () => {
        if (window.innerWidth < 1280) {
            setShow(false);
        } else {
            setShow(true);
        }
    };

    useEffect(() => {
        const auth = getAuth();
        if (auth) {
            setSidebarItems(getSidebarConfig('ADMIN'));
        }

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const matchUrlPath = (itemUrl, routerUrl) => routerUrl.substring(0, itemUrl.length) === itemUrl;

    return (
        <div className={`relative flex-shrink-0 ${show ? 'w-[200px]' : 'w-14'} transition-width duration-300`}>
            <Icon
                onClick={() => { setShow(!show); }}
                src={'/images/Arrow-left.svg'}
                className={`absolute ${show ? '-right-2 top-6' : '-right-2.5 top-6'}  z-50 cursor-pointer transition-transform duration-300 ${!show ? 'rotate-180' : ''}`}
            />
            <div className="bg-Black-300 h-screen overflow-y-auto no-scrollbar">
                <Icon src='/images/LogoSidebar.svg' className={`w-full h-8 justify-center items-center mt-4 ${show ? 'flex' : 'hidden'}`} />
                <Icon src='/images/arislogoIcon.svg' className={`w-[25px] ml-4 mt-4 mb-6 ${show ? 'hidden' : 'flex'}`} />
                <div className="pb-16 flex flex-col">
                    {sidebarItems.map((key, id) => (
                        <div key={id}>
                            <Text className={` ${id === 0 ? 'mt-[30px]' : 'mt-6'} mb-1.5 pl-4  font-semibold text-xs text-Black-60 ManropeFontFamily ${show ? 'flex' : 'hidden'}`}>{key.name}</Text>
                            <Text className={`${id === 0 ? 'hidden' : 'border-b border-Black-60 w-7 mt-1.5 mb-6 ml-3 '}${show ? 'hidden' : 'flex'}`}></Text>
                            {key.data.map((items, idx) => (
                                <div key={idx} className='flex'>
                                    <div className={`${matchUrlPath(items.url, router.pathname) ? 'visible' : 'invisible'} w-[3px] bg-primary-502`}></div>
                                    <div
                                        className={`w-full flex h-[30px] cursor-pointer ${items.isBita ? 'items-start pt-1.5' : 'items-center'} ${matchUrlPath(items.url, router.pathname) ? 'bg-white bg-opacity-10' : ''}`}
                                        onClick={() => { handleClick(items); }}
                                    >
                                        <div className={'mx-4 w-4'}>{matchUrlPath(items.url, router.pathname) ? items.iconsYellow : items.iconsGray}</div>
                                        {show && <div>
                                            <Text className={`${matchUrlPath(items.url, router.pathname) ? 'text-white font-medium' : 'font-normal text-Black-40'} ${items.isBita ? '' : 'mt-[1.5px]'} text-xs whitespace-nowrap pr-3`}>{items.name}</Text>
                                            {items.isBita && <Status variant='Bita' className='mt-1.5 !leading-[10px]'>BETA</Status>}
                                        </div>}
                                    </div>
                                </div>

                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
export default Sidebar;
