import { getWithAuth, postWithAuth, putWithAuth } from 'services/http.service';
import {
    ADD_USER,
    GET_ALL_USERS,
    GET_BUHEADS,
    GET_USER,
    GET_USERS,
    GET_USERS_BY_MOBILE,
    GET_USER_FOR_AUTHENTICATION,
    UPDATE_USER,
    USER_LOG_OUT
} from 'services/url.service';

export const authenticateUser = (token) => getWithAuth(GET_USER_FOR_AUTHENTICATION, token);
export const addUser = (details) => postWithAuth(ADD_USER, details);
export const getUsers = (offset, limit, search, token, role = '') => getWithAuth(GET_USERS(offset, limit, search, role), token);
export const getUser = (id, token) => getWithAuth(GET_USER(id), token);
export const updateUser = (id, details) => putWithAuth(UPDATE_USER(id), details);
export const getBuheads = (token) => getWithAuth(GET_BUHEADS, token);
export const getUsersByMobile = (mobileCountryCode, mobile, token) => getWithAuth(GET_USERS_BY_MOBILE(mobileCountryCode, mobile), token);
export const getAllUsers = (offset, limit, search, token, role = '') => getWithAuth(GET_ALL_USERS(offset, limit, search, role), token);
export const userLogOut = () => postWithAuth(USER_LOG_OUT);
