/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = { details: { type: '', message: [], position: 'top-right' } };

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        updateNotification(state, action) {
            let autoClose = 5000;
            if (action.payload.autoClose === false) {
                autoClose = false;
            }
            if (typeof action.payload.autoClose === 'number') {
                autoClose = action.payload.autoClose;
            }
            state.details = { ...action.payload, autoClose };
        }
    }
});

export const notificationActions = notificationSlice.actions;

export default notificationSlice.reducer;
