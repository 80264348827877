import React from 'react';
import PropTypes from 'prop-types';

const Tag = ({
    children, name, onClick, type = 'square', isActiveDot, href, ...properties
}) => {
    const tagType = {
        square: 'square',
        filled: 'filled',
        radius: 'radius'
    };

    const tagStyle = {
        square: 'tagSquare',
        filled: 'tagFilled',
        radius: 'tagRadius'
    };

    return (
        <a type={tagType[type]} onClick={onClick} href={href} className={`${properties.className} ${tagStyle[type]}`}>{children}
            {isActiveDot && (
                <div className="w-2 h-2  bg-orange-50 rounded-full ml-1"></div>
            )}
        </a>
    );
};

export default Tag;

Tag.propTypes = {
    isActiveDot: PropTypes.bool,
    classname: PropTypes.string,
    type: PropTypes.oneOf(['square', 'filled', 'radius']),
    children: PropTypes.string,
    onClick: PropTypes.func
};

Tag.defaultProps = {
    type: 'square'
};
