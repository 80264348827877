import { useEffect, useRef, useState } from 'react';

const Paginator = ({
    pages, pageLimit, handleSkipChange, selectedPage = 1
}) => {
    const didMount = useRef(false);
    const [currentPage, setCurrentPage] = useState(selectedPage);
    useEffect(() => {
        if (didMount.current) {
            handleSkipChange(currentPage - 1);
        }
        didMount.current = true;
    }, [currentPage]);

    useEffect(() => {
        setCurrentPage(selectedPage);
    }, [pages]);

    const goToNextPage = () => {
        setCurrentPage((page) => page + 1);
    };

    const goToPreviousPage = () => {
        setCurrentPage((page) => page - 1);
    };

    const changePage = (event) => {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
    };

    const getPaginationGroup = () => {
        const m = Math.round(pageLimit / 2) + 1;
        const start = currentPage < m ? 0 : currentPage - m;
        const arr = [];
        for (let idx = start; idx < start + pageLimit; idx += 1) {
            if (idx + 1 > pages) {
                break;
            }
            arr.push(idx + 1);
        }
        return arr;
    };

    return (
        <div className={"flex items-center justify-center gap-2 mt-10 text-xs interFontfamily"}>
            <button
                onClick={() => setCurrentPage(1)}
                className={`bg-white text-Black-300 border border-Black-40 cursor-pointer rounded-[2.5px]
                ${currentPage <= 5
                        ? 'pointer-events-none opacity-50'
                        : ''}`}
            >
                <div className='flex items-center justify-center w-8 h-8'>
                    <img src='/images/chevronright .svg' className='rotate-180' />
                    <img src='/images/chevronright .svg' className='rotate-180 -ml-2' />
                </div>
            </button>
            <button
                onClick={goToPreviousPage}
                className={`bg-white text-Black-300 border border-Black-40 cursor-pointer rounded-[2.5px]
                ${currentPage === 1
                        ? 'pointer-events-none opacity-50'
                        : ''}`}
            >
                <div className='flex items-center justify-center w-8 h-8'>
                    <img src='/images/chevronright .svg' className='rotate-180' />
                </div>
            </button>

            {getPaginationGroup().map((item, index) => (
                <button
                    key={index}
                    onClick={changePage}
                    className={` border  w-8 h-8 text-center border-Black-300 rounded-[2.5px] font-semibold !text-[10px]
                    ${currentPage === item
                            ? 'pointer-events-none border-none rounded-[2.5px] bg-primary-502 text-Black-300'
                            : 'text-Black-300'}`}
                >
                    {item}
                </button>
            ))}

            <button
                onClick={goToNextPage}
                className={` bg-white text-Black-300 border border-Black-60 cursor-pointer rounded-[2.5px]
                ${currentPage === pages
                        ? 'pointer-events-none opacity-50'
                        : ''}`}
            >
                <div className='flex items-center justify-center w-8 h-8 '>
                    <img src='/images/chevronright .svg' />
                </div>
            </button>
            <button
                onClick={() => setCurrentPage(pages)}
                className={`bg-white text-Black-300 border cursor-pointer border-Black-60 rounded-[2.5px]
                ${(currentPage > 5 * (Math.floor(pages / 5)))
                        ? 'pointer-events-none opacity-50'
                        : ''}`}
            >
                <div className='flex items-center justify-center w-8 h-8 '>
                    <img src='/images/chevronright .svg' />
                    <img src='/images/chevronright .svg' className='-ml-2' />
                </div>
            </button>
        </div>
    );
};

export default Paginator;
