import React, { forwardRef, useImperativeHandle } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// eslint-disable-next-line react/display-name
const Notification = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        displayToast(
            type = 'info',
            message = ['This is a toast'],
            autoClose = 5000,
            position = 'top-right',
            closeOnClick = true,
        ) {
            if (typeof message === 'object' && !Array.isArray(message)) {
                if (type === 'error') {
                    message = message?.message || "Something went wrong...!"
                } else {
                    message = 'Success'
                }
            }

            if (typeof message === 'string') {
                message = [message];
            }

            message.forEach((msg) => {
                toast[type](msg, {
                    position,
                    autoClose,
                    closeOnClick
                });
            });
        }
    }));
    return (
        <>
            <ToastContainer />
        </>
    );
});

export default Notification;
