import { config } from './application-config';

const applicationConfig = config[process?.env?.NEXT_PUBLIC_APPLICATION_NAME];
const applicationDetails = {
    applicationName: applicationConfig?.APPLICATION_NAME,
    applicationShortName: applicationConfig?.APPLICATION_SHORT_NAME,
    applicationFullName: applicationConfig?.APPLICATION_FULL_NAME,
    applicationFullName1: applicationConfig?.APPLICATION_FULL_NAME_1,
    applicationPngLogo: applicationConfig?.APPLICATION_PNG_LOGO,
    applicationSvgLogo: applicationConfig?.APPLICATION_SVG_LOGO,
    cardSvgLogo: applicationConfig?.CARD_SVG_LOGO,
    sideBarBgColor: applicationConfig?.SIDEBAR_BG_COLOR,
    sidebarTextColor: applicationConfig?.SIDEBAR_TEXT_COLOR,
    sidebarSubnavTextColor: applicationConfig?.SIDEBAR_SUBNAV_TEXT_COLOR,
    wapImg: applicationConfig?.WAP_HEADER_CARD_IMG,
    soPreviewLogo: applicationConfig?.SO_PREVIEW_LOGO,
    applicationContactUsNumber: applicationConfig?.CONTACT_US_NUMBER,
    contactEmail: applicationConfig?.CONTACT_EMAIL,
    applicationSalesEmail: applicationConfig?.SALES_EMAIL,
    applicationWebsite: applicationConfig?.WEBSITE,
    applicationAddressLine1: applicationConfig?.ADDRESS_LINE_1,
    applicationAddressLine2: applicationConfig?.ADDRESS_LINE_2,
    applicationGstIn: applicationConfig?.GSTIN,
    applicationPan: applicationConfig?.PAN,
    applicationEmail: applicationConfig?.APPLICATION_EMAIL,
    applicationLogoSmall: applicationConfig?.APPLICATION_LOGO_SMALL,
    applicationPngLogoV1: applicationConfig?.APPLICATION_PNG_LOGO_V1,
    applicationContactUsNumber1: applicationConfig?.CONTACT_US_NUMBER_1,
    applicationCin: applicationConfig?.CIN,
    formerlyKnownAs: applicationConfig?.FORMERLY_KNOWN_AS
};

module.exports = {
    applicationDetails,
    customerTypes: [
        { value: 'Developer', label: 'Developer' },
        { value: 'Trader', label: 'Trader' },
        { value: 'Contractor', label: 'Contractor' },
        { value: 'Service Provider', label: 'Service Provider' },
        { value: 'Proprietor', label: 'Proprietor' }
    ],
    businessTypes: [
        { value: 'Large Enterprises', label: 'Large' },
        { value: 'SME Enterprises', label: 'SME' },
        { value: 'Retail Enterprises', label: 'Retail' }
    ],
    gstTreatments: [
        { value: 'business_gst', label: 'Registered Business - Regular' },
        { value: 'business_none', label: 'Unregistered Business' }
    ],
    paperChallanPreferences: [
        { label: 'E-Challan only', value: 'none' },
        { label: `${applicationDetails?.applicationShortName} Paper Challan`, value: 'aris' },
        { label: 'Vendor Paper Challan', value: 'vendor' },
        { label: `Both ${applicationDetails?.applicationShortName} and Vendor Challan`, value: 'both' }
    ],
    documents: {
        customer: {
            gstin: {
                s3Path: 'customers/gst',
                documentRequired: true,
                documentNumberRequired: true,
                label: 'GSTIN',
                name: 'gstin',
                fileUploadIcon: '/images/PaperUpload.svg',
                fileUploadLabel: 'Upload GSTIN',
                required: true

            },
            pan: {
                s3Path: 'customers/pan',
                documentRequired: true,
                documentNumberRequired: true,
                label: 'PAN',
                name: 'pan',
                fileUploadIcon: '/images/addyellow.svg',
                fileUploadLabel: 'Add PAN card details',
                required: false

            },
            tan: {
                s3Path: 'customers/tan',
                documentRequired: true,
                documentNumberRequired: true,
                label: 'TAN',
                name: 'tan',
                fileUploadIcon: '/images/addyellow.svg',
                fileUploadLabel: 'Add TAN card details',
                required: false

            },
            cancelledcheque: {
                s3Path: 'customers/cancelledCheque',
                documentRequired: true,
                documentNumberRequired: false,
                label: 'Cancelled Cheque',
                name: 'cancelledcheque',
                fileUploadIcon: '/images/addyellow.svg',
                fileUploadLabel: 'Add Cancelled Cheque details',
                required: false

            },
            udyam: {
                s3Path: 'customers/udyam',
                documentRequired: true,
                documentNumberRequired: false,
                label: 'MSME/udyam certificate',
                name: 'udyam',
                fileUploadIcon: '/images/addyellow.svg',
                fileUploadLabel: 'Add MSME/udyam certificate details',
                required: false

            },
            tdsdeclaration: {
                s3Path: 'customers/tdsDeclaration',
                documentRequired: true,
                documentNumberRequired: false,
                label: 'TDS/TCS Declaration',
                name: 'tdsdeclaration',
                fileUploadIcon: '/images/addyellow.svg',
                fileUploadLabel: 'Add TDS/TCS Declaration details',
                required: true

            },
            registrationForm: {
                s3Path: 'customers/registrationForm',
                documentRequired: true,
                documentNumberRequired: false,
                label: 'Registration Form',
                name: 'registrationForm',
                fileUploadIcon: '/images/addyellow.svg',
                fileUploadLabel: 'Add Registration Form details',
                required: false

            }
        },
        vendor: {
            gstin: {
                s3Path: 'vendors/gst',
                documentRequired: true,
                documentNumberRequired: true,
                label: 'GSTIN',
                name: 'gstin',
                fileUploadIcon: '/images/PaperUpload.svg',
                fileUploadLabel: 'Upload GSTIN',
                required: true

            },
            pan: {
                s3Path: 'vendors/pan',
                documentRequired: true,
                documentNumberRequired: true,
                label: 'PAN',
                name: 'pan',
                fileUploadIcon: '/images/addyellow.svg',
                fileUploadLabel: 'Add PAN card details',
                required: false

            },
            tan: {
                s3Path: 'vendors/tan',
                documentRequired: true,
                documentNumberRequired: true,
                label: 'TAN',
                name: 'tan',
                fileUploadIcon: '/images/addyellow.svg',
                fileUploadLabel: 'Add TAN card details',
                required: false

            },
            cancelledcheque: {
                s3Path: 'vendors/cancelledCheque',
                documentRequired: true,
                documentNumberRequired: false,
                label: 'Cancelled Cheque',
                name: 'cancelledcheque',
                fileUploadIcon: '/images/addyellow.svg',
                fileUploadLabel: 'Add Cancelled Cheque details',
                required: false

            },
            udyam: {
                s3Path: 'vendors/udyam',
                documentRequired: true,
                documentNumberRequired: false,
                label: 'MSME/udyam certificate',
                name: 'udyam',
                fileUploadIcon: '/images/addyellow.svg',
                fileUploadLabel: 'Add MSME/udyam certificate details',
                required: false

            },
            trading: {
                s3Path: 'vendors/trading',
                documentRequired: true,
                documentNumberRequired: false,
                label: 'Trader certificate',
                name: 'trading',
                fileUploadIcon: '/images/addyellow.svg',
                fileUploadLabel: 'Add Trader certificate details',
                required: false

            },
            tdsdeclaration: {
                s3Path: 'vendors/tdsDeclaration',
                documentRequired: true,
                documentNumberRequired: false,
                label: 'TDS/TCS Declaration',
                name: 'tdsdeclaration',
                fileUploadIcon: '/images/addyellow.svg',
                fileUploadLabel: 'Add TDS/TCS Declaration details',
                required: true

            },
            registrationForm: {
                s3Path: 'vendors/registrationForm',
                documentRequired: true,
                documentNumberRequired: false,
                label: 'Registration Form',
                name: 'registrationForm',
                fileUploadIcon: '/images/addyellow.svg',
                fileUploadLabel: 'Add Registration Form details',
                required: false

            }
        }
    },
    asset: {
        vendor: {
            rcCopy: {
                s3Path: 'asset/truckDetails/rcCopy'
            }
        }
    },
    customer: {
        designation: [
            { value: 'Account Officer', label: 'Account Officer' },
            { value: 'Finance Officer', label: 'Finance Officer' },
            { value: 'Purchase Officer', label: 'Purchase Officer' },
            { value: 'Aris Internal', label: 'Aris Internal' },
            { value: 'Driver', label: 'Driver' },
            { value: 'Director', label: 'Director' },
            { value: 'Partner', label: 'Partner' },
            { value: 'Manager', label: 'Manager' },
            { value: 'Site Coordinator', label: 'Site Coordinator' }
        ],
        department: [
            { value: 'Accounts', label: 'Accounts' },
            { value: 'Finance', label: 'Finance' },
            { value: 'Purchase', label: 'Purchase' },
            { value: 'Aris Internal', label: 'Aris Internal' },
            { value: 'Transport', label: 'Transport' }
        ]
    },
    vendor: {
        designation: [
            { value: 'Account Officer', label: 'Account Officer' },
            { value: 'Finance Officer', label: 'Finance Officer' },
            { value: 'Purchase Officer', label: 'Purchase Officer' },
            { value: 'Aris Internal', label: 'Aris Internal' },
            { value: 'Driver', label: 'Driver' },
            { value: 'Director', label: 'Director' },
            { value: 'Partner', label: 'Partner' },
            { value: 'Manager', label: 'Manager' }
        ],
        department: [
            { value: 'Accounts', label: 'Accounts' },
            { value: 'Finance', label: 'Finance' },
            { value: 'Purchase', label: 'Purchase' },
            { value: 'Aris Internal', label: 'Aris Internal' },
            { value: 'Transport', label: 'Transport' }
        ]
    },

    messages: {
        error: 'Something went wrong!'
    },
    status: {
        requirement: {
            db: ['new', 'rfq_created', 'quotation', 'quotation_approved'],
            label: {
                new: 'Draft Requirement',
                rfq_created: 'RFQs Generated',
                quotation: 'CQ In progress',
                quotation_approved: 'Quotation Approved'
            },
            variant: {
                new: 'Newv1',
                rfq_created: 'Intermediate',
                quotation: 'Intermediate',
                quotation_approved: 'Complete'
            },
            actionNeeded: {
                new: 'Generate CQ',
                rfq_created: 'None',
                quotation: 'None',
                quotation_approved: 'None'
            }
        },
        requirementItem: {
            db: ['new', 'vendor_selected', 'removed', 'customer_approved']
        },
        rfq: {
            db: ['draft', 'confirmed', 'sent', 'quotation_uploaded', 'vendor_selected'],
            label: {
                draft: 'Draft Rfq',
                confirmed: 'RFQ Confirmed',
                sent: 'RFQ Sent',
                quotation_uploaded: 'Quotation Uploaded',
                vendor_selected: 'Vendor Selected'
            },
            actionNeeded: {
                draft: 'Confirm Draft',
                confirmed: 'Send RFQ to vendors',
                sent: 'None',
                quotation_uploaded: 'None',
                vendor_selected: 'None'
            }
        },
        customerQuotationApproval: {
            label: {
                internal_pending: 'Awaiting Approval',
                internal_approved: 'Approved',
                customer_pending: 'Approved',
                customer_approved: 'Approved',
                quotation_sent: 'Approved',
                quotation: 'Declined'
            },
            actionNeeded: {
                internal_pending: 'Approve',
                internal_approved: 'None',
                customer_pending: 'None',
                customer_approved: 'None',
                quotation_sent: 'None',
                quotation: 'None'
            }
        },
        vendorQuotation: {
            db: ['sent', 'received', 'new', 'selected', 'rate_unavailable'],
            label: {
                received: 'Received',
                rate_unavailable: 'No Vendor Bids Available',
                selected: 'Selected',
                new: 'Default rate'
            }
        },
        vendorQuotationItem: {
            db: ['new', 'selected']
        },
        purchaseOrder: {
            db: ['draft', 'confirmed', 'sent'],
            label: {
                draft: 'Draft Vendor PO',
                confirmed: 'Vendor PO Confirmed',
                sent: 'Vendor PO Sent',
                short_closed: 'PO Short Closed',
                paused: 'PO Paused',
                void: 'PO Void',
                closed: 'Vendor PO Closed'
            },
            variant: {
                draft: 'Newv1',
                confirmed: 'Complete',
                sent: 'Intermediate',
                short_closed: 'Error',
                paused: 'Intermediate',
                void: 'Error',
                closed: 'Error'
            },
            actionNeeded: {
                draft: 'Preview & Send',
                confirmed: 'Send to Vendor',
                sent: 'None',
                short_closed: 'None',
                paused: 'None',
                void: 'None',
                closed: 'None'
            }
        },
        customerQuotation: {
            db: ['quotation', 'draft_confirmed', 'customer_pending', 'customer_approved', 'quotation_sent', 'quotation_received', 'draft', 'internal_pending', 'internal_approved', 'vpo_generated', 'so_draft', 'so_confirmed', 'so_short_closed'],
            label: {
                quotation: 'Draft CQ',
                customer_pending: 'Awaiting Customer Approval',
                customer_approved: 'Approved by Customer',
                quotation_sent: 'Quote sent to Customer',
                quotation_received: 'Customer PO Received',
                draft: 'Draft CQ',
                internal_pending: 'Awaiting Approval',
                internal_approved: 'Internally Approved',
                vpo_generated: 'VPO Generated',
                draft_confirmed: 'Quotation Saved',
                so_draft: 'Draft SO',
                so_confirmed: 'SO Confirmed',
                so_short_closed: 'Short Closed',
                inconsistent_data: 'CQ inconsistent'
            },
            variant: {
                quotation: 'Newv1',
                customer_pending: 'Intermediate',
                customer_approved: 'Intermediate',
                quotation_sent: 'Intermediate',
                quotation_received: 'Newv1',
                draft: 'Newv1',
                internal_pending: 'Intermediate',
                internal_approved: 'Complete',
                vpo_generated: 'Intermediate',
                draft_confirmed: 'Complete',
                so_draft: 'Newv1',
                so_confirmed: 'Intermediate',
                so_short_closed: 'Error',
                inconsistent_data: 'Intermediate'
            },
            actionNeeded: {
                quotation: 'Confirm Draft',
                customer_pending: 'Resend to Customer',
                customer_approved: 'Send Final Quotation',
                quotation_sent: 'Upload Customer PO',
                quotation_received: 'None',
                draft: 'Save',
                draft_confirmed: 'Send for Internal Approval',
                internal_pending: 'Resend for Internal Approval',
                internal_approved: 'Send to Customer',
                vpo_generated: 'VPO Generated'
            }
        },
        salesOrder: {
            db: ['draft', 'confirmed'],
            label: {
                draft: 'Draft SO',
                confirmed: 'SO Generated',
                short_closed: 'SO Short Closed',
                void: 'SO Void',
                completed: 'SO Completed',
                no_item_included: 'Empty SO'
            },
            variant: {
                draft: 'Newv1',
                confirmed: 'Intermediate',
                short_closed: 'Error',
                void: 'Error',
                completed: 'Complete',
                no_item_included: 'Error'
            }
        },

        quickQuote: {
            label: {
                draft: 'Draft',
                sent: 'Quote sent'
            }
        }
    },
    grade: {
        options: [
            { label: 'Select', value: 'FE-500' },
            { label: 'FE-500', value: 'FE-500' },
            { label: 'CRS 500D', value: 'CRS 500D' },
            { label: 'FE-500D', value: 'FE-500D' }
        ]
    },
    s3Document: {
        paths: {
            vendorQuotation: 'vendor-quotations'
        }
    },
    defaultLimit: 10,
    infiniteLimit: 9999,
    documentManagerLimit: 12,
    pageLimit: 5,
    rfqVendor: {
        rateSource: {
            text: {
                new: 'Awaiting',
                defaultRates: 'Prefilled',
                received: 'Received'
            },
            statusColor: {
                new: 'Primary',
                defaultRates: 'Inactive',
                received: 'Secondary'
            }
        }
    },
    rateSource: {
        defaultRates: 'Prefilled',
        received: 'Received',
        rate_unavailable: 'Rate unavailable'
    },
    systemUserId: '2fc404fe-5a70-4788-8ab8-8b0d0c9c949f',
    emailData: {
        sendCustomerQuotation: {
            emailBody: `
            <div>
                <div>Please find attached our best offer for <b>XXX</b> based on your requirement.</div>
                <br>
                <div>Please reach out to us at <b>+91 22 69112000</b> in case of any questions.</div>
                </div>`,
            emailSubject: 'Proposal on your requirement',
            termsAndConditions: `
                <div>
                <div> Payment: <b>NET60</b></div>
                <div>Transport: Inclusive</div>
                <div>Unloading: Exclusive</div>
                <div>Billing Details as mentioned above in the document</div>
                <div>Purchase Order should include the following:</div>
                <ol>
                <li>Date & Quote#</li>
                <li>${applicationDetails?.applicationShortName} REQ# (Top-Right)</li>
                </ol>
                <div>Kindly share the purchase order in favour of this quote to ${applicationDetails?.applicationSalesEmail} <br />to expedite the delivery process.</div>
            </div>`

        },
        purchaseOrderEmail: {
            body: `
            <div>
                <p style='font-weight: 400;font-size: 14px;line-height: 19px; color:#364054; opacity:0.7; padding-top:10px;'>We are delighted to place a new order!</p>
                <p style='font-weight: 400;font-size: 14px;line-height: 19px; color:#364054; opacity:0.7; margin-top:15px;'>Enclosed is a copy of the purchase order dated DATE in line with our requirement.</p>
            </div>`
        },
        rfqEmail: {
            body: `
         <div>
            <p style='font-weight: 400;font-size: 14px;line-height: 19px; color:#364054; opacity:0.7; padding-top:10px;'>Kindly click on the button below to submit your best offer for <strong><%DEPARTMENT%></strong> at your earliest.</p>
        </div>`,
            subject: 'Offer Request - <%REQID%>'
        }
    },

    customerTC: {
        Steel: {
            short: `<div>
            <div><strong>Validity</strong> - 1 day only - Subject to Market condition</div>
            <div><strong>Rate</strong> - FOR at site</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Unloading</strong> - In {{__unloadingScope}} scope</div>
            <div><strong>Delivery</strong> - Immediate</div>
            </div>`,
            longTerms: `<div>
            <ol>
            <li>Brand and Material Grade: Bhagwati/Guardian/Tata/SAIL/JSPL/JSW Any other,</li>  
            <li>Length of the material: Straight length or Bended TMT. 12mtr only</li>
            <li>TCS under Income Tax Act’1961 and other applicable taxes if any will also be extra</li>
            <li>Vehicle retention charges are extra</li>
            <li>Weighbridge tolerance will be acceptable upto +-0.5%</li>
            <li>Order quantity tolerance will be acceptable upto +-10%</li>
            <li>Testing: Test Certificates for all the materials will be given to you. In case of testing at site, it has to be organized by you at your own cost.</li>
            <li>The invoice is towards the goods supplied in terms of the purchase order issued by the Purchaser.</li>
            <li>All risk of loss or damage will pass on to the Purchaser once the material/ goods leave our factory/ Unit.</li>
            <li>Any complaints/ grievances regarding the quality or quantity of the material/ goods supplied shall be lodged with ${applicationDetails?.applicationFullName} within 24 hours of receipt of the same. No complaints/ grievances shall be entertained once the material/ goods are utilized by the Purchaser or consignee or any other party.</li>
            <li>Subject to clause 3 above, the maximum liability of ${applicationDetails?.applicationFullName} shall not exceed the value of the goods supplied herein.</li>
            <li>Payment towards this invoice shall be made on or before the due date mentioned in this invoice, failing which, we shall be entitled to charge interest at 36% p.a. from the due date till payment/ realization thereof.</li>
            <li>This sale is governed under the laws applicable in India.</li>
            <li>Any/ all dispute(s) arising out of or in connection with this invoice between the Purchaser and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai.</li>
            </ol>
            </div>`
        },
        RMC: {
            short: `<div>
            <div><strong>Rate</strong> - For at site</div>
            <div><strong>Offer Validity</strong> - For 2 days only Subject to Market condition</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>Pumping Charges - ₹18,000 + 18% GST  for quantity upto 50 cum. ₹200 + 18% per cum GST for quantity greater then 50 cum.</li>
                <li>Idle Charges - Applicable if vertical line of pipeline is more than 12 Floors.</li>
                <li>Partload Charges - ₹3,000 + 18% GST for quantity below 3 cum.</li>
                <li>Slurry Charges - ₹3,250 + 18% GST  per cum.</li>
                <li>Waiting Charges - ₹750 per hour if waiting period more than 2 hours.<br></li>
                <li>The invoice is towards the goods supplied in terms of the purchase order issued by the Purchaser.</li>
                <li>All risk of loss or damage will pass on to the Purchaser once the material/ goods leave our factory/ Unit.</li>
                <li>Any complaints/ grievances regarding the quality of the material/ goods supplied shall be lodged with ${applicationDetails?.applicationFullName} within 28 Days of receipt of the same. Any complaints/ grievances regarding the quantity of the material/ goods supplied shall be lodged with ${applicationDetails?.applicationFullName} within 48 hours of receipt of the same. No complaints/ grievances shall be entertained once the material/ goods are utilized by the Purchaser or consignee or any other party.</li>
                <li>Subject to clause 3 above, the maximum liability of ${applicationDetails?.applicationFullName} shall not exceed the value of the goods supplied herein.</li>
                <li>Payment towards this invoice shall be made on or before the due date mentioned in this invoice, failing which, we shall be entitled to charge interest at 36% p.a. from the due date till payment/ realization thereof.</li>
                <li>This sale is governed under the laws applicable in India.</li>
                <li>Any/ all dispute(s) arising out of or in connection with this invoice between the Purchaser and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai.</li>
            </ol>
            </div>`
        },
        Aggregate: {
            short: `<div>
            <div><strong>Offer Validity</strong> - For 2 days only</div>
            <div><strong>Source</strong> - Crusher Name (if any)</div>
            <div><strong>GST</strong> - 5% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Royalty</strong> - Inclusive</div>
            <div><strong>Unloading</strong> - In Customer Scope</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement.</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>TCS under Income Tax Act’1961 and other applicable taxes if any will also be extra</li>
                <li>The invoice is towards the goods supplied in terms of the purchase order issued by the Purchaser.</li>
                <li>All risk of loss or damage will pass on to the Purchaser once the material/ goods leave our factory/ Unit.</li>
                <li>Any complaints/ grievances regarding the quality or quantity of the material/ goods supplied shall be lodged with ${applicationDetails?.applicationFullName} within 24 hours of receipt of the same. No complaints/ grievances shall be entertained once the material/ goods are utilized by the Purchaser or consignee or any other party.</li>
                <li>Subject to clause 3 above, the maximum liability of ${applicationDetails?.applicationFullName} shall not exceed the value of the goods supplied herein.</li>
                <li>Payment towards this invoice shall be made on or before the due date mentioned in this invoice, failing which, we shall be entitled to charge interest at 36% p.a. from the due date till payment/ realization thereof.</li>
                <li>This sale is governed under the laws applicable in India.</li>
                <li>Any/ all dispute(s) arising out of or in connection with this invoice between the Purchaser and
                    ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act,
                    1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai.</li>
            </ol>
            </div>`
        },
        'Fly Ash Block': {
            short: `<div>
            <div><strong>Advance Payment</strong> - {{__advancePayment}}%</div>
            <div><strong>Payment</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>TCS under Income Tax Act’1961 and other applicable taxes if any will also be extra</li>
                <li>The invoice is towards the goods supplied in terms of the purchase order issued by the Purchaser.</li>
                <li>All risk of loss or damage will pass on to the Purchaser once the material/ goods leave our factory/ Unit.</li>
                <li>Any complaints/ grievances regarding the quality or quantity of the material/ goods supplied shall be lodged with ${applicationDetails?.applicationFullName} within 24 hours of receipt of the same. No complaints/ grievances shall be entertained once the material/ goods are utilized by the Purchaser or consignee or any other party.</li>
                <li>Subject to clause 3 above, the maximum liability of ${applicationDetails?.applicationFullName} shall not exceed the value of the goods supplied herein.</li>
                <li>Payment towards this invoice shall be made on or before the due date mentioned in this invoice, failing which, we shall be entitled to charge interest at 36% p.a. from the due date till payment/ realization thereof.</li>
                <li>This sale is governed under the laws applicable in India.</li>
                <li>Any/ all dispute(s) arising out of or in connection with this invoice between the Purchaser and
                    ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act,
                    1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai.</li>
            </ol>
            </div>`
        },
        Cement: {
            short: `<div>
            <div><strong>Offer Validity</strong> - For 2 days only</div>
            <div><strong>Rate</strong> - For at site</div>
            <div><strong>GST</strong> - 28% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Unloading</strong> - In Customer Scope</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>Brand and Material Grade: Zuari/India Cements / Kalburgi / Vicat / Chettinad/Ultratech/Any other</li>
                <li>TCS under Income Tax Act’1961 and other applicable taxes if any will also be extra</li>
                <li>Testing: In case of testing at site, it has to be organized by you at your own cost.</li>
                <li>The invoice is towards the goods supplied in terms of the purchase order issued by the Purchaser.</li>
                <li>All risk of loss or damage will pass on to the Purchaser once the material/ goods leave our factory/ Unit.</li>
                <li>Any complaints/ grievances regarding the quality or quantity of the material/ goods supplied shall be lodged with ${applicationDetails?.applicationFullName} within 24 hours of receipt of the same. No complaints/ grievances shall be entertained once the material/ goods are utilized by the Purchaser or consignee or any other party.</li>
                <li>Subject to clause 3 above, the maximum liability of ${applicationDetails?.applicationFullName} shall not exceed the value of the goods supplied herein.</li>
                <li>Payment towards this invoice shall be made on or before the due date mentioned in this invoice, failing which, we shall be entitled to charge interest at 36% p.a. from the due date till payment/ realization thereof.</li>
                <li>This sale is governed under the laws applicable in India.</li>
                <li>Any/ all dispute(s) arising out of or in connection with this invoice between the Purchaser and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai. </li>
            </ol>
            </div>`
        },
        'Construction Chemicals': {
            short: `<div>
            <div><strong>Offer Validity</strong> - For 2 days only</div>
            <div><strong>Rate</strong> - For at site</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Unloading</strong> - In Customer Scope</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>Brand and Material Grade: Tremco /Fosroc/Sika/CAC/Normet (choice for selection)</li>
                <li>Barrels:- Empty Barrels are on returnable basis</li>
                <li>TCS under Income Tax Act’1961 and other applicable taxes if any will also be extra</li>
                <li>Min Order Quantity: - In Multiple of truck load, as mentioned above</li>
                <li>Testing: In case of testing at site, it has to be organized by you at your own cost.</li>
                <li>Measuring Tolerance: - ± 0.5% of total material weight</li>
                <li>Product Liability:- We are not responsible for any loss or damage that may be caused as
                a result of incorrect application of product</li>
                <li>Packing:- The Products will be supplied in our standard packing. Any special packing required will be extra charged.</li>
                <li>The invoice is towards the goods supplied in terms of the purchase order issued by the Purchaser.</li>
                <li>All risk of loss or damage will pass on to the Purchaser once the material/ goods leave our factory/ Unit.</li>
                <li>Any complaints/ grievances regarding the quality or quantity of the material/ goods supplied shall be lodged with ${applicationDetails?.applicationFullName} within 24 hours of receipt of the same. No complaints/ grievances shall be entertained once the material/ goods are utilized by the Purchaser or consignee or any other party.</li>
                <li>Subject to clause 3 above, the maximum liability of ${applicationDetails?.applicationFullName} shall not exceed the value of the goods supplied herein.</li>
                <li>Payment towards this invoice shall be made on or before the due date mentioned in this invoice, failing which, we shall be entitled to charge interest at 36% p.a. from the due date till payment/ realization thereof.</li>
                <li>This sale is governed under the laws applicable in India.</li>
                <li> Any/ all dispute(s) arising out of or in connection with this invoice between the Purchaser and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai.</li>
            </ol>
            </div>`
        },
        'Walling Solutions': {
            short: `<div>
            <div><strong>Offer Validity</strong> - For 2 days only</div>
            <div><strong>Rate</strong> - For at site</div>
            <div><strong>GST</strong> - 12% on Blocks and 18% on BJM applicable</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            <div><strong>Unloading</strong> - In Customer Scope</div>
            </div>`,
            longTerms: `<div>
            <ol>
            <li>TCS & other taxes as applicable will be deducted as per Govt Norms.</li>
            <li>E waybill, Delivery Challan and Test Report Soft copies to be submitted along with the Tax Invoice (with mentioned referred PO Number), Its compulsory.</li>
            <li>Invoice to be submitted at Zonal Office Along with. (i) FOR GOODS : a) Receipted Delivery Challan, b) LR / Weighment Slip wherever applicable, c) Material Test Certificate, d) Copy of Purchase Order and (ii) FOR SERVICE : All relevant site receipted documents in original</li>
            <li>All Documents including Challans and Invoices must bear the Purchase Order Number</li>
            <li>This Purchase Order is being sent to you in duplicate, please return the Duplicate Copy duly signed as token of acceptance within 7 Days. If not, this order shall be considered as accepted.</li>
            <li>The invoice is towards the goods supplied in terms of the purchase order issued by the Purchaser.</li>
            <li>All risk of loss or damage will pass on to the Purchaser once the material/ goods leave our factory/ Unit.</li>
            <li>Any complaints/ grievances regarding the quality or quantity of the material/ goods supplied shall be lodged with ${applicationDetails?.applicationFullName} within 48 hours of receipt of the same. No complaints/ grievances shall be entertained once the material/ goods are utilized by the Purchaser or consignee or any other party.</li>
            <li>Subject to clause 3 above, the maximum liability of ${applicationDetails?.applicationFullName} shall not exceed the value of the goods supplied herein.</li>
            <li>Payment towards this invoice shall be made on or before the due date mentioned in this invoice, failing which, we shall be entitled to charge interest at 36% p.a. from the due date till payment/ realization thereof.</li>
            <li>This sale is governed under the laws applicable in India.</li>
            <li>Any/ all dispute(s) arising out of or in connection with this invoice between the Purchaser and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai.</li>
            </ol>
            </div>`
        },
        'Wood Products': {
            short: `<div>
            <div><strong>Offer Validity</strong> - For 2 days only</div>
            <div><strong>Rate</strong> - FOR at site</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Unloading</strong> - In Customer Scope</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li> TCS under Income Tax Act’1961 and other applicable taxes if any will also be extra</li>
                <li>Testing: In case of testing at site, it has to be organized by you at your own cost.</li>
                <li>The invoice is towards the goods supplied in terms of the purchase order issued by the Purchaser.</li>
                <li>All risk of loss or damage will pass on to the Purchaser once the material/ goods leave our factory/ Unit.</li>
                <li>Any complaints/ grievances regarding the quality or quantity of the material/ goods supplied shall be lodged
                        with ${applicationDetails?.applicationFullName} within 48 hours of receipt of the same. No complaints/ grievances shall be
                        entertained once the material/ goods are utilized by the Purchaser or consignee or any other party.</li>
                <li> Subject to clause 3 above, the maximum liability of ${applicationDetails?.applicationFullName} shall not exceed the value of the goods supplied herein.</li>
                <li> Payment towards this invoice shall be made on or before the due date mentioned in this invoice, failing which, we shall be entitled to charge interest at 36% p.a. from the due date till payment/ realization thereof.</li>
                <li> This sale is governed under the laws applicable in India.</li>
                <li> Any/ all dispute(s) arising out of or in connection with this invoice between the Purchaser and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai.</li>
            </ol>
            </div>`
        },
        Minerals: {
            short: `<div>
            <div><strong>Offer Validity</strong> - For 2 days only</div>
            <div><strong>Rate</strong> - For at site</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Unloading</strong> - In Customer Scope</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>TCS under Income Tax Act’1961 and other applicable taxes if any will also be extra</li>
                <li>Testing: In case of testing at site, it has to be organized by you at your own cost.</li>
                <li>The invoice is towards the goods supplied in terms of the purchase order issued by the Purchaser.</li>
                <li>All risk of loss or damage will pass on to the Purchaser once the material/ goods leave our factory/ Unit.</li>
                <li>Any complaints/ grievances regarding the quality or quantity of the material/ goods supplied shall be lodged with ${applicationDetails?.applicationFullName} within 12 hours of receipt of the same. No complaints/ grievances shall be entertained once the material/ goods are utilized by the Purchaser or consignee or any other party.</li>
                <li>Subject to clause 3 above, the maximum liability of ${applicationDetails?.applicationFullName} shall not exceed the value of the goods supplied herein.</li>
                <li>Payment towards this invoice shall be made on or before the due date mentioned in this invoice, failing which, we shall be entitled to charge interest at 36% p.a. from the due date till payment/ realization thereof.</li>
                <li>This sale is governed under the laws applicable in India.</li>
                <li>Any/ all dispute(s) arising out of or in connection with this invoice between the Purchaser and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai.</li>
            </ol>
            </div>`
        },
        Electricals: {
            short: `<div>
            <div><strong>Offer Validity</strong> - For 2 days only</div>
            <div><strong>Rate</strong> - For at site</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Unloading</strong> - In Customer Scope</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>TCS under Income Tax Act’1961 and other applicable taxes if any will also be extra</li>
                <li>Testing: In case of testing at site, it has to be organized by you at your own cost.</li>
                <li>The invoice is towards the goods supplied in terms of the purchase order issued by the Purchaser.</li>
                <li>All risk of loss or damage will pass on to the Purchaser once the material/ goods leave our factory/ Unit.</li>
                <li>Any complaints/ grievances regarding the quality or quantity of the material/ goods supplied shall be lodged with ${applicationDetails?.applicationFullName} within 12 hours of receipt of the same. No complaints/ grievances shall be entertained once the material/ goods are utilized by the Purchaser or consignee or any other party.</li>
                <li>Subject to clause 3 above, the maximum liability of ${applicationDetails?.applicationFullName} shall not exceed the value of the goods supplied herein.</li>
                <li>Payment towards this invoice shall be made on or before the due date mentioned in this invoice, failing which, we shall be entitled to charge interest at 36% p.a. from the due date till payment/ realization thereof.</li>
                <li>This sale is governed under the laws applicable in India.</li>
                <li>Any/ all dispute(s) arising out of or in connection with this invoice between the Purchaser and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai.</li> 
            </ol>
            </div>`
        },
        Plumbing: {
            short: `<div>
            <div><strong>Offer Validity</strong> - For 2 days only</div>
            <div><strong>Rate</strong> - For at site</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Unloading</strong> - In Customer Scope</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>TCS under Income Tax Act’1961 and other applicable taxes if any will also be extra</li>
                <li>Testing: In case of testing at site, it has to be organized by you at your own cost.</li>
                <li>The invoice is towards the goods supplied in terms of the purchase order issued by the Purchaser.</li>
                <li>All risk of loss or damage will pass on to the Purchaser once the material/ goods leave our factory/ Unit.</li>
                <li>Any complaints/ grievances regarding the quality or quantity of the material/ goods supplied shall be lodged with ${applicationDetails?.applicationFullName} within 12 hours of receipt of the same. No complaints/ grievances shall be entertained once the material/ goods are utilized by the Purchaser or consignee or any other party.</li>
                <li>Subject to clause 3 above, the maximum liability of ${applicationDetails?.applicationFullName} shall not exceed the value of the goods supplied herein.</li>
                <li>Payment towards this invoice shall be made on or before the due date mentioned in this invoice, failing which, we shall be entitled to charge interest at 36% p.a. from the due date till payment/ realization thereof.</li>
                <li>This sale is governed under the laws applicable in India.</li>
                <li>Any/ all dispute(s) arising out of or in connection with this invoice between the Purchaser and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai.</li>
            </ol>
            </div>`
        },
        Floorings: {
            short: `<div>
            <div><strong>Offer Validity</strong> - For 2 days only</div>
            <div><strong>Rate</strong> - For at site</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Unloading</strong> - In Customer Scope</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>TCS under Income Tax Act’1961 and other applicable taxes if any will also be extra</li>
                <li>Testing: In case of testing at site, it has to be organized by you at your own cost.</li>
                <li>The invoice is towards the goods supplied in terms of the purchase order issued by the Purchaser.</li>
                <li>All risk of loss or damage will pass on to the Purchaser once the material/ goods leave our factory/ Unit.</li>
                <li>Any complaints/ grievances regarding the quality or quantity of the material/ goods supplied shall be lodged with ${applicationDetails?.applicationFullName} within 12 hours of receipt of the same. No complaints/ grievances shall be entertained once the material/ goods are utilized by the Purchaser or consignee or any other party.</li>
                <li>Subject to clause 3 above, the maximum liability of ${applicationDetails?.applicationFullName} shall not exceed the value of the goods supplied herein.</li>
                <li>Payment towards this invoice shall be made on or before the due date mentioned in this invoice, failing which, we shall be entitled to charge interest at 36% p.a. from the due date till payment/ realization thereof.</li>
                <li>This sale is governed under the laws applicable in India.</li>
                <li>Any/ all dispute(s) arising out of or in connection with this invoice between the Purchaser and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai.</li>
            </ol>
            </div>`
        },
        'Doors & Windows': {
            short: `<div>
            <div><strong>Offer Validity</strong> - For 2 days only</div>
            <div><strong>Rate</strong> - For at site</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Unloading</strong> - In Customer Scope</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>TCS under Income Tax Act’1961 and other applicable taxes if any will also be extra</li>
                <li>Testing: In case of testing at site, it has to be organized by you at your own cost.</li>
                <li>The invoice is towards the goods supplied in terms of the purchase order issued by the Purchaser.</li>
                <li>All risk of loss or damage will pass on to the Purchaser once the material/ goods leave our factory/ Unit.</li>
                <li>Any complaints/ grievances regarding the quality or quantity of the material/ goods supplied shall be lodged with ${applicationDetails?.applicationFullName} within 12 hours of receipt of the same. No complaints/ grievances shall be entertained once the material/ goods are utilized by the Purchaser or consignee or any other party.</li>
                <li>Subject to clause 3 above, the maximum liability of ${applicationDetails?.applicationFullName} shall not exceed the value of the goods supplied herein.</li>
                <li>Payment towards this invoice shall be made on or before the due date mentioned in this invoice, failing which, we shall be entitled to charge interest at 36% p.a. from the due date till payment/ realization thereof.</li>
                <li>This sale is governed under the laws applicable in India.</li>
                <li>Any/ all dispute(s) arising out of or in connection with this invoice between the Purchaser and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai.</li>
            </ol>
            </div>`
        },
        'Other Products': {
            short: `<div>
            <div><strong>Offer Validity</strong> - For 2 days only</div>
            <div><strong>Rate</strong> - For at site</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Unloading</strong> - In Customer Scope</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>TCS under Income Tax Act’1961 and other applicable taxes if any will also be extra</li>
                <li>Testing: In case of testing at site, it has to be organized by you at your own cost.</li>
                <li>The invoice is towards the goods supplied in terms of the purchase order issued by the Purchaser.</li>
                <li>All risk of loss or damage will pass on to the Purchaser once the material/ goods leave our factory/ Unit.</li>
                <li>Any complaints/ grievances regarding the quality or quantity of the material/ goods supplied shall be lodged with ${applicationDetails?.applicationFullName} within 12 hours of receipt of the same. No complaints/ grievances shall be entertained once the material/ goods are utilized by the Purchaser or consignee or any other party.</li>
                <li>Subject to clause 3 above, the maximum liability of ${applicationDetails?.applicationFullName} shall not exceed the value of the goods supplied herein.</li>
                <li>Payment towards this invoice shall be made on or before the due date mentioned in this invoice, failing which, we shall be entitled to charge interest at 36% p.a. from the due date till payment/ realization thereof.</li>
                <li>This sale is governed under the laws applicable in India.</li>
                <li>Any/ all dispute(s) arising out of or in connection with this invoice between the Purchaser and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai.</li>
            </ol>
            </div>`
        },
        Services: {
            short: `<div>
            <div><strong>Offer Validity</strong> - For 2 days only</div>
            <div><strong>Rate</strong> - For at site</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
            <li>  TCS under Income Tax Act’1961 and other applicable taxes if any will also be extra</li>
            <li>  Testing: In case of testing at site, it has to be organized by you at your own cost.</li>
            <li>  The invoice is towards the goods supplied in terms of the purchase order issued by the Purchaser.</li>
            <li>  All risk of loss or damage will pass on to the Purchaser once the material/ goods leave our factory/ Unit.</li>
            <li>  Any complaints/ grievances regarding the quality or quantity of the material/ goods supplied shall be lodged with ${applicationDetails?.applicationFullName} within 12 hours of receipt of the same. No complaints/ grievances shall be entertained once the material/ goods are utilized by the Purchaser or consignee or any other party.</li>
            <li>  Subject to clause 3 above, the maximum liability of ${applicationDetails?.applicationFullName} shall not exceed the value of the goods supplied herein.</li>
            <li>  Payment towards this invoice shall be made on or before the due date mentioned in this invoice, failing which, we shall be entitled to charge interest at 36% p.a. from the due date till payment/ realization thereof.</li>
            <li>  This sale is governed under the laws applicable in India.</li>
            <li>  Any/ all dispute(s) arising out of or in connection with this invoice between the Purchaser and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai.</li>
            </ol>
            </div>`
        }
    },
    vendorTC: {
        Steel: {
            short: `<div>
            <div><strong>Rate</strong> - FOR at site</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Unloading</strong> - In {{__unloadingScope}} scope</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>Brand and Material Grade: Tata/SAIL/JSPL/Any other, FE550D/FE500D</li>
                <li>Length of the material: Straight length or Bended TMT. 12mtr only</li>
                <li>TDS under Income Tax Act’1961 will be deducted as per Government norms.</li>
                <li>Weighbridge tolerance will be acceptable up to +-0.5%</li>
                <li>Testing: Test Certificates for all the materials will be given by you. In case of testing at site, it has to be organized by you at your own cost.</li>
                <li>Excess quantity will be not acceptable, so kindly do not load excess material against PO.</li>
                <li>MTC should me match with TAG (Heat No./Lot No.) for cross verification by site quality team</li>
                <li>All Materials shall Confirm to IS:1786 Specification, the above material should pass the Test in Physical and Chemical Properties, failing which, It will be rejected at your risk & cost. If any material is rejected, the same shall be taken back/removed from the project site premises within 7days, after this period, we will not be held responsible for the materials lying at site.</li>
                <li>E waybill, Delivery Challan, Weight Slip and Test Report Soft copies to be submitted along with the Tax Invoice(with mentioned referred PO Number), Its compulsory.</li>
                <li>Invoice to be submitted at Zonal Office Along with.</li>
                    <ol>
                        <li>FOR GOODS : a) Receipted Delivery Challan, b) LR / Weighment Slip wherever applicable, c) Material Test Certificate, d) Copy of Purchase Order and</li>
                        <li>FOR SERVICE : All relevant site receipted documents in original</li>
                    </ol>
                <li>All Documents including Challans and Invoices must bear the Purchase Order Number.</li>
                <li>Contact Person Name -</li>
                <li>Mobile No. -</li>
                <li>In the event, any dispute arises between the parties in relation to the quality and/ or quantity of the goods/ material supplied to ${applicationDetails?.applicationFullName} and/ or on behalf of ${applicationDetails?.applicationFullName}, then the decision of ${applicationDetails?.applicationFullName} in relation to the same, shall be final and binding upon the Vendor;</li>
                <li>The Vendor hereby unconditionally agrees and undertakes to indemnify and keep ${applicationDetails?.applicationFullName} indemnified from and against all costs, losses, claims, actions, penalties, demands, suits, damages, liability, etc. that may be suffered/ likely to be suffered, caused or incurred/ likely to be incurred by ${applicationDetails?.applicationFullName} on account of any claims/ grievances being raised by the end users/ consumers against ${applicationDetails?.applicationFullName} in relation to the quality and/ or quantity of the goods/ material supplied by the Vendor;</li>
                <li>Any/ all dispute(s) arising out of or in connection with this Purchase Order between the Vendor and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai;</li>
                <li>This Purchase Order shall be governed by the laws of India and the courts situated in Mumbai shall have the exclusive jurisdiction</li>
            </ol>
            </div>`
        },
        RMC: {
            short: `<div>
            <div><strong>Rate</strong> - FOR at site</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Loading</strong> - Included</div>
            <div><strong>Delivery</strong> - As per Immediate / Site requirement / Schedule.</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>TDS & other taxes as applicable will be deducted as per Govt Norms</li>
                <li>E waybill, Delivery Challan Soft copies to be submitted along with the Tax Invoice (with mentioned referred PO Number), Its compulsory. </li>
                <li>Concrete results shall strictly achieve more than 70% of Characteristic strength (fck) at 7 days and more than 100 % of fck at 28 days.       </li>
                <li>Concrete flow at site shall be 650+/- 50mm and slump shall be 175+/-25mm at site unless specified otherwise.      </li>
                <li>In case of the above criteria not meeting, and Destructive/ non-destructive test (DT/NDT) suggested by Structural consultant shall be executed by RMC Vendor at their cost. If core/ NDT fails, any cost of jacketing / fibre wrapping suggested by the structural consultant shall be borne by RMC Vendor.</li>
                <li>Cubes shall be cast by RMC Vendor’s Field technician (FT) at site and shall be stored / cured at site. These cube results shall be binding to both parties.</li>
                <li>Mix correction sheet shall be sent with the first vehicle that comes to Construction site.       </li>
                <li>Batch sheet and challan shall be delivered together along with every vehicle.       </li>
                <li>No water or admixture addition will be allowed at site. If done, vehicle shall be rejected.      </li>
                <li>Customer reserves the right to weigh randomly 1 in 20 vehicles received at site, the weighment cost shall be borne by RMC Vendor and results shall be accepted by both parties.       </li>
                <li>Yield/ Deviance in actual and theoretical quantity shall not be more 1%.       </li>
                <li>Weekly and monthly reports of materials shall be submitted by RMC Vendor to Customer.    </li>
                <li>Cubes shall be cast by RMC Vendor FT as per frequency mentioned in IS 456-2000.       </li>
                <li>Vehicles should reach site within 1 hour from the time of batching. Vehicles exceeding 3hrs from the time of batching shall be rejected.  </li>
                <li>In Accepting this Purchase Order, seller agrees to furnish the goods specified in full accordance with all conditions set forth herein and / or attachments hereto. All drawings, designs, specifications, and other data prepared by Owner and related thereto are the property of the Owner and must be returned to herein Owner upon completion by seller of the obligations under this purchase order. The information contained is not to be released or disclosed for any other use or purpose other than for the execution of this purchase order. This formal purchase order constitutes the entire agreement and only written changes by way of an amendment to this purchase order will be legally binding. It is important that seller signs and returns the purchase order copy within seven (7) days of receipt. No other form of acceptance will be accepted. Failure to return the acceptance does not diminish the responsibilities as set forth herein but may result in a delay to any payments that may be due and may cause for termination of this purchase order.</li>
                <li>Any delay in delivery would attract penalty on account of loss of work .in case if vendors fails to supply material in time as per the schedule provided in our purchased order, we reserve the right to procure similar material from the market and the difference in procurement cost, if any would be recovered from the vendor’s accounts.</li>
                <li>In the event, any dispute arises between the parties in relation to the quality and/ or quantity of the goods/ material supplied to ${applicationDetails?.applicationFullName} and/ or on behalf of ${applicationDetails?.applicationFullName}, then the decision of ${applicationDetails?.applicationFullName} in relation to the same, shall be final and binding upon the Vendor;</li>
                <li>The Vendor hereby unconditionally agrees and undertakes to indemnify and keep ${applicationDetails?.applicationFullName} indemnified from and against all costs, losses, claims, actions, penalties, demands, suits, damages, liability, etc. that may be suffered/ likely to be suffered, caused or incurred/ likely to be incurred by ${applicationDetails?.applicationFullName} on account of any claims/ grievances being raised by the end users/ consumers against ${applicationDetails?.applicationFullName} in relation to the quality and/ or quantity of the goods/ material supplied by the Vendor;</li>
                <li>Any/ all dispute(s) arising out of or in connection with this Purchase Order between the Vendor and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai;</li>
                <li>This Purchase Order shall be governed by the laws of India and the courts situated in Mumbai shall have the exclusive jurisdiction</li>
            </ol>
            </div>`
        },
        Aggregate: {
            short: `<div>
            <div><strong>Source</strong> - Crusher Name (if any)</div>
            <div><strong>GST</strong> - 5% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Royalty</strong> - Inclusive</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>TDS & other taxes as applicable will be deducted as per Govt Norms</li>
                <li>E waybill, Delivery Challan and Test Report Soft copies to be submitted along with the Tax Invoice (with mentioned referred PO Number), Its compulsory.</li>
                <li>Invoice to be submitted at Zonal Office Along with.</li>
                    <ol>
                        <li>FOR GOODS : a) Receipted Delivery Challan, b) LR / Weighment Slip wherever applicable, c) Material Test Certificate, d) Copy of Purchase Order and</li>
                        <li> FOR SERVICE : All relevant site receipted documents in original</li>
                    </ol>
                <li>All Documents including Challans and Invoices must bear the Purchase Order Number</li>
                <li>This Purchase Order is being sent to you in duplicate, please return the Duplicate Copy duly signed as token of acceptance within 7 Days. If not, this order shall be considered as accepted.</li>
                <li>Testing: Test Certificates for all the materials will be given by you. In case of testing at site, it has to be organized by you at your own cost</li>
                <li>Excess quantity will be not acceptable, so kindly do not load excess material against PO.</li>
                <li>Contact Person Name -</li>
                <li>Mobile No. -<br></li>
                <li>In the event, any dispute arises between the parties in relation to the quality and/ or quantity of the goods/ material supplied to ${applicationDetails?.applicationFullName} and/ or on behalf of ${applicationDetails?.applicationFullName}, then the decision of ${applicationDetails?.applicationFullName} in relation to the same, shall be final and binding upon the Vendor;</li>
                <li>The Vendor hereby unconditionally agrees and undertakes to indemnify and keep ${applicationDetails?.applicationFullName} indemnified from and against all costs, losses, claims, actions, penalties, demands, suits, damages, liability, etc. that may be suffered/ likely to be suffered, caused or incurred/ likely to be incurred by ${applicationDetails?.applicationFullName} on account of any claims/ grievances being raised by the end users/ consumers against ${applicationDetails?.applicationFullName} in relation to the quality and/ or quantity of the goods/ material supplied by the Vendor;</li>
                <li>Any/ all dispute(s) arising out of or in connection with this Purchase Order between the Vendor and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai;</li>
                <li>This Purchase Order shall be governed by the laws of India and the courts situated in Mumbai shall have the exclusive jurisdiction</li>
            </ol>
            </div>`
        },
        'Fly Ash Block': {
            short: `<div>
            <div><strong>Advance Payment</strong> - {{__advancePayment}}%</div>
            <div><strong>Payment</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            </div>`,
            longTerms: null
        },
        Cement: {
            short: `<div>
            <div><strong>Rate</strong> - FOR at site</div>
            <div><strong>GST</strong> - 28% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Loading</strong> - Included</div>
            <div><strong>Unloading</strong> - In Customer scope</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>TDS & other taxes as applicable will be deducted as per Govt Norms</li>
                <li>E waybill, Delivery Challan and Test Report Soft copies to be submitted along with the Tax Invoice (with mentioned referred PO Number), Its compulsory.</li>
                <li>Invoice to be submitted at Zonal Office Along with.</li>
                    <ol>
                        <li>FOR GOODS : a) Receipted Delivery Challan, b) LR / Weighment Slip wherever applicable, c) Material Test Certificate, d) Copy of Purchase Order and</li>
                        <li> FOR SERVICE : All relevant site receipted documents in original</li>
                    </ol>
                <li>All Documents including Challans and Invoices must bear the Purchase Order Number</li>
                <li>This Purchase Order is being sent to you in duplicate, please return the Duplicate Copy duly signed as token of acceptance within 7 Days. If not, this order shall be considered as accepted.</li>
                <li>Testing: Test Certificates for all the materials will be given by you. In case of testing at site, it has to be organized by you at your own cost</li>
                <li>Excess quantity will be not acceptable, so kindly do not load excess material against PO.</li>
                <li>Contact Person Name -</li>
                <li>Mobile No. -</li>
                <li>In the event, any dispute arises between the parties in relation to the quality and/ or quantity of the goods/ material supplied to ${applicationDetails?.applicationFullName} and/ or on behalf of ${applicationDetails?.applicationFullName}, then the decision of ${applicationDetails?.applicationFullName} in relation to the same, shall be final and binding upon the Vendor;</li>
                <li>The Vendor hereby unconditionally agrees and undertakes to indemnify and keep ${applicationDetails?.applicationFullName} indemnified from and against all costs, losses, claims, actions, penalties, demands, suits, damages, liability, etc. that may be suffered/ likely to be suffered, caused or incurred/ likely to be incurred by ${applicationDetails?.applicationFullName} on account of any claims/ grievances being raised by the end users/ consumers against ${applicationDetails?.applicationFullName} in relation to the quality and/ or quantity of the goods/ material supplied by the Vendor;</li>
                <li>Any/ all dispute(s) arising out of or in connection with this Purchase Order between the Vendor and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai;</li>
                <li>This Purchase Order shall be governed by the laws of India and the courts situated in Mumbai shall have the exclusive jurisdiction</li>
            </ol>
            </div>`
        },
        'Construction Chemicals': {
            short: `<div>
            <div><strong>Rate</strong> - FOR at site</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Loading</strong> - Included</div>
            <div><strong>Unloading</strong> - In Customer Scope</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>TDS & other taxes as applicable will be deducted as per Govt Norms</li>
                <li>E waybill, Delivery Challan and Test Report Soft copies to be submitted along with the Tax Invoice (with mentioned referred PO Number), Its compulsory.</li>
                <li>Invoice to be submitted at Zonal Office Along with.</li>
                    <ol>
                        <li>FOR GOODS : a) Receipted Delivery Challan, b) LR / Weighment Slip wherever applicable, c) Material Test Certificate, d) Copy of Purchase Order and</li>
                        <li> FOR SERVICE : All relevant site receipted documents in original</li>
                    </ol>
                <li>All Documents including Challans and Invoices must bear the Purchase Order Number</li>
                <li>This Purchase Order is being sent to you in duplicate, please return the Duplicate Copy duly signed as token of acceptance within 7 Days. If not, this order shall be considered as accepted.</li>
                <li>Testing: Test Certificates for all the materials will be given by you. In case of testing at site, it has to be organized by you at your own cost</li>
                <li>Excess quantity will be not acceptable, so kindly do not load excess material against PO.</li>
                <li>Contact Person Name -</li>
                <li>Mobile No. -</li>
                <li>In the event, any dispute arises between the parties in relation to the quality and/ or quantity of the goods/ material supplied to ${applicationDetails?.applicationFullName} and/ or on behalf of ${applicationDetails?.applicationFullName}, then the decision of ${applicationDetails?.applicationFullName} in relation to the same, shall be final and binding upon the Vendor;</li>
                <li>The Vendor hereby unconditionally agrees and undertakes to indemnify and keep ${applicationDetails?.applicationFullName} indemnified from and against all costs, losses, claims, actions, penalties, demands, suits, damages, liability, etc. that may be suffered/ likely to be suffered, caused or incurred/ likely to be incurred by ${applicationDetails?.applicationFullName} on account of any claims/ grievances being raised by the end users/ consumers against ${applicationDetails?.applicationFullName} in relation to the quality and/ or quantity of the goods/ material supplied by the Vendor;</li>
                <li>Any/ all dispute(s) arising out of or in connection with this Purchase Order between the Vendor and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai;</li>
                <li>This Purchase Order shall be governed by the laws of India and the courts situated in Mumbai shall have the exclusive jurisdiction</li>
            </ol>
            </div>`
        },
        'Walling Solutions': {
            short: `<div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Rate</strong> - FOR at site</div>
            <div><strong>GST</strong> - 12% on Blocks and 18% on BJM applicable</div>
            <div><strong>Loading</strong> - Included</div>
            <div><strong>Unloading</strong> - In Customer Scope</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>3% Breakage is permissible, if it is more than this, will be deducted from Vendor</li>
                <li>TDS & other taxes as applicable will be deducted as per Govt Norms</li>
                <li>E waybill, Delivery Challan and Test Report Soft copies to be submitted along with the Tax Invoice (with mentioned referred PO Number), Its compulsory.</li>
                <li>Invoice to be submitted at Zonal Office Along with.</li>
                    <ol>
                        <li>FOR GOODS : a) Receipted Delivery Challan, b) LR / Weighment Slip wherever applicable, c) Material Test Certificate, d) Copy of Purchase Order and</li>
                        <li> FOR SERVICE : All relevant site receipted documents in original</li>
                    </ol>
                <li>All Documents including Challans and Invoices must bear the Purchase Order Number</li>
                <li>This Purchase Order is being sent to you in duplicate, please return the Duplicate Copy duly signed as token of acceptance within 7 Days. If not, this order shall be considered as accepted.</li>
                <li>In the event, any dispute arises between the parties in relation to the quality and/ or quantity of the goods/ material supplied to ${applicationDetails?.applicationFullName} and/ or on behalf of ${applicationDetails?.applicationFullName}, then the decision of ${applicationDetails?.applicationFullName} in relation to the same, shall be final and binding upon the Vendor;</li>
                <li>The Vendor hereby unconditionally agrees and undertakes to indemnify and keep ${applicationDetails?.applicationFullName} indemnified from and against all costs, losses, claims, actions, penalties, demands, suits, damages, liability, etc. that may be suffered/ likely to be suffered, caused or incurred/ likely to be incurred by ${applicationDetails?.applicationFullName} on account of any claims/ grievances being raised by the end users/ consumers against ${applicationDetails?.applicationFullName} in relation to the quality and/ or quantity of the goods/ material supplied by the Vendor;</li>
                <li>Any/ all dispute(s) arising out of or in connection with this Purchase Order between the Vendor and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai;</li>
                <li>This Purchase Order shall be governed by the laws of India and the courts situated in Mumbai shall have the exclusive jurisdiction</li>
            </ol>
            </div>`
        },
        'Wood Products': {
            short: `<div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Rate</strong> - FOR at site</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Loading</strong> - Included</div>
            <div><strong>Unloading</strong> - In Customer Scope</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>TDS & other taxes as applicable will be deducted as per Govt Norms</li>
                <li>E waybill, Delivery Challan and Test Report Soft copies to be submitted along with the Tax Invoice (with mentioned referred PO Number), Its compulsory.</li>
                <li>Invoice to be submitted at Zonal Office Along with.</li>
                    <ol>
                        <li>FOR GOODS : a) Receipted Delivery Challan, b) LR / Weighment Slip wherever applicable, c) Material Test Certificate, d) Copy of Purchase Order and</li>
                        <li>FOR SERVICE : All relevant site receipted documents in original</li>
                    </ol>
                <li>All Documents including Challans and Invoices must bear the Purchase Order Number</li>
                <li>This Purchase Order is being sent to you in duplicate, please return the Duplicate Copy duly signed as token of acceptance within 7 Days. If not, this order shall be considered as accepted.</li>
                <li>Testing: Test Certificates for all the materials will be given by you. In case of testing at site, it has to be organized by you at your own cost</li>
                <li>Excess quantity will be not acceptable, so kindly do not load excess material against PO.</li>
                <li>Contact Person Name -</li>
                <li>Mobile No. -</li>
                <li>In the event, any dispute arises between the parties in relation to the quality and/ or quantity of the goods/ material supplied to ${applicationDetails?.applicationFullName} and/ or on behalf of ${applicationDetails?.applicationFullName}, then the decision of ${applicationDetails?.applicationFullName} in relation to the same, shall be final and binding upon the Vendor;</li>
                <li>The Vendor hereby unconditionally agrees and undertakes to indemnify and keep ${applicationDetails?.applicationFullName} indemnified from and against all costs, losses, claims, actions, penalties, demands, suits, damages, liability, etc. that may be suffered/ likely to be suffered, caused or incurred/ likely to be incurred by ${applicationDetails?.applicationFullName} on account of any claims/ grievances being raised by the end users/ consumers against ${applicationDetails?.applicationFullName} in relation to the quality and/ or quantity of the goods/ material supplied by the Vendor;</li>
                <li>Any/ all dispute(s) arising out of or in connection with this Purchase Order between the Vendor and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai;</li>
                <li>This Purchase Order shall be governed by the laws of India and the courts situated in Mumbai shall have the exclusive jurisdiction</li>
            </ol>
            </div>`
        },
        Minerals: {
            short: `<div>
            <div><strong>Rate</strong> - FOR at site</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Loading</strong> - Included</div>
            <div><strong>Unloading</strong> - In Customer Scope</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>TDS & other taxes as applicable will be deducted as per Govt Norms</li>
                <li>E waybill, Delivery Challan and Test Report Soft copies to be submitted along with the Tax Invoice (with mentioned referred PO Number), Its compulsory.</li>
                <li>Invoice to be submitted at Zonal Office Along with.</li>
                    <ol>
                        <li>FOR GOODS : a) Receipted Delivery Challan, b) LR / Weighment Slip wherever applicable, c) Material Test Certificate, d) Copy of Purchase Order and</li>
                        <li> FOR SERVICE : All relevant site receipted documents in original</li>
                    </ol>
                <li>All Documents including Challans and Invoices must bear the Purchase Order Number</li>
                <li>This Purchase Order is being sent to you in duplicate, please return the Duplicate Copy duly signed as token of acceptance within 7 Days. If not, this order shall be considered as accepted.</li>
                <li>Contact Person Name -</li>
                <li>Mobile No. -<br></li>
                <li>In the event, any dispute arises between the parties in relation to the quality and/ or quantity of the goods/ material supplied to ${applicationDetails?.applicationFullName} and/ or on behalf of ${applicationDetails?.applicationFullName}, then the decision of ${applicationDetails?.applicationFullName} in relation to the same, shall be final and binding upon the Vendor;</li>
                <li>The Vendor hereby unconditionally agrees and undertakes to indemnify and keep ${applicationDetails?.applicationFullName} indemnified from and against all costs, losses, claims, actions, penalties, demands, suits, damages, liability, etc. that may be suffered/ likely to be suffered, caused or incurred/ likely to be incurred by ${applicationDetails?.applicationFullName} on account of any claims/ grievances being raised by the end users/ consumers against ${applicationDetails?.applicationFullName} in relation to the quality and/ or quantity of the goods/ material supplied by the Vendor;</li>
                <li>Any/ all dispute(s) arising out of or in connection with this Purchase Order between the Vendor and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai;</li>
                <li>This Purchase Order shall be governed by the laws of India and the courts situated in Mumbai shall have the exclusive jurisdiction</li>
            </ol>
            </div>`
        },
        Electricals: {
            short: `<div>
            <div><strong>Rate</strong> - FOR at site</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Loading</strong> - Included</div>
            <div><strong>Unloading</strong> - In Customer Scope</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>TDS & other taxes as applicable will be deducted as per Govt Norms</li>
                <li>E waybill, Delivery Challan and Test Report Soft copies to be submitted along with the Tax Invoice (with mentioned referred PO Number), Its compulsory.</li>
                <li>Invoice to be submitted at Zonal Office Along with.</li>
                    <ol>
                        <li>FOR GOODS : a) Receipted Delivery Challan, b) LR / Weighment Slip wherever applicable, c) Material Test Certificate, d) Copy of Purchase Order and</li>
                        <li> FOR SERVICE : All relevant site receipted documents in original</li>
                    </ol>
                <li>All Documents including Challans and Invoices must bear the Purchase Order Number</li>
                <li>This Purchase Order is being sent to you in duplicate, please return the Duplicate Copy duly signed as token of acceptance within 7 Days. If not, this order shall be considered as accepted.</li>
                <li>Contact Person Name -</li>
                <li>Mobile No. -<br></li>
                <li>In the event, any dispute arises between the parties in relation to the quality and/ or quantity of the goods/ material supplied to ${applicationDetails?.applicationFullName} and/ or on behalf of ${applicationDetails?.applicationFullName}, then the decision of ${applicationDetails?.applicationFullName} in relation to the same, shall be final and binding upon the Vendor;</li>
                <li>The Vendor hereby unconditionally agrees and undertakes to indemnify and keep ${applicationDetails?.applicationFullName} indemnified from and against all costs, losses, claims, actions, penalties, demands, suits, damages, liability, etc. that may be suffered/ likely to be suffered, caused or incurred/ likely to be incurred by ${applicationDetails?.applicationFullName} on account of any claims/ grievances being raised by the end users/ consumers against ${applicationDetails?.applicationFullName} in relation to the quality and/ or quantity of the goods/ material supplied by the Vendor;</li>
                <li>Any/ all dispute(s) arising out of or in connection with this Purchase Order between the Vendor and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai;</li>
                <li>This Purchase Order shall be governed by the laws of India and the courts situated in Mumbai shall have the exclusive jurisdiction</li>
            </ol>
            </div>`
        },
        Plumbing: {
            short: `<div>
            <div><strong>Rate</strong> - FOR at site</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Loading</strong> - Included</div>
            <div><strong>Unloading</strong> - In Customer Scope</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>TDS & other taxes as applicable will be deducted as per Govt Norms</li>
                <li>E waybill, Delivery Challan and Test Report Soft copies to be submitted along with the Tax Invoice (with mentioned referred PO Number), Its compulsory.</li>
                <li>Invoice to be submitted at Zonal Office Along with.</li>
                    <ol>
                        <li>FOR GOODS : a) Receipted Delivery Challan, b) LR / Weighment Slip wherever applicable, c) Material Test Certificate, d) Copy of Purchase Order and</li>
                        <li> FOR SERVICE : All relevant site receipted documents in original</li>
                    </ol>
                <li>All Documents including Challans and Invoices must bear the Purchase Order Number</li>
                <li>This Purchase Order is being sent to you in duplicate, please return the Duplicate Copy duly signed as token of acceptance within 7 Days. If not, this order shall be considered as accepted.</li>
                <li>Contact Person Name -</li>
                <li>Mobile No. -<br></li>
                <li>In the event, any dispute arises between the parties in relation to the quality and/ or quantity of the goods/ material supplied to ${applicationDetails?.applicationFullName} and/ or on behalf of ${applicationDetails?.applicationFullName}, then the decision of ${applicationDetails?.applicationFullName} in relation to the same, shall be final and binding upon the Vendor;</li>
                <li>The Vendor hereby unconditionally agrees and undertakes to indemnify and keep ${applicationDetails?.applicationFullName} indemnified from and against all costs, losses, claims, actions, penalties, demands, suits, damages, liability, etc. that may be suffered/ likely to be suffered, caused or incurred/ likely to be incurred by ${applicationDetails?.applicationFullName} on account of any claims/ grievances being raised by the end users/ consumers against ${applicationDetails?.applicationFullName} in relation to the quality and/ or quantity of the goods/ material supplied by the Vendor;</li>
                <li>Any/ all dispute(s) arising out of or in connection with this Purchase Order between the Vendor and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai;</li>
                <li>This Purchase Order shall be governed by the laws of India and the courts situated in Mumbai shall have the exclusive jurisdiction</li>
            </ol>
            </div>`
        },
        Floorings: {
            short: `<div>
            <div><strong>Rate</strong> - FOR at site</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Loading</strong> - Included</div>
            <div><strong>Unloading</strong> - In Customer Scope</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>TDS & other taxes as applicable will be deducted as per Govt Norms</li>
                <li>E waybill, Delivery Challan and Test Report Soft copies to be submitted along with the Tax Invoice (with mentioned referred PO Number), Its compulsory.</li>
                <li>Invoice to be submitted at Zonal Office Along with.</li>
                    <ol>
                        <li>FOR GOODS : a) Receipted Delivery Challan, b) LR / Weighment Slip wherever applicable, c) Material Test Certificate, d) Copy of Purchase Order and</li>
                        <li> FOR SERVICE : All relevant site receipted documents in original</li>
                    </ol>
                <li>All Documents including Challans and Invoices must bear the Purchase Order Number</li>
                <li>This Purchase Order is being sent to you in duplicate, please return the Duplicate Copy duly signed as token of acceptance within 7 Days. If not, this order shall be considered as accepted.</li>
                <li>Contact Person Name -</li>
                <li>Mobile No. -<br></li>
                <li>In the event, any dispute arises between the parties in relation to the quality and/ or quantity of the goods/ material supplied to ${applicationDetails?.applicationFullName} and/ or on behalf of ${applicationDetails?.applicationFullName}, then the decision of ${applicationDetails?.applicationFullName} in relation to the same, shall be final and binding upon the Vendor;</li>
                <li>The Vendor hereby unconditionally agrees and undertakes to indemnify and keep ${applicationDetails?.applicationFullName} indemnified from and against all costs, losses, claims, actions, penalties, demands, suits, damages, liability, etc. that may be suffered/ likely to be suffered, caused or incurred/ likely to be incurred by ${applicationDetails?.applicationFullName} on account of any claims/ grievances being raised by the end users/ consumers against ${applicationDetails?.applicationFullName} in relation to the quality and/ or quantity of the goods/ material supplied by the Vendor;</li>
                <li>Any/ all dispute(s) arising out of or in connection with this Purchase Order between the Vendor and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai;</li>
                <li>This Purchase Order shall be governed by the laws of India and the courts situated in Mumbai shall have the exclusive jurisdiction</li>
            </ol>
            </div>`
        },
        'Doors & Windows': {
            short: `<div>
            <div><strong>Rate</strong> - FOR at site</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Loading</strong> - Included</div>
            <div><strong>Unloading</strong> - In Customer Scope</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>TDS & other taxes as applicable will be deducted as per Govt Norms</li>
                <li>E waybill, Delivery Challan and Test Report Soft copies to be submitted along with the Tax Invoice (with mentioned referred PO Number), Its compulsory.</li>
                <li>Invoice to be submitted at Zonal Office Along with.</li>         
                    <ol>
                        <li>FOR GOS : a) Receipted Delivery Challan, b) LR / Weighment Slip wherever applicable, c) Material Test Certificate, d) Copy of Purchase Order and</li>
                        <li> FOR SERVICE : All relevant site receipted documents in original</li>
                    </ol>
                <li>All Documents including Challans and Invoices must bear the Purchase Order Number</li>
                <li>This Purchase Order is being sent to you in duplicate, please return the Duplicate Copy duly signed as token of acceptance within 7 Days. If not, this order shall be considered as accepted.</li>
                <li>Contact Person Name -</li>
                <li>Mobile No. - <br></li>
                <li>In the event, any dispute arises between the parties in relation to the quality and/ or quantity of the goods/ material supplied to ${applicationDetails?.applicationFullName} and/ or on behalf of ${applicationDetails?.applicationFullName}, then the decision of ${applicationDetails?.applicationFullName} in relation to the same, shall be final and binding upon the Vendor;</li>
                <li>The Vendor hereby unconditionally agrees and undertakes to indemnify and keep ${applicationDetails?.applicationFullName} indemnified from and against all costs, losses, claims, actions, penalties, demands, suits, damages, liability, etc. that may be suffered/ likely to be suffered, caused or incurred/ likely to be incurred by ${applicationDetails?.applicationFullName} on account of any claims/ grievances being raised by the end users/ consumers against ${applicationDetails?.applicationFullName} in relation to the quality and/ or quantity of the goods/ material supplied by the Vendor;</li>
                <li>Any/ all dispute(s) arising out of or in connection with this Purchase Order between the Vendor and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai;</li>
                <li>This Purchase Order shall be governed by the laws of India and the courts situated in Mumbai shall have the exclusive jurisdiction</li>
            </ol>
            </div>`
        },
        'Other Products': {
            short: `<div>
            <div><strong>Rate</strong> - FOR at site</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Loading</strong> - Included</div>
            <div><strong>Unloading</strong> - In Customer Scope</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>TDS & other taxes as applicable will be deducted as per Govt Norms</li>
                <li>E waybill, Delivery Challan and Test Report Soft copies to be submitted along with the Tax Invoice (with mentioned referred PO Number), Its compulsory.</li>
                <li>Invoice to be submitted at Zonal Office Along with.</li>
                    <ol>
                        <li>FOR GOODS : a) Receipted Delivery Challan, b) LR / Weighment Slip wherever applicable, c) Material Test Certificate, d) Copy of Purchase Order and</li>
                        <li> FOR SERVICE : All relevant site receipted documents in original</li>
                    </ol>
                <li>All Documents including Challans and Invoices must bear the Purchase Order Number</li>
                <li>This Purchase Order is being sent to you in duplicate, please return the Duplicate Copy duly signed as token of acceptance within 7 Days. If not, this order shall be considered as accepted.</li>
                <li>Contact Person Name -</li>
                <li>Mobile No. -<br></li>
                <li>In the event, any dispute arises between the parties in relation to the quality and/ or quantity of the goods/ material supplied to ${applicationDetails?.applicationFullName} and/ or on behalf of ${applicationDetails?.applicationFullName}, then the decision of ${applicationDetails?.applicationFullName} in relation to the same, shall be final and binding upon the Vendor;</li>
                <li>The Vendor hereby unconditionally agrees and undertakes to indemnify and keep ${applicationDetails?.applicationFullName} indemnified from and against all costs, losses, claims, actions, penalties, demands, suits, damages, liability, etc. that may be suffered/ likely to be suffered, caused or incurred/ likely to be incurred by ${applicationDetails?.applicationFullName} on account of any claims/ grievances being raised by the end users/ consumers against ${applicationDetails?.applicationFullName} in relation to the quality and/ or quantity of the goods/ material supplied by the Vendor;</li>
                <li>Any/ all dispute(s) arising out of or in connection with this Purchase Order between the Vendor and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai;</li>
                <li>This Purchase Order shall be governed by the laws of India and the courts situated in Mumbai shall have the exclusive jurisdiction</li>
            </ol>
            </div>`
        },
        Services: {
            short: `<div>
            <div><strong>Rate</strong> - FOR at site</div>
            <div><strong>GST</strong> - 18% will be applicable for material</div>
            <div><strong>Advance</strong> - {{__advancePayment}}%</div>
            <div><strong>Net</strong> - {{__days}} days</div>
            <div><strong>Transportation</strong> - {{__transport}}</div>
            <div><strong>Loading</strong> - Included</div>
            <div><strong>Unloading</strong> - In Customer Scope</div>
            <div><strong>Delivery</strong> - Immediate / As per Site requirement</div>
            </div>`,
            longTerms: `<div>
            <ol>
                <li>TDS & other taxes as applicable will be deducted as per Govt Norms</li>
                <li>E waybill, Delivery Challan and Test Report Soft copies to be submitted along with the Tax Invoice (with mentioned referred PO Number), Its compulsory.</li>
                <li>Invoice to be submitted at Zonal Office Along with.</li>
                    <ol>
                        <li>FOR GOODS : a) Receipted Delivery Challan, b) LR / Weighment Slip wherever applicable, c) Material Test Certificate, d) Copy of Purchase Order and</li>
                        <li> FOR SERVICE : All relevant site receipted documents in original</li>
                    </ol>
                <li>All Documents including Challans and Invoices must bear the Purchase Order Number</li>
                <li>This Purchase Order is being sent to you in duplicate, please return the Duplicate Copy duly signed as token of acceptance within 7 Days. If not, this order shall be considered as accepted.</li>
                <li>Contact Person Name -</li>
                <li>Mobile No. -<br></li>
                <li>In the event, any dispute arises between the parties in relation to the quality and/ or quantity of the goods/ material supplied to ${applicationDetails?.applicationFullName} and/ or on behalf of ${applicationDetails?.applicationFullName}, then the decision of ${applicationDetails?.applicationFullName} in relation to the same, shall be final and binding upon the Vendor;</li>
                <li>The Vendor hereby unconditionally agrees and undertakes to indemnify and keep ${applicationDetails?.applicationFullName} indemnified from and against all costs, losses, claims, actions, penalties, demands, suits, damages, liability, etc. that may be suffered/ likely to be suffered, caused or incurred/ likely to be incurred by ${applicationDetails?.applicationFullName} on account of any claims/ grievances being raised by the end users/ consumers against ${applicationDetails?.applicationFullName} in relation to the quality and/ or quantity of the goods/ material supplied by the Vendor;</li>
                <li>Any/ all dispute(s) arising out of or in connection with this Purchase Order between the Vendor and ${applicationDetails?.applicationFullName} shall be referred to a Sole Arbitrator to be appointed mutually by the parties. The Arbitration proceedings shall be held in accordance with the provisions of Arbitration and Conciliation Act, 1996 as may be amended/ enacted from time to time. The seat and venue of the Arbitration shall be Mumbai;</li>
                <li>This Purchase Order shall be governed by the laws of India and the courts situated in Mumbai shall have the exclusive jurisdiction</li>
            </ol>
            </div>`
        }
    },
    quickQuoteTC: {
        short: `<div>
        *The rates mentioned above are tentative/approximate and are subject to change
        </div>`
    },
    duePeriods: [
        { value: 30, label: 'NET 30' },
        { value: 45, label: 'NET 45' },
        { value: 60, label: 'NET 60' },
        { value: 90, label: 'NET 90' }
    ],
    s3Path: {
        customerPO: 'customerQuotation/customerPO'
    },
    googleMap: {
        defaultLocation: {
            lat: 19.0759837,
            lng: 72.8776559
        },
        zoomLevel: {
            normal: 20,
            high: 25
        },
        radius: 4 /* in kilometers */
    },
    shortTncAllowedLines: 25,
    invalidTncMessage: 'Terms condition cannot be more than 25 lines',
    invalidCharacters: ['<', '>'],
    approvalHistoryEvents: {
        label: {
            customerQuotationInternallyRejected: 'Quotation internally rejected',
            sendQuotationForInternalApproval: 'Quotation sent for internal approval',
            rfqAndCustomerQuotationCreated: 'Rfq & Quotation created',
            customerQuotationApprovedByCustomer: 'Quotation approved by customer',
            sendQuotationToCustomer: 'Quotation sent to customer',
            customerQuotationInternallyApproved: 'Quotation internally approved',
            customerQuotationNegotiatedByCustomer: 'Quotation negotiated by customer',
            customerQuotationApprovedExecAdjustment: 'Customer Rate updated by executive'
        }
    },
    PAPER_CHALLAN_PREFERENCE: {
        ARIS: 'aris',
        VENDOR: 'vendor',
        NONE: 'none',
        BOTH: 'both'
    },

    PAPER_CHALLAN_PREFERENCE_PREFIX: {
        ARIS: 'A.Ch# ',
        VENDOR: 'V.Ch# ',
        NONE: 'E.Ch# ',
        BOTH: 'A.Ch# '
    },
    receivedQuantityTolerancePercentage: 10,
    fulfilmentQuantityTolerancePercentage: 100,
    fulfilmentQuantityTolerancePercentageForSOOnlyItem: 10
};
