import { Icon, Text } from '@/atoms';
import { CLOUD_PLATFORMS } from '@/helpers/constants';
import { getAuth, removeAuth } from '@/services/identity.service';
import { userLogOut } from '@/services/user.service';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

function ProfileDropdown({
    handleClickOutside, userName
}) {
    const router = useRouter();
    const [showPlatformSwitcher, setShowPlatformSwitcher] = useState(false);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, false);
        return () => {
            document.removeEventListener('click', handleClickOutside, false);
        };
    }, []);

    const togglePlatformSwitcher = (e) => {
        setShowPlatformSwitcher((prevState) => !prevState);
        e.stopPropagation();
    };

    const handleLogout = async () => {
        const auth = getAuth();
        if (auth && auth.token) {
            await userLogOut();
        }
        removeAuth();
        router.push('/login');
    };

    return (
        <div className='flex gap-1 fixed z-50 top-20 md:top-24 lg:top-20 right-1 mt-1'>
            {showPlatformSwitcher && (
                <div className="w-[283px] mt-12 bg-white border border-Black-10 rounded-lg p-3">
                    <div className="flex flex-col gap-2.5 justify-between">
                        {CLOUD_PLATFORMS.map((platform) => (
                            <a
                                key={platform.id}
                                href={platform.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <div className="flex w-full justify-between">
                                    <Text type='xs400' className='mr-5 whitespace-nowrap'>{platform.name}</Text>
                                    <Icon src={platform.logo} className={`h-4 ${platform.className}`} />
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            )}
            <div className='h-[121px] w-[176px] border rounded-lg bg-white p-3 mr-6 mt-[-8px]'>
                <Text type='xs500v3'>Signed in as:</Text>
                <Text type='xs500v4' className='mt-1 border-b border-Black-10 pb-1'>{userName}</Text>
                <div className='flex items-center justify-between mt-[13px] cursor-pointer' onClick={togglePlatformSwitcher}>
                    <div className='flex items-center gap-1'>
                        <Icon src='/images/appswitcher.svg' />
                        <Text type='xs400'>Organisations</Text>
                    </div>
                    <Icon src="/images/rightGo.svg" />
                </div>
                <div
                    className='flex items-center gap-1 mt-[9px] cursor-pointer'
                    onClick={handleLogout}
                >
                    <Icon src='/images/Logoutv2.svg' />
                    <Text type='xs400'>Logout</Text>
                </div>
            </div>
        </div>
    );
}

export default ProfileDropdown;
