import PropTypes from 'prop-types';
import Select from 'react-select';

const Dropdown = ({
    options,
    name,
    defaultValue,
    isDisabled,
    isLoading,
    isMulti,
    onChange,
    defaultValueIndex,
    className,
    labelClass,
    error,
    label,
    border,
    gap,
    height,
    ...properties
}) => {
    const colourStyles = {

        control: (base) => ({
            ...base,
            maxHeight: 48,
            minHeight: `${height || '48px'}`,
            overflowY: 'hidden',
            overflowX: 'hidden',
            border: `${border || '1px solid #DFE0EB'}`,
            fontSize: '14px ',
            fontWeight: '400',
            borderRadius: '8px'

        }),
        valueContainer: (base) => ({
            ...base,
            paddingRight: 0

        }),
        option: (styles, {
            isFocused
        }) => ({
            ...styles,
            backgroundColor: isFocused ? '#FEF8E2' : null,
            color: '#201F1F'
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            transition: 'all .2s ease',
            transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
            color: '#364054',
            opacity: '80%'
        }),
        menuPortal: (base) => ({ ...base, zIndex: 100 })
    };
    return (
        <div className={`w-full flex flex-col ${className} ${gap || ' gap-2'}`}>
            <label className='text-Gray-900 font-medium text-sm'>{label}{properties.isRequired && <span className="required">*</span>}</label>
            <Select
                instanceId="long-value-select"
                isMulti={isMulti}
                classNamePrefix="select"
                placeholder={`${properties.placeholder ? properties.placeholder : 'Search and select'
                    }`}
                defaultValue={defaultValue}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable
                isSearchable
                name={name}
                closeMenuOnSelect={!isMulti}
                options={options}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: '#fff'
                    }
                })}
                menuPlacement="auto"
                styles={colourStyles}
                menuPortalTarget={typeof window !== 'undefined' ? document.querySelector('body') : null}
                onChange={onChange}
                className={`${className}`}
                {...properties}
            />
            {error?.[name] && (
                <div className="text-error text-xs text-red-500">
                    {error[name].message}
                </div>
            )}
        </div>
    );
};
export default Dropdown;

Dropdown.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isClearable: PropTypes.bool,
    isSearchable: PropTypes.bool,
    isMulti: PropTypes.bool,
    onChange: PropTypes.func,
    defaultValueIndex: PropTypes.number
};

Dropdown.defaultProps = {
    isDisabled: false,
    isLoading: false,
    isClearable: false,
    isSearchable: false,
    isMulti: false
};
