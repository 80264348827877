import Icon from "./Icon"

const Searchv1 = ({ className, placeholder, onSearch, searchText, ...props }) => {
    return (
        <label className={`flex justify-center items-center gap-x-1.5  bg-Black-5 rounded-[20px] py-2  px-3.5 ${className}`}>
            <Icon src="/images/Searchv2.svg" />
            <input
                placeholder={placeholder || 'Search...'}
                className={`${placeholder ? 'text-sm' : ''} bg-Black-5 w-full outline-none text-Black-300 font-normal text-sm interFontfamily`}
                onChange={onSearch}
                {...props}
            />
        </label>
    )
}

export default Searchv1