import GlobalErrorPage from '@/components/Errors/GlobalErrorPage';
import Bugsnag, { ErrorBoundary } from '@/lib/bugsnag';
import { dumpLogsToAwsCloudWatch } from 'services/logger.service';

export default function ErrorHandlerWrapper({ children }) {
    function errorBoundaryHandler(error) {
        const body = {
            logMessage: 'BrowserError: Error occurred in the component',
            logType: 'error',
            logData: { errorStack: error.stack, message: error.message }
        };
        Bugsnag.notify(error);
        dumpLogsToAwsCloudWatch(body);
    }

    // commented Test Error for bugsnap
    // useEffect(() => {
    //     Bugsnag.notify(new Error('Test Ui Error'));
    // }, []);

    return (
        <ErrorBoundary
            FallbackComponent={GlobalErrorPage}
            onError={errorBoundaryHandler}
        >
            {children}
        </ErrorBoundary>
    );
}
