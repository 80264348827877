import React from 'react';

const SelectField = ({
    onSelect, placeholder, id, options, error
}) => (
    <>
        <select
            className="form-control w-[317px] h-10 outline-none rounded-lg cursor-pointer px-3 capitalize"
            id={id}
            defaultValue={'DEFAULT'}
            onClick={(e) => onSelect(e.target.value)}
        >
            <option value="DEFAULT" disabled >{placeholder}</option>
            {options.map((option, idx) => (
                <option key={idx} value={option.value}>{option.label}</option>
            ))}
        </select>
        {error && <div className="text-red-500">{error.message}</div>}
    </>
);
export default SelectField;
