import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const TextField = ({
    errors,
    register,
    name,
    label,
    type,
    getValues,
    inputboxClass,
    setlabelTop = false,
    disabled = false,
    maxlength = '500',
    prefix,
    suffix,
    ...inputProps
}) => {
    const [labelTop, setLabelTop] = useState(setlabelTop);
    const [focused, setFocued] = useState(false);
    useEffect(() => {
        if (getValues && getValues(name)?.length) {
            setLabelTop(true);
        }
    }, []);

    return (
        <div className={
            `relative w-full flex flex-col ${(inputboxClass === undefined || (inputboxClass && !inputboxClass.includes('gap-'))) ? 'gap-2' : ''
            } ${inputProps.readOnly ? 'opacity-50' : ''} ${inputboxClass ?? ''} ${inputProps.className ?? ''}`
        }>
            <label className={`text-Black-300 font-medium text-sm ${inputProps.labelClassName || ''}`}>{label}{inputProps.isRequired && <span className="required">*</span>}</label>
            {prefix && <div className='text-Gray-900 text-sm'>
                {prefix}
            </div>}
            <input
                {...(register && { ...register(name) })}
                type={type}
                maxLength={maxlength}
                {...inputProps}
                onFocus={() => {
                    setLabelTop(true);
                    setFocued(true);
                }}
                onBlur={(e) => {
                    e.target.value.length === 0 && setLabelTop(false);
                    setFocued(false);
                }}
                className={`${disabled ? 'disabled-input text-gray-400' : 'bg-white'} textField w-full p-3 text-Black-300 border rounded outline-none text-sm transition duration-150 ease-in-out font-normal h-12 border-solid focus:border-1 focus:border-primary-400  placeholder-iron-gray focus:outline-none placeholder-Black-60  border-gray-200 hover:border-gray-400 ${errors?.[name] ? 'focus:border-error' : ''} ${inputProps.inputPaddingClassName} ${inputProps.inputClassName} `}
                placeholder={inputProps.placeholder}
                disabled={disabled}
            />
            {suffix && <div className='text-Gray-900 text-sm'>
                {suffix}
            </div>}
            {errors?.[name] && (
                <div className="required">
                    {errors[name].message}
                </div>
            )}
        </div>
    );
};

export default TextField;
TextField.propTypes = {
    errors: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string
};
