// bugsnag.js
import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';

export function startBugsnagPerfornaceMonitoring() {
    BugsnagPerformance.start({
        apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
        appType: `${process.env.NEXT_PUBLIC_APPLICATION_NAME}-web`,
        releaseStage: process.env.NEXT_PUBLIC_APPLICATION_NAME
    });
}
Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    appType: `${process.env.NEXT_PUBLIC_APPLICATION_NAME}-web`,
    releaseStage: process.env.NEXT_PUBLIC_APPLICATION_NAME
});

export const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
export default Bugsnag;
