import { cloneDeep } from 'lodash';
import { getAllGstStateDetails } from '../services/common.service';
import { handleApiResponse, preciseTo } from '../services/helper-function.service';
import { track } from '../services/mixpanel.service';
import { getVendors } from '../services/vendor.service';
import {
    APPROVAL_ACTION_STATUSES, CQStatuses, OPEN_TEXT, POStatuses,
    isTextVendor,
    vendorPaymentRequestStatus
} from './constants';

const { Base64 } = require('base64-string');
const fmt = require('indian-number-format');

export const getEncodedUrlParams = (jsonData) => {
    const enc = new Base64();
    return enc.urlEncode(JSON.stringify(jsonData));
};

export const trackTableEvents = (pageName, mixpanelEvent) => {
    if (mixpanelEvent.type && pageName) {
        track(`${pageName}${mixpanelEvent.type}ed`, mixpanelEvent.data);
    }
};

export const googleStaticMapDimensions = (window) => {
    const windowWidth = window?.innerWidth ?? 768;
    if (windowWidth <= 767) {
        return `${windowWidth}x${windowWidth}`;
    } return '640x555';
};

export const deepClone = (data) => cloneDeep(data);

export const getEditedLocationJson = (data) => {
    let editedLocationJson = cloneDeep(data?.editedLocationJson ?? {});

    editedLocationJson = {
        ...editedLocationJson,
        ...((editedLocationJson?.state1 || editedLocationJson?.state) && { state: editedLocationJson?.state1 ?? data?.state }),
        ...((editedLocationJson?.pinCode1 || editedLocationJson?.pinCode) && { pinCode: editedLocationJson?.pinCode1 ?? editedLocationJson?.pinCode }),
        ...((editedLocationJson?.formattedAddress) && {
            fullAddress: editedLocationJson?.formattedAddress
        })
    };
    const keysToRemoveList = ['addressLine1', 'addressLine2', 'addressName', 'state1', 'pinCode1'];
    keysToRemoveList.forEach((key) => {
        if (editedLocationJson[key]) {
            delete editedLocationJson[key];
        }
    });

    return editedLocationJson;
};

export const getAllStateData = async (token) => {
    const res = await getAllGstStateDetails(token);
    const { details: gstStateData, apiError: gstStateApiError } = handleApiResponse(res);

    return { gstStateData, gstStateApiError };
};

export const getItemsDate = (data, isTypeOpenQuantity, flow) => (data ?? []).map((item) => {
    const text = `${OPEN_TEXT}${flow === 'VPO' ? ' - ' : ''}`;
    return {
        ...item,
        quantity: isTypeOpenQuantity ? text : preciseTo(item.quantity)
    };
});

export const getQuantity = ({ quantity, isTypeOpenQuantity }) => {
    let qty = quantity || 0;
    if (!isTypeOpenQuantity) {
        return qty;
    }
    if (!quantity) {
        qty = 1;
    }
    return qty;
};

export const isRestrictedToPerformPOAction = ({
    purchaseOrderStatus,
    isReallocated,
    internalStatus
}) => [POStatuses.DRAFT, POStatuses.CONFIRMED].includes(purchaseOrderStatus)
&& isReallocated
    && [CQStatuses.DRAFT_CONFIRMED, CQStatuses.DRAFT, CQStatuses.INTERNAL_PENDING].includes(internalStatus);

export const fetchVendors = async (offset, limit, search, categoryId, onlyActiveVendors, token) => {
    const res = await getVendors(offset, limit, search, categoryId, onlyActiveVendors, token);
    const { details: vendors, apiError } = handleApiResponse(res);
    return { vendors, apiError };
};

export const checkIfAllItemInVirtualPO = (requirementItems) => {
    let areAllItemsInVirtualPO = true;
    requirementItems.forEach((item) => {
        areAllItemsInVirtualPO = areAllItemsInVirtualPO && item.includeItemInVirtualPO;
    });

    return areAllItemsInVirtualPO;
};

export const areObjectsEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

export const isTextInconsistentData = (text) => text === CQStatuses.INCONSISTENT_DATA;

export const isNumeric = (str) => {
    if (typeof str === 'number') return true;
    if (typeof str !== 'string') return false;
    return !Number.isNaN(str) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        && !Number.isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
};

export const countDecimals = (value) => {
    if (Math.floor(value) !== value) { return value.toString().split('.')?.[1]?.length || 0; }
    return 0;
};

export const getQueryString = (filterValues) => {
    const queryString = Object.keys(filterValues).map((key) => `${key}=${filterValues[key]}`).join('&');

    return queryString;
};

export const getFilterValues = (queryParams) => {
    const filterValues = { ...queryParams };
    if ('search' in filterValues) {
        delete filterValues.search;
    }
    if ('offset' in filterValues) {
        delete filterValues.offset;
    }
    if ('limit' in filterValues) {
        delete filterValues.limit;
    }
    return filterValues;
};

export const compareObjects = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if number of keys is the same
    if (keys1.length !== keys2.length) {
        return false;
    }

    // Check if all keys and their values are the same
    return keys1.every((key) => obj1[key] === obj2[key]);
};

export const formatNumbers = (value = 0, decimalPoint = 1, options = {}) => {
    const { noSpace = false } = options;
    const sanitizedValue = String(value).replace(/,/g, '');
    const valToNumber = Number(sanitizedValue);
    const fmtFormat = (x, d = 1) => fmt.formatFixed((Number(x) / d), decimalPoint);

    // Regular formatting without minimum length consideration
    if (Math.abs(valToNumber) >= 10000000) {
        // Convert to Crores
        return `₹${fmtFormat(valToNumber, 10000000)}${noSpace ? '' : ' '}Cr`;
    }
    if (Math.abs(valToNumber) >= 100000) {
        // Convert to Lakhs
        return `₹${fmtFormat(valToNumber, 100000)}${noSpace ? '' : ' '}L`;
    }
    if (Math.abs(valToNumber) >= 1000) {
        // Convert to Thousands
        return `₹${fmtFormat(valToNumber, 1000)}${noSpace ? '' : ' '}K`;
    }
    // Return as is if below 1000
    return `₹${valToNumber.toFixed(2)}`;
};

export const formatNumbersWithMinLength = (value = 0, minimumLength) => {
    const sanitizedValue = String(value).replace(/,/g, '');

    const valToNumber = Number(sanitizedValue);

    const fmtFormat = (x, d = 1) => fmt.formatFixed((Number(x) / d), 2);
    if (minimumLength) {
        if (valToNumber < 1000) {
            return `₹${valToNumber.toFixed(2)}`;
        }
        if (minimumLength >= 8) {
            return `₹${fmtFormat(valToNumber, 10000000)} Cr`;
        }
        if (minimumLength >= 6) {
            return `₹${fmtFormat(valToNumber, 100000)} L`;
        }
        if (minimumLength >= 4) {
            return `₹${fmtFormat(valToNumber, 1000)} K`;
        }
    }
};

export const convertToBarData = (data) => {
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',
        'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const currentDate = new Date();
    const result = [];
    const colors = ['bg-Black-40', 'bg-Black-20', 'bg-Black-10'];

    for (let i = 2; i >= 0; i--) {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // ensures two-digit month
        const yearMonth = `${date.getFullYear()}-${month}`;
        const found = data.find((item) => item.month === yearMonth);

        const yearShort = date.getFullYear().toString().slice(-2);
        const monthYear = `${months[date.getMonth()]}'${yearShort}`;

        result.push({
            name: monthYear,
            uv: found ? found.totaltransactionamount : 0,
            bgColor: colors[i]
        });
    }

    return result;
};

export const determineBuHeadStatus = (approvals = []) => {
    let currentLevel = 1;

    approvals.forEach((approval) => {
        if (approval.status === APPROVAL_ACTION_STATUSES.APPROVED) {
            currentLevel = Math.max(currentLevel + 1, approval.level);
        }
    });

    // Generate variant information based on approval status
    return approvals.map((approval) => {
        let variant;

        if (approval.status === APPROVAL_ACTION_STATUSES.REJECTED || approval.status === APPROVAL_ACTION_STATUSES.EXPIRED) {
            variant = 'Error';
        } else if (approval.level === currentLevel) {
            variant = 'Intermediate';
        } else if (approval.level < currentLevel) {
            variant = 'Complete';
        } else {
            variant = 'Newv1';
        }

        return {
            approvalId: approval.id,
            name: approval.approver.fullName,
            variant
        };
    });
};

export const determineVendorApprovalStatus = (approvals = []) => {
    let currentApprovedLevel = 1;

    approvals.forEach((approval) => {
        if (approval.status === APPROVAL_ACTION_STATUSES.APPROVED) {
            currentApprovedLevel = Math.max(currentApprovedLevel + 1, approval.level);
        }
    });
    const vendorApprovers = approvals.filter((approverAction) => isTextVendor(approverAction.type));

    return vendorApprovers.map((approval) => {
        let variant;
        if (approval.status === APPROVAL_ACTION_STATUSES.REJECTED || approval.status === APPROVAL_ACTION_STATUSES.EXPIRED) {
            variant = 'Error';
        } else if (approval.level === currentApprovedLevel) {
            variant = 'Intermediate';
        } else if (approval.status === APPROVAL_ACTION_STATUSES.APPROVED) {
            variant = 'Complete';
        } else {
            variant = 'Newv1';
        }

        return {
            approvalId: approval.id,
            name: approval.approver.fullName,
            variant
        };
    });
};
export const getPaymentRequestDetails = (paymentRequests = []) => {
    let approvedAmount = 0;
    let pendingAmount = 0;
    let totalPaymentRequestsAmount = 0;
    let noOfApproved = 0;
    let noOfPending = 0;
    const noOftotalPaymentRequests = paymentRequests.length;

    paymentRequests.forEach((request) => {
        totalPaymentRequestsAmount += request.paymentAmount;

        if (
            vendorPaymentRequestStatus[request.paymentRequestStatus] === vendorPaymentRequestStatus.bu_approved
                || vendorPaymentRequestStatus[request.paymentRequestStatus] === vendorPaymentRequestStatus.sent_final_approval
        ) {
            approvedAmount += request.paymentAmount;
            noOfApproved += 1;
        } else if (vendorPaymentRequestStatus[request.paymentRequestStatus] === vendorPaymentRequestStatus.waiting_bu_approval) {
            pendingAmount += request.paymentAmount;
            noOfPending += 1;
        }
    });

    return {
        approvedAmount: approvedAmount.toFixed(2),
        noOfApproved,
        noOfPending,
        noOftotalPaymentRequests,
        pendingAmount: pendingAmount.toFixed(2),
        totalPaymentRequestsAmount: totalPaymentRequestsAmount.toFixed(2)
    };
};

export const getStartAndEndDates15Days = () => {
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    startDate.setDate(startDate.getDate() - 15);
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);
    return {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
    };
};

export const getTruncatedBranchName = (branchName) => {
    const truncatedName = branchName.length > 20 ? `${branchName.slice(0, 20)}...` : branchName;
    return `Br: ${truncatedName}`;
};

export const hasHTMLTags = (str) => {
    const regex = /<\/?[a-z][\s\S]*>/i;
    return regex.test(str);
};
