import { getWithAuth, getWithAuthWithToken, getWithOutAuth } from './http.service';
import {
    GET_ALL_GST_STATE_DETAILS,
    GET_GST_DETAILS, GET_GST_DETAILS_NO_AUTH, GET_IFSC_DETAILS, GET_POSTAL_DETAILS
} from './url.service';

export const getGstDetails = (gstIn) => getWithAuthWithToken(GET_GST_DETAILS(gstIn));
export const getGstDetailsWithOutAuth = (gstIn, token) => getWithOutAuth(GET_GST_DETAILS_NO_AUTH(gstIn), token);
export const getIfscDetails = (ifsc) => getWithAuthWithToken(GET_IFSC_DETAILS(ifsc));
export const getPostalDetails = (pincode) => getWithAuthWithToken(GET_POSTAL_DETAILS(pincode));
export const getAllGstStateDetails = (token) => getWithAuth(GET_ALL_GST_STATE_DETAILS, token);
