import moment from "moment"
import { Text } from "../atoms"
import ShowAndHideData from "./ShowAndHideData"

const ItemsAndNamesSection = ({ showRequirementDetails, className }) => {
    return (
        <div className={className}>
            <div className="flex justify-between items-center">
                <Text type='base600'>
                    {showRequirementDetails.projectCustomer.customer.displayName}
                </Text>
                <Text type='xs500' className='!text-Gray-503'>
                    Created on: {moment(showRequirementDetails.createdAt.split('T')[0]).format('DD/MM/YYYY')}
                </Text>
            </div>
            <div className="flex justify-between items-center mt-0.5">
                <Text type='sm500'>
                    {showRequirementDetails.projectCustomer.project.projectName}
                </Text>
                <Text type='xs500' className='!text-Gray-503'>
                    Delivery by: {moment(showRequirementDetails.estimatedDate).format('DD/MM/YYYY')}
                </Text>
            </div>
            {showRequirementDetails.
                requirementItem.length ?
                <ShowAndHideData
                    className='mt-4'
                    data={
                        showRequirementDetails.
                            requirementItem.
                            map((ri) => ({
                                name: `${ri.item.name || ''} ${ri.item.grade || ''} ${ri.item.custom || ''}`
                            }))
                    }
                /> : null
            }
            {showRequirementDetails
                .purchaseOrder.length ?
                <ShowAndHideData
                    className='mt-2.5'
                    type="vendor"
                    data={
                        showRequirementDetails
                            .purchaseOrder.
                            map((po) => ({ name: po.vendor.displayName }))
                    }
                /> : null
            }

        </div>
    )
}

export default ItemsAndNamesSection