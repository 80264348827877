/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
import mixpanel from 'mixpanel-browser';

const sensitiveDataKey = [
    'password'
];

const removeSensitiveData = (properties = {}) => {
    const newProperties = { ...properties };
    for (const key in newProperties) {
        if (sensitiveDataKey.includes(key.toLowerCase())) {
            delete newProperties[key];
        }
    }
    return newProperties;
};

const init = () => {
    let distinct_id = '';
    let user_id = '';
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_ID || '', {
        debug: true,
        ignore_dnt: true,
        loaded: (mixPanel = mixpanel) => {
            distinct_id = mixPanel.get_distinct_id();
            user_id = mixPanel.get_property('user_id');
        }
    });
};

// initialize mixpanel
init();

const defaultProperties = (properties = {}) => {
    let defaultPropertiesData = { ...properties };
    if (properties?.picture) {
        properties.$avatar = properties.picture;
    }
    if (properties?.fullName) {
        properties.$name = properties.fullName;
    }
    if (properties.email) {
        properties.$email = properties.email;
    }
    defaultPropertiesData = { ...properties };
    return defaultPropertiesData;
};

const track = (eventName, data = {}) => {
    mixpanel.track(eventName, data);
};

const peopleSet = (properties = {}) => {
    const newProperties = ({ ...properties, ...defaultProperties(properties) });
    mixpanel.people.set(removeSensitiveData(newProperties));
};

const register = (properties = {}) => {
    const newProperties = ({ ...properties, ...defaultProperties(properties) });
    mixpanel.register(removeSensitiveData(newProperties));
};

const peopleSetOnce = (properties = {}) => {
    mixpanel.people.set_once(removeSensitiveData(properties));
};

const peopleIncrement = (properties = {}) => {
    mixpanel.people.increment(removeSensitiveData(properties));
};

const peopleAppend = (properties = {}) => {
    mixpanel.people.append(removeSensitiveData(properties));
};

const peopleUnion = (properties = {}) => {
    mixpanel.people.union(removeSensitiveData(properties));
};

const peopleUnset = (properties = {}) => {
    mixpanel.people.unset(removeSensitiveData(properties));
};

const identify = (distinct_id = '', properties = {}) => {
    register(properties);
    peopleSet(properties);
    mixpanel.identify(distinct_id);
    mixpanel.people.set(removeSensitiveData(properties));
};

export {
    track,
    register,
    peopleUnset,
    peopleUnion,
    peopleAppend,
    peopleIncrement,
    peopleSetOnce,
    peopleSet,
    identify
};
