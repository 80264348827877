import { VQStatuses } from '@/helpers/constants';
import * as R from 'ramda';
import { v4 as uuidV4 } from 'uuid';

const moment = require('moment');

export function dateToDMMMYYYY(date) {
    return moment(date).format('DD MMM, YYYY', 'h:mm:ss a');
}
export function currentTime(date) {
    return moment(date).format('h:mm:ss a');
}

export function dateToll(date) {
    return moment(date).format('ll');
}

export function dateToDDMMYYYY(date) {
    return moment(date).format('DD-MMM-YYYY', 'h:mm:ss a');
}

export function dateToDDMMYYYYWithSlash(date) {
    return moment(date).format('DD/MM/YYYY', 'h:mm:ss a');
}

export function dateWithSlashTime(date) {
    return moment(date).format('DD/MM/YY, h:mm a');
}

export function dateToDMMMYYYYWithTime(date) {
    return moment(date).format('DD MMM, YYYY h:mm:ss a');
}

export function dateToDMMMWithTime(date) {
    return moment(date).format('DD MMM, h:mm a');
}

export function offerValidDate(date, categories) {
    if (categories.toLowerCase().includes('steel')) {
        return moment(date).format('DD-MM-YYYY', 'h:mm:ss a');
    }
    date.setDate(date.getDate() + 2);
    return moment(date).format('DD-MM-YYYY', 'h:mm:ss a');
}

export function joinArrayToString(arrayObj, property) {
    const categoriesArray = arrayObj.map((ele) => ele[property]);
    return categoriesArray.join(', ');
}

export function formatOptions(options) {
    if (!Array.isArray(options)) {
        return [];
    }
    const sortedOptions = options.sort((a, b) => a.label?.localeCompare(b.label));
    const uniqueOptions = sortedOptions.filter((value, index) => {
        const _value = JSON.stringify(value);
        return index === sortedOptions.findIndex((obj) => JSON.stringify(obj) === _value);
    });
    if (Array.isArray(uniqueOptions)) {
        return uniqueOptions;
    }
    return [];
}

export function getFileNameFromUrl(url) {
    if (!url) return null;
    const urlArray = url.split('/');
    const urlItem = urlArray[urlArray.length - 1].split('-');
    urlItem.splice(0, 5);
    return urlItem.join('-');
}

export function createFileName(fileName) {
    const fileId = uuidV4();
    return (`${fileId}-${fileName}`);
}

export function getTabName(step) {
    switch (step) {
    case 1:
        return 'Organization Details';
    case 2:
        return 'Statutory Details';
    case 3:
        return 'Contact Details';
    default:
        return '';
    }
}

export function handleApiResponse(res, type = 'object', defaultErrMsg = 'Something went wrong!') {
    let details = {};
    let apiError = [];
    let successMessage = null;

    if (R.pathOr(false, ['data', 'status'], res)) {
        const defaultRes = type === 'object' ? {} : [];
        details = R.pathOr(defaultRes, ['data', 'entity'], res);
        successMessage = R.pathOr(null, ['data', 'message'], res);
    } else {
        if (typeof res?.error === 'string' || Array.isArray(res?.error)) {
            apiError = res?.error;
        } else if (typeof res?.error === 'object') {
            apiError = res?.error?.message ?? res?.errorMessage;
        } else {
            apiError = res?.errorMessage;
        }

        if (!apiError) {
            apiError = defaultErrMsg;
        }

        if (typeof (apiError) === 'string') {
            apiError = [apiError];
        }
    }

    return { details, apiError, successMessage };
}

export function getMappedUomList(uoms) {
    return uoms.map((uom) => ({
        label: uom,
        value: uom
    }));
}

export function getMappedGradesList(grades) {
    let hasNullGrade = false;
    let gradesList = [];
    grades.forEach((itr) => {
        if (!itr.grade) {
            hasNullGrade = true;
        }

        if (itr.grade) {
            gradesList.push({
                label: itr.grade,
                value: itr.grade
            });
        }
    });
    if (hasNullGrade) {
        gradesList = [{ label: 'No Grade', value: null }, ...gradesList];
    }
    return gradesList;
}
// please update this function in API as well
export function generatePoId(rfqId, vendorNumber) {
    return (`P${vendorNumber}${rfqId.slice(1,)}`);
}

export function getGstPercentage(items) {
    const singleGst = items[0]?.gstPercentage;
    let hasSingleGst = true;
    items?.forEach((ele) => {
        if (ele.gstPercentage !== singleGst) {
            hasSingleGst = false;
        }
    });
    return (hasSingleGst ? singleGst : null);
}
export const getCategoriesFromReqItems = (requirementItems = []) => {
    const itemNames = requirementItems.map((requirementItem) => requirementItem?.item?.category?.name);
    const uniqueItems = [...new Set(itemNames)];
    return (uniqueItems.join(', '));
};

export const extractDataFromGoogleRes = (res, errorMessage) => {
    const finalData = {};
    let googleJson = {};
    if ((res?.results || []).length > 0) {
        const data = res?.results[0];
        googleJson = JSON.parse(JSON.stringify(res?.results[0]));
        data.address_components.forEach((address) => {
            if (address.types.includes('sublocality_level_5')) {
                finalData.subLocalityL5 = address.long_name;
            } else if (address.types.includes('sublocality_level_4')) {
                finalData.subLocalityL4 = address.long_name;
            } else if (address.types.includes('sublocality_level_3')) {
                finalData.subLocalityL3 = address.long_name;
            } if (address.types.includes('sublocality_level_2')) {
                finalData.subLocalityL2 = address.long_name;
            } if (address.types.includes('sublocality_level_1')) {
                finalData.subLocalityL1 = address.long_name;
            } else if (address.types.includes('locality')) {
                finalData.locality = address.long_name;
            } else if (address.types.includes('sublocality')) {
                finalData.sublocality = address.long_name;
            } else if (address.types.includes('administrative_area_level_5')) {
                finalData.adminArea5 = address.long_name;
            } else if (address.types.includes('administrative_area_level_4')) {
                finalData.adminArea4 = address.long_name;
            } else if (address.types.includes('administrative_area_level_3')) {
                finalData.adminArea3 = address.long_name;
            } else if (address.types.includes('administrative_area_level_2')) {
                finalData.adminArea2 = address.long_name;
            } else if (address.types.includes('administrative_area_level_1')) {
                finalData.state = address.long_name;
                finalData.adminArea1 = address.long_name;
            } else if (address.types.includes('postal_code')) {
                finalData.pinCode = address.long_name;
            } else if (address.types.includes('plus_code')) {
                finalData.plusCode = address.long_name;
            }
        });
        finalData.city = finalData.adminArea2 || finalData.adminArea3;
        finalData.formattedAddress = data.formatted_address;
        finalData.placeId = data.place_id;
        finalData.types = data.types;
        finalData.matchType = data.geometry.location_type;
        if (typeof data?.geometry?.location?.lat === 'function') {
            finalData.location = { lat: data.geometry.location.lat(), lng: data.geometry.location.lng() };
            finalData.bounds = data.geometry.bounds;
            finalData.viewport = data.geometry.viewport;
            googleJson.geometry.location = { lat: data.geometry.location.lat(), lng: data.geometry.location.lng() };
        } else {
            finalData.location = data.geometry.location;
            finalData.bounds = data.geometry.bounds;
            finalData.viewport = data.geometry.viewport;
        }
        return ({ error: null, data: finalData, googleJson });
    }
    return ({ error: errorMessage, data: {}, googleJson });
};

function deg2rad(deg) {
    return deg * (Math.PI / 180);
}

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
        + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2))
        * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
};

const getDeliveryAddress = (address) => {
    const {
        subLocalityL1, subLocalityL2, subLocalityL3, locality, state
    } = address;
    let finalDeliveryAddress = '';
    const subLocality = subLocalityL1 || subLocalityL2 || subLocalityL3;
    if (subLocality) {
        finalDeliveryAddress = `${subLocality}, `;
    }

    if (locality) {
        finalDeliveryAddress = `${finalDeliveryAddress}${locality}`;
    }
    if ((!subLocality || !locality) && state) {
        if (locality) {
            finalDeliveryAddress = `${finalDeliveryAddress}, ${state}`;
        } else {
            finalDeliveryAddress = `${finalDeliveryAddress}${state}`;
        }
    }
    return finalDeliveryAddress;
};
// change in combination of deliveryAddress will affect output of getDeliveryAddress function.
export const getStructuredAddresses = (address) => {
    let finalAddress;

    if (address?.locationJson) {
        const {
            city, state, pinCode, locality, formattedAddress, subLocalityL1, subLocalityL2, subLocalityL3
        } = address.locationJson;

        // change in combination of deliveryAddress will affect output of getDeliveryAddress function.
        finalAddress = {
            deliveryAddress: address?.displayDeliverySiteAddress ?? getDeliveryAddress({
                locality, state, subLocalityL1, subLocalityL2, subLocalityL3
            }),
            city,
            state,
            pinCode,
            formattedAddress,
            locality,
            addressLine1: subLocalityL2 || subLocalityL3,
            addressLine2: subLocalityL1 || locality
        };
    } else {
        finalAddress = {
            addressLine1: address?.addressLine1,
            addressLine2: address?.addressLine2,
            city: address?.city,
            state: address?.state,
            country: address?.country,
            pinCode: address?.pinCode,
            deliveryAddress: address?.displayDeliverySiteAddress
            ?? `${address?.addressLine2
                || address?.addressLine1}
                ${((address?.addressLine2 || address?.addressLine1) && address?.pinCode) ? ',' : ''} 
                ${address?.pinCode},
                ${address?.country}`
        };
    }
    if (address?.fullAddress?.trim()) {
        finalAddress.showFullAddressOnly = true;
    }
    finalAddress.fullAddress = address?.fullAddress;
    return finalAddress;
};

export function isWebView(userAgent) {
    if (userAgent) {
        return userAgent.includes('android-arisinfra') || userAgent.includes('ios-arisinfra');
    } if (typeof window !== 'undefined' && window.navigator && window.navigator.userAgent) {
        const { userAgent } = window.navigator;
        return userAgent.includes('android-arisinfra') || userAgent.includes('ios-arisinfra');
    }
    return false;
}

export const getCustomAddress = (address, keys) => {
    const finalAddress = { ...getStructuredAddresses(address) };
    const customAddress = [];
    if (keys.length > 0) {
        if (keys.length > 1) {
            keys.forEach((key) => {
                finalAddress[key] && customAddress.push(finalAddress[key]);
            });
        } else {
            customAddress.push(finalAddress[keys[0]] || '');
        }
    }
    return (customAddress.join(', '));
};

export const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
};

export function isUpcomingDateTime(date) {
    return moment(date).isValid() && moment().diff(date, 'seconds') < 0;
}

export function getItemNames(requirementItems = []) {
    const itemNames = requirementItems.map((requirementItem) => R.pathOr('', ['item', 'category', 'name'], requirementItem));
    const uniqueItems = [...new Set(itemNames)];
    return (uniqueItems.join(', '));
}

export function getPlaceOfSupplyString({ stateName, gstStateCode }) {
    return `${stateName} (${gstStateCode})`;
}

export function preciseTo(number, decimalPlaces = 2, defaultValue = 0) {
    if (number === null
        || decimalPlaces === null
        || Number.isNaN(number)
        || Number.isNaN(decimalPlaces)
        || decimalPlaces < 0) {
        return defaultValue;
    }
    return parseFloat(Number(number).toFixed(decimalPlaces));
}

export function getPaperChallanNumber(paperChallanNo, vendorPaperChallanNo) {
    if (paperChallanNo) {
        return paperChallanNo;
    }
    return (vendorPaperChallanNo ? `VCh# ${vendorPaperChallanNo}` : 'NA');
}

export function getStatus(pauseStatuses) {
    const allPaused = pauseStatuses.every((status) => status);
    const anyPaused = pauseStatuses.some((status) => status);

    if (allPaused) {
        return 'Paused';
    } if (anyPaused) {
        return 'Partial Paused';
    }
    return false;
}
export function filterIncludedInRfqVendorQuotations(requirement) {
    const requirementItem = requirement.requirementItem.map((reqItem) => ({
        ...reqItem,
        vendorQuotations: reqItem.vendorQuotations.filter(
            (quotation) => !(quotation.includedInRfq === false
                && quotation.status === VQStatuses.RATE_UNAVAILABLE)
        )
    }));
    return ({
        ...requirement,
        requirementItem
    });
}
export const isUUID = (str) => {
    const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidPattern.test(str);
};

export const getFormattedPrice = (price) => Number(price)?.toLocaleString('en-IN', {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'INR'
});

export const getMarginAndPercentage = ({ purchase, sales }) => {
    const absPurchase = purchase ? (purchase / 100) : 0;
    const absSales = sales ? (sales / 100) : 0;
    const margin = absSales - absPurchase;
    const percentage = ((margin) / absSales) * 100;

    // if !sales => PO only item
    // if !purchase => SO only item
    let percentageValue = null;
    if (!sales) percentageValue = 'Net Loss';
    else if (!purchase) percentageValue = 'Net Profit';
    else percentageValue = `${percentage.toFixed(2)}%`;

    return {
        margin,
        percentage: percentageValue
    };
};

export const getUomsOrMisc = (input) => input && (input.includes(',') ? 'misc' : input);

export const isNetLoss = (value) => value === 'Net Loss';

export const isNegative = (value) => {
    value = value.replace('%', '');
    value = parseFloat(value);
    return value < 0;
};

export function capitalizeFirstLetter(name) {
    const capitalized = name?.charAt(0).toUpperCase() + name?.slice(1);

    return capitalized;
}

export const getOpenQuantityRateMargin = ({ purchaseOrderItems = [], requirementItems = [] }) => {
    const itemMargins = [];
    let marginRange = '';
    let marginAbsValue = 0;
    let hasRangeMargin = true;

    purchaseOrderItems?.forEach((poItem) => {
        const reqItem = requirementItems?.find((item) => item.id === poItem?.requirementItemId);
        if (reqItem) {
            const margin = ((Number(reqItem.rate) - Number(poItem.unitPrice)) / Number(reqItem.rate)) * 100;
            marginAbsValue += (Number(reqItem.rate) - Number(poItem.unitPrice));
            itemMargins.push(margin);
        }
    });

    const highestMargin = Math.max(...itemMargins);
    const lowestMargin = Math.min(...itemMargins);
    marginRange = `${lowestMargin.toFixed(2)}-${highestMargin.toFixed(2)}%`;
    if (itemMargins.length === 1) {
        marginRange = `${itemMargins[0].toFixed(2)}%`;
        hasRangeMargin = false;
    }
    return { marginRange, marginAbsValue, hasRangeMargin };
};

export const getDealIdLabelName = (deal) => {
    const latestCustomer = deal?.requirements[0]?.customer?.displayName;
    const customers = deal?.requirements?.map((requirement) => requirement?.customer?.displayName);
    const uniqueCustomers = Array.from(new Set(customers));
    const moreCustomers = Number(uniqueCustomers?.length) - 1;
    return `${deal?.dealNumber} - ${latestCustomer} ${moreCustomers > 0 ? `... + ${moreCustomers} more` : ''}`;
};

export const getCustomersName = (deal) => {
    const customers = deal?.requirements?.map((requirement) => requirement?.customer?.displayName);

    if (R.isNil(customers) || R.isEmpty(customers)) return '';

    const uniqueCustomers = Array.from(new Set(customers));

    return `( ${uniqueCustomers?.join(', ')} )`;
};

export const replaceTncPlaceholder = (str, value) => str.replace('$$value$$', value);
