import PropTypes from 'prop-types';

const classes = {
    Primary: 'border border-orange-500 bg-orange-100 text-orange-500 rounded-3xl',
    Primaryv1: 'border-b border-[#FFC117] bg-primary-20 text-Black-300 font-medium text-[10px] leading-[15px] px-3 py-[3px] rounded-[30px] interFontfamily',
    Primaryb: 'bg-[#FFEFE4] text-orange-500 rounded',
    Secondary: 'border border-green-600 bg-green-100 text-green-600 rounded-3xl',
    Secondaryv1: 'border-b border-[#79DA91] bg-[#EBF9EE] text-Black-300 font-medium text-[10px] leading-[15px] px-3 py-[3px] rounded-[30px] interFontfamily',
    Secondaryb: 'bg-[#EAF3EB] text-green-600 rounded',
    Inactive: 'border border-[#676D8D] bg-[#F3F3F9] text-[#676D8D] rounded-3xl',
    Tertiary: 'border border-[#00AADF] bg-[#E8F4FA] text-[#00AADF]  rounded-3xl',
    Tertiaryb: ' bg-[#E8F4FA] text-[#00AADF] rounded',
    Quaternary: 'border border-[#FB4545] bg-[#FFF3F3] text-[#FB4545] rounded-3xl',
    Quaternaryb: 'bg-[#FFF3F3] text-[#FB4545] rounded',
    red: 'inline-block px-2 py-1 text-xs leading-5 font-normal border border-Red-100 text-Red-100 rounded-[3px]',
    redv1: 'border-b border-[#F78468] bg-[#FDE0D9] text-Black-300 font-medium text-[10px] leading-[15px] px-3 py-[3px] rounded-[30px] interFontfamily',
    yellow: 'border border-primary-502 border-opacity-40 bg-[#FFF3D1] text-Black-300 rounded-3xl text-[10px] font-normal leading-3',
    green: 'border border-[#34C759] border-opacity-50 bg-[#EBF9EE] text-Black-300 rounded-3xl text-[10px] font-normal leading-3 capitalize',
    ongoing: 'border border-primary-502 bg-primary-502 bg-opacity-20 text-Black-300 text-opacity-50  rounded-3xl',
    Inactivev1: 'bg-Black-10 bg-opacity-70 text-Black-80 rounded-[30px] px-2.5 font-medium text-xs leading-[18px] interFontfamily',
    active: 'bg-primary-20  text-Black-300 rounded-[30px] px-2.5 font-medium text-xs leading-[18px] interFontfamily',
    yellow: 'border border-[#FBC31D] border-opacity-40 bg-[#FBC31D] bg-opacity-20 text-[#FCA40E] rounded-3xl',
    green: 'border border-[#34C759] border-opacity-50 bg-[#EBF9EE] text-Black-300 rounded-3xl text-[10px] font-normal leading-3 capitalize',
    ongoing: 'border border-primary-502 bg-primary-502 bg-opacity-20 text-Black-300 text-opacity-50  rounded-3xl',
    Filed: 'border border-green-400 border-opacity-50 bg-green-10 leading-3  text-Black-300 text-[10px] font-normal rounded-3xl px-2',
    Filedlg: 'border border-green-400 border-opacity-50 bg-green-10 leading-[18px] text-Black-300 text-xs font-medium rounded-[30px] px-3',
    New: 'border border-Black-300 border-opacity-50 bg-Black-10 leading-3 text-Black-300 text-[10px] font-normal rounded-[15px] px-2',
    Newlg: 'border border-Black-300 border-opacity-50 bg-Black-10 leading-[18px] text-Black-300 text-xs font-medium rounded-[30px] px-3',
    PartiallyFiled: 'border border-primary-502 border-opacity-50 bg-primary-20 leading-3 text-Black-300 text-[10px] font-normal rounded-[15px] px-2',
    PartiallyFiledlg: 'border border-primary-502 border-opacity-50 bg-primary-20 leading-[18px] text-Black-300 text-xs font-medium rounded-[30px] px-3',
    Discarded: 'border border-red-300 border-opacity-50 bg-red-10 leading-3 text-Black-300 text-[10px] font-normal rounded-[15px] px-2',
    Bita: 'bg-primary-502 leading-[15px] text-Black-300 text-[10px] font-semibold rounded-[30px] px-2 interFontfamily',
    alert: 'bg-[#F5633F] bg-opacity-20 rounded-[22px] font-normal text-xs text-Black-300 px-2 leading-[15px] interFontfamily',
    Newv1: 'bg-Black-10 border-b border-Black-80 border-opacity-80 rounded-[30px] py-0.5 px-3 leading-[18px] text-Black-300 text-xs font-medium interFontfamily whitespace-nowrap',
    Newv1Xs: 'bg-Black-10 border-b border-Black-80 border-opacity-80 rounded-[30px] py-0.5 px-2 leading-3 text-Black-300 text-[10px] font-medium interFontfamily whitespace-nowrap',
    Error: 'bg-red-10 border-b border-red-300 border-opacity-80 rounded-[30px] py-0.5 px-3 leading-[18px] text-Black-300 text-xs font-medium interFontfamily whitespace-nowrap',
    ErrorXs: 'bg-red-10 border-b border-red-300 border-opacity-80 rounded-[30px] py-0.5 px-2 leading-3 text-[10px] text-Black-300 text-xs font-medium interFontfamily whitespace-nowrap',
    Intermediate: 'bg-primary-20 border-b border-primary-502 border-opacity-80 rounded-[30px] py-0.5 px-3 leading-[18px] text-Black-300 text-xs font-medium interFontfamily whitespace-nowrap',
    IntermediateXs: 'bg-primary-20 border-b border-primary-502 border-opacity-80 rounded-[30px] py-0.5 px-2 leading-3 text-[10px] text-Black-300 text-xs font-medium interFontfamily whitespace-nowrap',
    Complete: 'bg-[#E4F5F2] border-b border-[#49BEAA] border-opacity-80 rounded-[30px] py-0.5 px-3 leading-[18px] text-Black-300 text-xs font-medium interFontfamily whitespace-nowrap',
    pill: ' border border-Black-20 rounded-[30px] py-1 px-2 leading-[18px] text-Black-80 text-xs font-normal interFontfamily whitespace-nowrap',
    pillActive: 'bg-primary-10 border border-primary-502 rounded-[30px] py-1 px-2 leading-[18px] text-Black-80 text-xs font-normal interFontfamily whitespace-nowrap',
    CompleteXs: 'bg-green-10 border-b border-green-400 border-opacity-80 rounded-[30px] py-0.5 px-2 leading-3 text-xs text-Black-300 text-[10px] font-medium interFontfamily whitespace-nowrap'
};

const Status = ({
    className, variant, children, onClick, ...props
}) => (
    <div className={`inline-block items-center py-1 px-2 text-xs font-normal ${className} button ${classes[variant] || classes.Primary}`} onClick={onClick} {...props}>
        {children}
    </div>
);

export default Status;

Status.defaultProps = {
    className: '',
    variant: 'Primary'

};

Status.propTypes = {
    className: PropTypes.string,
    /**
     * Which variant should the status be?
     */
    variant: PropTypes.oneOf(['Primary', 'Primaryb', 'Secondary', 'Secondaryb', 'Inactive', ' Tertiary', 'Tertiaryb', 'Quaternary', 'Quaternaryb', 'red', 'yellow', 'ongoing', 'Filed', 'New', 'PartiallyFiled', 'Discarded', 'Bita', 'Primaryv1', 'Secondaryv1', 'redv1', 'Inactivev1', 'active', 'alert']),
    /**
     * status content
     */
    children: PropTypes.node.isRequired

};
