import EmptyLayout from './EmptyLayout';
import Layout from './Layout';

const LayoutConfig = [
    {
        path: '/delivery-challan',
        layout: EmptyLayout
    },
    {
        path: '/login',
        layout: EmptyLayout
    },
    {
        path: '/submit-rates',
        layout: EmptyLayout
    },
    {
        path: 'public',
        layout: EmptyLayout
    },
    {
        path: 'rfq/[vendorId]',
        layout: EmptyLayout
    },
    {
        path: '/customer-quotations/[id]/final-quotation/pdf',
        layout: EmptyLayout
    },
    {
        path: '/customer-quotations/[id]/final-quotation/general-terms',
        layout: EmptyLayout
    },
    {
        path: '/404',
        layout: EmptyLayout
    },
    {
        path: '/',
        layout: Layout
    },
    {
        path: '',
        layout: EmptyLayout
    }
];

const getLayout = (path) => {
    const configuration = LayoutConfig.find((config) => path.includes(config.path));
    if (configuration) return configuration.layout;
    return EmptyLayout;
};

export default getLayout;
