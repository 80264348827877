/* eslint-disable max-len */
const ApiUrl = process.env.NEXT_PUBLIC_API_URL;

const UrlParamsReplace = (url, params = {}) => {
    let urlWithPrefix = `${ApiUrl}${url}`;
    if (params) {
        Object.keys(params).forEach(
            // eslint-disable-next-line no-return-assign
            (key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]))
        );
    }
    return urlWithPrefix;
};

export const GET_SERVER_TIME = UrlParamsReplace('/server-time/today');

export const USER_LOG_OUT = UrlParamsReplace('/users/logout');

// aws
export const GET_PRE_SIGNED_URL = UrlParamsReplace('/aws/get-pre-signed-url');
export const GET_UPLOAD_PRE_SIGNED_URL = UrlParamsReplace(
    '/aws/get-upload-pre-signed-url'
);
export const GET_SIGNED_URL = (fileKey) => UrlParamsReplace('/aws/get-s3-signed-url?fileKey=:fileKey', { fileKey });
export const GET_SIGNED_URL_WITHOUT_SECURITY = (fileKey, useNanoReportBucket) => UrlParamsReplace('/aws/get-public-s3-signed-url?fileKey=:fileKey&useNanoReportBucket=:useNanoReportBucket', { fileKey, useNanoReportBucket });

// user
export const ADD_USER = UrlParamsReplace('/users');
export const GET_USER_FOR_AUTHENTICATION = UrlParamsReplace('/user-authentication');
export const GET_USERS = (offset, limit, search, role) => UrlParamsReplace(
    '/users?offset=:offset&limit=:limit&search=:search&role=:role',
    {
        offset, limit, search, role
    }
);
export const GET_USER = (id) => UrlParamsReplace('/users/:id', { id });
export const UPDATE_USER = (id) => UrlParamsReplace('/users/:id', { id });
export const GET_USERS_BY_MOBILE = (mobileCountryCode, mobile) => UrlParamsReplace('/get-users-by-mobile?mobile=:mobile&mobileCountryCode=:mobileCountryCode', {
    mobileCountryCode,
    mobile
});
export const GET_BUHEADS = UrlParamsReplace('/bu-heads');
export const GET_ALL_USERS = (offset, limit, search, role) => UrlParamsReplace(
    '/all-users?offset=:offset&limit=:limit&search=:search&role=:role',
    {
        offset, limit, search, role
    }
);
// GST Details
export const GET_GST_DETAILS = (gstIn) => UrlParamsReplace('/gsts/:gstIn', { gstIn });
export const GET_GST_DETAILS_NO_AUTH = (gstIn) => UrlParamsReplace('/gsts/:gstIn/no-auth', { gstIn });
export const GET_ALL_GST_STATE_DETAILS = UrlParamsReplace('/gst-state');

// IFSC Details
export const GET_IFSC_DETAILS = (ifsc) => UrlParamsReplace('/ifscs/:ifsc', { ifsc });

// customer
export const ADD_CUSTOMER = UrlParamsReplace('/customers');
export const GET_CUSTOMERS = (offset, limit, search) => UrlParamsReplace('/customers?offset=:offset&limit=:limit&search=:search', {
    offset,
    limit,
    search
});
export const GET_CUSTOMER = (id) => UrlParamsReplace('/customers/:id', { id });
export const GET_CUSTOMER_FINANCIAL_DETAILS = (id) => UrlParamsReplace('/customers/:customerId/financial-report', { customerId: id });
export const GET_LAST_N_INVOICES = (zohoCustomerId, count) => UrlParamsReplace('/customers/:zohoCustomerId/invoices?count=:count', { zohoCustomerId, count });
export const GET_LAST_N_TRANSACTIONS = (zohoCustomerId, count) => UrlParamsReplace('/customers/:zohoCustomerId/transactions?count=:count', { zohoCustomerId, count });
export const UPDATE_CUSTOMER = UrlParamsReplace('/customers');
export const GET_CUSTOMERS_BY_NAME = (name) => UrlParamsReplace('/customers/get-customers-by-name?name=:name', { name });
export const UPDATE_CUSTOMER_STATUS = (customerId) => UrlParamsReplace('/customer/:customerId/status', { customerId });
export const CREATE_CUSTOMER_COMMENT_IN_ZOHO = (zohoCustomerId) => UrlParamsReplace('/zoho-customer-comment?zohoCustomerId=:zohoCustomerId', { zohoCustomerId });

// items
export const GET_ITEMS = (category) => UrlParamsReplace('/get-items?category=:category', { category });
export const GET_ALL_ITEMS = (offset, limit, search, categoryId, itemType) => UrlParamsReplace('/items?offset=:offset&limit=:limit&search=:search&categoryId=:categoryId&itemType=:itemType', {
    offset, limit, search, categoryId, itemType
});
export const ADD_ITEMS_FROM_CSV = UrlParamsReplace('/bulk-items');
export const PRICE_UPDATE_ITEMS_FROM_CSV = UrlParamsReplace('/price-update-bulk-items');
export const VALIDATE_ITEMS_FROM_CSV = UrlParamsReplace('/bulk-items-validations');
export const PRICE_UPDATE_VALIDATE_ITEMS_FROM_CSV = UrlParamsReplace('/price-update-bulk-items-validations');
export const CREATE_DEFAULT_RATE = UrlParamsReplace('/items/rates');
export const GET_DEFAULT_RATES = (offset, limit, search, department, vendorId) => UrlParamsReplace(
    '/items/:vendorId/rates?offset=:offset&limit=:limit&search=:search&department=:department',
    {
        offset,
        limit,
        search,
        department,
        vendorId
    }
);
export const EDIT_DEFAULT_RATE = UrlParamsReplace('/items/rates');
export const GET_ITEM_GRADES = (itemId) => UrlParamsReplace('/items/:itemId/grades', { itemId });

// requirement
export const ADD_REQUIREMENT = UrlParamsReplace('/requirements');
export const ADD_REQUIREMENT_ITEM = (requirementId) => UrlParamsReplace('/requirements/:requirementId/item', { requirementId });
export const GET_REQUIREMENT_DETAILS = (requirementId) => UrlParamsReplace('/requirements/:requirementId', { requirementId });
export const LIST_REQUIREMENT = (offset, limit, search) => UrlParamsReplace('/requirements?offset=:offset&limit=:limit&search=:search', {
    offset,
    limit,
    search
});
export const UPDATE_REQUIREMENT = (requirementId, details) => UrlParamsReplace('/requirements/:requirementId', { requirementId, details });

export const UPDATE_REQUIREMENT_ITEM = (requirementId) => UrlParamsReplace('/requirements/:requirementId/item', { requirementId });
export const UPDATE_REQUIREMENT_L1L4People = (requirementId) => UrlParamsReplace('/requirements/:requirementId/sales-people', { requirementId });
export const UPDATE_REQUIREMENT_ITEM_DESCRIPTION = (requirementItemId) => UrlParamsReplace('/requirements-item/:requirementItemId/description', { requirementItemId });
export const DELETE_REQUIREMENT_ITEM = (requirementId) => UrlParamsReplace('/requirements/:requirementId', { requirementId });
export const UPDATE_CUSTOMER_QUOTATION_ITEM = (requirementItemId) => UrlParamsReplace('/requirements-item/:requirementItemId/customer-quotation', { requirementItemId });
export const DELETE_CUSTOMER_QUOTATION_ITEM = (requirementItemId, customerQuotationId) => UrlParamsReplace('/requirements-item/:requirementItemId/delete-item', { requirementItemId, customerQuotationId });
export const CREATE_RFQ_AND_CUSTOMER_QUOTATION = (id) => UrlParamsReplace('/requirements/:id/create-customer-quotation', { id });

// rfqs
export const GET_RFQS = (offset, limit, search, departmentId, sort) => UrlParamsReplace('/rfqs?offset=:offset&limit=:limit&search=:search&departmentId=:departmentId&sort=:sort', {
    offset,
    limit,
    search,
    departmentId,
    sort
});
export const GET_RFQ_DETAILS = (rfqId) => UrlParamsReplace('/rfqs/:rfqId', { rfqId });
export const SEND_RFQ_TO_VENDORS = UrlParamsReplace('/rfqs/send');
export const RESEND_RFQ_TO_VENDORS = UrlParamsReplace('/rfqs/resend');
export const UPDATE_RFQ_DOCUMENT = (rfqId) => UrlParamsReplace('/rfqs/:rfqId/document', { rfqId });
export const UPDATE_RFQ = (rfqId) => UrlParamsReplace('/rfq/:rfqId', { rfqId });
export const GET_CATEGORISED_VENDORS_FOR_RFQ = (rfqId) => UrlParamsReplace('/rfqs/:rfqId/categorised-vendors', { rfqId });
export const GET_CATEGORISED_VENDORS_FOR_RFQ_WITH_AUTOMATED_BID = (rfqId) => UrlParamsReplace('/rfqs/:rfqId/vendors', { rfqId });
// vendor
export const ADD_VENDOR = UrlParamsReplace('/vendors');
export const GET_VENDORS = (offset, limit, search, categoryId, onlyActiveVendors) => UrlParamsReplace('/vendors?offset=:offset&limit=:limit&search=:search&categoryId=:categoryId&onlyActiveVendors=:onlyActiveVendors', {
    offset,
    limit,
    search,
    categoryId,
    onlyActiveVendors
});
export const GET_VENDOR = (id) => UrlParamsReplace('/vendors/:id', { id });
export const UPDATE_VENDOR = (id) => UrlParamsReplace('/vendors/:id', { id });
export const UPDATE_VENDOR_APPROVER = (vendorId) => UrlParamsReplace('/vendors/:vendorId/vendorApprover', { vendorId });
export const UPDATE_VENDOR_STATUS = (vendorId) => UrlParamsReplace('/vendor/:vendorId/status', { vendorId });
export const ADD_VENDOR_PICKUP_LOCATION = (vendorId) => UrlParamsReplace('/vendors/:vendorId/pickup-address', { vendorId });
export const GET_ALL_VENDORS = ({ offset, limit, search }) => UrlParamsReplace('/vendor-permissions-search?offset=:offset&limit=:limit&search=:search', {
    offset,
    limit,
    search
});

// contactDetails
export const ADD_CONTACT_DETAILS = UrlParamsReplace('/contact-details');
export const UPDATE_CONTACT_DETAILS = UrlParamsReplace('/contact-details');
export const GET_CONTACT_DETAILS = (type, tableId, offset, limit) => UrlParamsReplace('/contact-details?type=:type&tableId=:tableId&offset=:offset&limit=:limit', {
    type, tableId, offset, limit
});
export const GET_CONTACT_DETAILS_BY_ID = (id) => UrlParamsReplace('/contact-details/:id', { id });

// delivery manager and aris internal contact details
export const GET_DELIVERY_MANAGER_DETAILS = (vendorId) => UrlParamsReplace('/vendors/:vendorId/get-contact-managers', { vendorId });

// assetDetails
export const ADD_ASSET_DETAILS = UrlParamsReplace('/asset-details');
export const UPDATE_ASSET_DETAILS = (id) => UrlParamsReplace('/asset-details/:id', { id });
export const GET_ASSET_DETAILS = (tableType, assetType, tableId, offset, limit) => UrlParamsReplace('/asset-details?tableType=:tableType&tableId=:tableId&assetType=:assetType&offset=:offset&limit=:limit', {
    tableType, assetType, tableId, offset, limit
});

// document
export const ADD_DOCUMENT = () => UrlParamsReplace('/documents');
export const GET_DOCUMENTS = ({ tableId, tableType }) => UrlParamsReplace('/documents?tableId=:tableId&tableType=:tableType', { tableId, tableType });
export const UPDATE_DOCUMENT = (id) => UrlParamsReplace('/documents/:id', { id });
// bankDetail
export const ADD_VENDOR_BANKDETAIL = (vendorId) => UrlParamsReplace('/vendors/:vendorId/bankDetails', { vendorId });
export const GET_VENDOR_BANKDETAIL = (vendorId) => UrlParamsReplace('/vendors/:vendorId/bankDetails', { vendorId });
export const UPDATE_VENDOR_BANKDETAIL = (vendorId, id) => UrlParamsReplace('/vendors/:vendorId/bankDetails/:id', { vendorId, id });
// roles
export const GET_ROLES = UrlParamsReplace('/roles');

// designations
export const GET_DESIGNATIONS = ({ isCustomer, isVendor }) => UrlParamsReplace('/designations?isVendor=:isVendor&isCustomer=:isCustomer', { isCustomer, isVendor });

// categories
export const GET_CATEGORIES = (offset, limit, name) => UrlParamsReplace('/categories?offset=:offset&limit=:limit&name=:name', { offset, limit, name });
export const GET_ALL_CATEGORIES = UrlParamsReplace('/all-categories');

// departments
export const GET_DEPARTMENTS = UrlParamsReplace('/departments');

// project
export const ADD_PROJECT = UrlParamsReplace('/add-project');
export const GET_ALL_PROJECT_NAMES = UrlParamsReplace('/get-all-project-names');
export const GET_PROJECT_CUSTOMER_NAMES = (offset, limit, search, projectId) => UrlParamsReplace('/projects/:projectId/customer-names?offset=:offset&limit=:limit&search=:search', {
    offset, limit, search, projectId
});
export const GET_PROJECTS = (offset, limit, search, onlyActiveCustomers) => UrlParamsReplace('/projects?offset=:offset&limit=:limit&search=:search&onlyActiveCustomers=:onlyActiveCustomers', {
    offset, limit, search, onlyActiveCustomers
});
export const GET_PROJECT = (id) => UrlParamsReplace('/projects/:id', { id });
export const UPDATE_PROJECT = (id) => UrlParamsReplace('/projects/:id', { id });
export const PROJECT_REQUIREMENTS = (offset, limit, search, projectId) => UrlParamsReplace('/projects/:projectId/requirements?offset=:offset&limit=:limit&search=:search', {
    projectId,
    offset,
    limit,
    search
});
export const PROJECT_RFQS = (offset, limit, search, projectId, departmentId) => UrlParamsReplace('/projects/:projectId/rfqs?offset=:offset&limit=:limit&search=:search&departmentId=:departmentId', {
    projectId,
    offset,
    limit,
    search,
    departmentId
});
export const PROJECT_VENDOR_QUOTATIONS = (offset, limit, search, projectId, departmentId) => UrlParamsReplace('/projects/:projectId/vendors?offset=:offset&limit=:limit&search=:search&departmentId=:departmentId', {
    projectId,
    offset,
    limit,
    search,
    departmentId
});

export const ADD_CUSTOMER_TO_PROJECT = (projectId) => UrlParamsReplace('/projects/:projectId/add-customer', { projectId });
export const ADD_PROJECT_SITE_ADDRESS = (projectId) => UrlParamsReplace('/projects/:projectId/site-address', { projectId });

// vendor-quotaions
export const GET_VENDORS_QUOTATIONS = (offset, limit, search, departmentId, sort) => UrlParamsReplace('/vendors-quotations?offset=:offset&limit=:limit&search=:search&departmentId=:departmentId&sort=:sort', {
    offset, limit, search, departmentId, sort
});
export const GET_VENDOR_QUOTATIONS_FOR_RFQ = (rfqId) => UrlParamsReplace('/vendors-quotations/:rfqId', { rfqId });
export const UPDATE_VENDOR_QUOTATION = (id) => UrlParamsReplace('/vendors-quotations/:id/update', { id });
export const GET_VENDOR_QUOTATIONS_OF_VENDOR = (rfqVendorId) => UrlParamsReplace('/vendors-quotations/vendors/:rfqVendorId', { rfqVendorId });
export const UPDATE_VENDOR_QUOTATION_WITHOUT_AUTH = UrlParamsReplace('/vendors-quotations-without-auth/update');
export const GET_RFQ_VENDORS_OF_RFQ = (rfqId) => UrlParamsReplace('/rfqs/:rfqId/rfq-vendors', { rfqId });

// customer-quotations
export const GET_CUSTOMER_QUOTATIONS = (offset, limit, search, sort, projectCustomerId, filter, createdAt) => UrlParamsReplace('/customer-quotations?offset=:offset&limit=:limit&search=:search&sort=:sort&projectCustomerId=:projectCustomerId&filter=:filter&createdAt=:createdAt', {
    offset, limit, search, sort, projectCustomerId, filter, createdAt
});
export const GET_CUSTOMER_QUOTATIONS_INTERNAL = (offset, limit, search) => UrlParamsReplace('/customer-quotations/approve-internal?offset=:offset&limit=:limit&search=:search', {
    offset, limit, search
});
export const GET_CUSTOMER_QUOTATION = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId', { customerQuotationId });
export const REFRESH_VENDOR_BASE_PRICES = () => UrlParamsReplace('/rfqs/vendors-base-price');
export const GET_CUSTOMER_QUOTATION_FOR_INTERNAL_APPROVAL = (id) => UrlParamsReplace('/customer-quotation/internal-approval?id=:id', { id });
export const GET_CUSTOMER_QUOTATION_WITHOUT_AUTH = (customerQuotationId) => UrlParamsReplace('/customer-quotations-without-auth/:customerQuotationId', { customerQuotationId });
export const CONFIRM_CUSTOMER_QUOTATION_DRAFT = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/confirm-draft', { customerQuotationId });
export const APPROVE_CUSTOMER_QUOTATION_INTERNAL = (id) => UrlParamsReplace('/customer-quotations/internal-approval/approve?id=:id', { id });
export const SEND_CUSTOMER_QUOTATION_INTERNAL_APPROVAL = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/send-for-internal-approval', { customerQuotationId });
export const SEND_FOR_INTERNAL_APPROVAL = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/internal-approval', { customerQuotationId });
export const APPROVE_CUSTOMER_QUOTATION_BY_CUSTOMER_WITHOUT_AUTH = (customerQuotationId) => UrlParamsReplace('/customer-quotations-without-auth/:customerQuotationId/approve-by-customer', { customerQuotationId });
export const SEND_CUSTOMER_QUOTATION_TO_CUSTOMER = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/send-to-customer', { customerQuotationId });
export const RESEND_CUSTOMER_QUOTATION_TO_CUSTOMER = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/resend-to-customer', { customerQuotationId });
export const SEND_FINAL_CUSTOMER_QUOTATION_TO_CUSTOMER = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/final-quotation/send-to-customer', { customerQuotationId });
export const DECLINE_QUOTATION_INTERNAL = (id) => UrlParamsReplace('/customer-quotations/internal-approval/decline?id=:id', { id });
export const RESEND_RFQ_FROM_CUSTOMER_QUOTATION = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/resend-rfq', { customerQuotationId });
export const UPDATE_REQUIREMENT_ITEMS_WITHOUT_AUTH = UrlParamsReplace('/requirement-items-without-auth/update');
export const UPDATE_VENDOR_COUNTER_OFFER_WITHOUT_AUTH = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/update-vendor-offer', { customerQuotationId });
export const GET_VENDOR_QUOTATIONS_WITHOUT_AUTH = (customerQuotationId, vendorId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/vendors/:vendorId/counter-offer', { customerQuotationId, vendorId });
export const UPDATE_CUSTOMER_QUOTATION = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId', { customerQuotationId });
export const CHANGE_VENDORS_FOR_CUSTOMER_QUOTATION = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/change-vendors', { customerQuotationId });
export const ADD_VENDOR_TO_CUSTOMER_QUOTATION_RFQ = (customerQuotationId, rfqId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/rfqs/:rfqId/add-vendors', { customerQuotationId, rfqId });
export const UPDATE_CUSTOMER_QUOTATION_STATUS = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/change-status', { customerQuotationId });
export const UPDATE_CUSTOMER_QUOTATION_TNC = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/update-tnc', { customerQuotationId });
export const SAVE_CUSTOMER_QUOTATION_DRAFT = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/save-draft', { customerQuotationId });
export const SWITCH_TO_CUSTOMER_MODE = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/switch-to-customer-mode', { customerQuotationId });
export const GET_VENDOR_ADDRESS_FOR_QUOTATION = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/address', { customerQuotationId });
export const CONFIRM_OPEN_QUANTITY_CUSTOMER_QUOTATION = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/open-quantity/confirm-draft', { customerQuotationId });
export const SAVE_OPEN_QUANTITY_CUSTOMER_QUOTATION = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/open-quantity/save-draft', { customerQuotationId });
export const GET_VENDORS_FOR_CUSTOMER_QUOTATION_SEND_RFQ = (customerQuotationId, rfqId, offset, limit, search, categoryId, onlyActiveVendors) => UrlParamsReplace('/customer-quotations/:customerQuotationId/rfqs/:rfqId/rfq-vendors?offset=:offset&limit=:limit&search=:search&categoryId=:categoryId&onlyActiveVendors=:onlyActiveVendors', {
    customerQuotationId, rfqId, offset, limit, search, categoryId, onlyActiveVendors
});

export const REALLOCATE_VENDORS_CUSTOMER_QUOTATIONS = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/reallocate-vendors', { customerQuotationId });
export const VALIDATE_REALLOCATED_QUANTITY_OF_CUSTOMER_QUOTATION = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/validate-reallocated-quantity', { customerQuotationId });
export const REVERT_REALLOCATE_CUSTOMER_QUOTATIONS = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/revert-reallocate', { customerQuotationId });
export const CONFIRM_REALLOCATION_CUSTOMER_QUOTATION = (customerQuotationId) => UrlParamsReplace('/customer-quotations/:customerQuotationId/confirm-reallocation', { customerQuotationId });
// purchase-orders

export const GET_PURCHASE_ORDERS = (offset, limit, search, sort, deliveryStatus, documentStatus, departmentId) => UrlParamsReplace('/purchase-orders?offset=:offset&limit=:limit&search=:search&sort=:sort&deliveryStatus=:deliveryStatus&documentStatus=:documentStatus&departmentId=:departmentId', {
    offset, limit, search, sort, deliveryStatus, documentStatus, departmentId
});

export const GET_PURCHASE_ORDER = (purchaseOrderId) => UrlParamsReplace('/purchase-orders/:purchaseOrderId', {
    purchaseOrderId
});
export const GET_PURCHASE_ORDER_WITHOUT_AUTH = (purchaseOrderId) => UrlParamsReplace('/public/purchase-orders/:purchaseOrderId', {
    purchaseOrderId
});
export const GET_DELIVERY_MANAGERS_WITH_CM = (purchaseOrderId, vendorId) => UrlParamsReplace('/get-delivery-manager/:purchaseOrderId/:vendorId', {
    purchaseOrderId, vendorId
});

export const CONFIRM_DRAFT_PURCHASE_ORDER = (purchaseOrderId) => UrlParamsReplace('/purchase-orders/:purchaseOrderId/confirm-draft', {
    purchaseOrderId
});
export const SEND_PURCHASE_ORDER_TO_VENDOR = (purchaseOrderId) => UrlParamsReplace('/purchase-orders/:purchaseOrderId/send-final-purchase-order', {
    purchaseOrderId
});
export const UPDATE_PURCHASE_ORDER = (purchaseOrderId) => UrlParamsReplace('/purchase-orders/:purchaseOrderId', {
    purchaseOrderId
});
export const CLOSE_PURCHASE_ORDER = (purchaseOrderId) => UrlParamsReplace('/purchase-order/:purchaseOrderId/close', {
    purchaseOrderId
});
export const CREATE_PURCHASE_ORDER = UrlParamsReplace('/purchaseOrders/create');

export const CREATE_FULFILMENTS = UrlParamsReplace('/fulfilment/create/multiple');

export const CONFIRM_PURCHASE_ORDER = (purchaseOrderId) => UrlParamsReplace('/purchase-orders/:purchaseOrderId/confirm', { purchaseOrderId });

export const DUMP_LOGS_TO_AWS_CLOUD_WATCH_URL = UrlParamsReplace('/dump-logs/aws');

export const GET_PURCHASE_ORDER_BY_REQUIREMENT_ITEM_ID = () => UrlParamsReplace('/purchase-orders/req-id/get-purchase-orders');

export const GET_DEFAULT_PO_NUMBER = (poBranch, poCategory, poFinancialYear) => UrlParamsReplace('/purchase-order/deafult-po-number?poBranch=:poBranch&poCategory=:poCategory&poFinancialYear=:poFinancialYear', { poBranch, poCategory, poFinancialYear });

export const VALIDATE_PO_SHORT_CLOSE = (purchaseOrderId) => UrlParamsReplace('/purchase-orders/:purchaseOrderId/short-close/validate', { purchaseOrderId });
export const SHORT_CLOSE_PO = (purchaseOrderId, cancelBothSalesAndPurchaseOrder) => UrlParamsReplace('/purchase-orders/:purchaseOrderId/short-close?cancelBothSalesAndPurchaseOrder=:cancelBothSalesAndPurchaseOrder', { purchaseOrderId, cancelBothSalesAndPurchaseOrder });
export const VALIDATE_PAUSE_PO = (purchaseOrderId) => UrlParamsReplace('/purchase-orders/:purchaseOrderId/pause/validate', { purchaseOrderId });
export const PAUSE_PO = (purchaseOrderId) => UrlParamsReplace('/purchase-orders/:purchaseOrderId/pause', { purchaseOrderId });
export const RESUME_PO = (purchaseOrderId) => UrlParamsReplace('/purchase-orders/:purchaseOrderId/resume', { purchaseOrderId });
export const GET_PURCHASE_ORDER_VERSIONS = (purchaseOrderId) => UrlParamsReplace('/purchase-order/:purchaseOrderId/versions', { purchaseOrderId });
export const UPDATE_PO_ITEMS_RATES = (purchaseOrderId) => UrlParamsReplace('/purchase-orders/:purchaseOrderId/update-item-rates', { purchaseOrderId });
export const GET_CLOSEST_PICKUP_ADDRESS = (purchaseOrderId) => UrlParamsReplace('/purchase-orders/:purchaseOrderId/closest-pickup-address', { purchaseOrderId });
export const GET_DISTANCE_FROM_SITE_ADDRESS = (purchaseOrderId, addressId) => UrlParamsReplace('/purchase-orders/:purchaseOrderId/distance-from-site-address/:addressId', { purchaseOrderId, addressId });

export const GET_PURCHASE_ORDER_DETAILS_FOR_PO_EXEC = (purchaseOrderId) => UrlParamsReplace('/public/purchase-orders/:purchaseOrderId/po-exec', {
    purchaseOrderId
});

export const UPDATE_PO_SETTINGS = (purchaseOrderId) => UrlParamsReplace('/purchase-orders/:purchaseOrderId/settings', {
    purchaseOrderId
});

export const UPDATE_PO_L1L4PEOPLE = (purchaseOrderId) => UrlParamsReplace('/purchase-orders/:purchaseOrderId/sales-people', { purchaseOrderId });

// postal
export const GET_POSTAL_DETAILS = (pincode) => UrlParamsReplace('/postal-details/:pincode', { pincode });

export const GET_URL_PARAMS_WITHOUT_AUTH = (id) => UrlParamsReplace('/url-params/:id', { id });

// aris-branches
export const GET_ARIS_BRANCHES = UrlParamsReplace('/aris-branches');

// Addresses
export const GET_ALL_ADDRESSES = ({
    addressName, addressableId, addressableType, isReadOnly
}) => UrlParamsReplace('/addresses?addressName=:addressName&addressableId=:addressableId&addressableType=:addressableType&isReadOnly=:isReadOnly', {
    addressName, addressableId, addressableType, isReadOnly
});
export const ADD_ADDRESS = UrlParamsReplace('/addresses');
export const UPDATE_ADDRESS = UrlParamsReplace('/addresses');
export const ADD_GOOGLE_ADDRESS = UrlParamsReplace('/google-addresses');
export const UPDATE_ADDRESS_STATUS = (addressId) => UrlParamsReplace('/addresses/:addressId/status', { addressId });
export const GET_ENGLISH_TRANSLATE = UrlParamsReplace('/translate-hindi-to-english');

// quick-quote-requirements
export const CREATE_QUICK_QUOTE_REQUIREMENT = UrlParamsReplace('/quick-quote-requirements');
export const GET_QUICK_QUOTE_REQUIREMENT = (quickQuoteRequirementId) => UrlParamsReplace('/quick-quote-requirements/:quickQuoteRequirementId', { quickQuoteRequirementId });
export const GET_QUICK_QUOTE_REQUIREMENTS = (offset, limit, departmentId, search) => UrlParamsReplace('/quick-quote-requirements?offset=:offset&limit=:limit&departmentId=:departmentId&search=:search', {
    offset, limit, departmentId, search
});
export const ADD_ITEM_TO_QUICK_QUOTE = (quickQuoteRequirementId) => UrlParamsReplace('/quick-quote-requirements/:quickQuoteRequirementId/item', { quickQuoteRequirementId });
export const UPDATE_QUICK_QUOTE_REQUIREMENT = (quickQuoteRequirementId) => UrlParamsReplace('/quick-quote-requirements/:quickQuoteRequirementId', { quickQuoteRequirementId });
export const SEND_QUICK_QUOTE_TO_CUSTOMER = (quickQuoteRequirementId) => UrlParamsReplace('/quick-quote-requirements/:quickQuoteRequirementId/send-to-customer', { quickQuoteRequirementId });
export const DELETE_QUICK_QUOTE_REQUIREMENT_ITEM = (quickQuoteRequirementId, quickQuoteRequirementItemId) => UrlParamsReplace('/quick-quote-requirements/:quickQuoteRequirementId/item/:quickQuoteRequirementItemId', { quickQuoteRequirementId, quickQuoteRequirementItemId });
export const UPDATE_ITEM_OF_QUICK_QUOTE_REQUIREMENT = (quickQuoteRequirementId, quickQuoteRequirementItemId) => UrlParamsReplace('/quick-quote-requirements/:quickQuoteRequirementId/item/:quickQuoteRequirementItemId', { quickQuoteRequirementId, quickQuoteRequirementItemId });

// Locations
export const GET_LOCAIONS = () => UrlParamsReplace('/get-locations');
// auth0
export const GET_AUTH0_ROLES = UrlParamsReplace('/auth0/roles');
export const GET_AUTH0_USERS_BY_PHONE = (phoneNumber) => UrlParamsReplace('/auth0/get-user-by-phone?phoneNumber=:phoneNumber', { phoneNumber });
export const GET_AUTH0_USERS = ({ limit, page, search }) => UrlParamsReplace('/auth0/users?limit=:limit&page=:page&search=:search', { limit, page, search });
export const GET_AUTH0_USER = (userId) => UrlParamsReplace('/auth0/users/:userId', { userId });
export const UPDATE_AUTH0_USER = (id) => UrlParamsReplace('/auth0/users/:id', { id });
export const GET_AUTH0_USER_ROLES = (userId) => UrlParamsReplace('/auth0/users/:userId/roles', { userId });
export const ADD_AUTH0_USER = UrlParamsReplace('/auth0/users');

// Vendor Service Area
export const GET_VENDOR_SERVICE_AREAS = (vendorId) => UrlParamsReplace('/vendors-service-areas/:vendorId', { vendorId });
export const GET_VENDOR_TYPES = (offset, limit) => UrlParamsReplace('/vendor-types/?offset=:offset&limit=:limit', { offset, limit });
export const UPDATE_VENDOR_SERVICE_AREAS = (vendorId) => UrlParamsReplace('/vendors-service-areas/:vendorId', { vendorId });
// auditTrail
export const GET_LATEST_AUDIT_TRAIL_DATA_FOR_EVENT = (eventName, objectId) => UrlParamsReplace('/auditTrail?eventName=:eventName&objectId=:objectId', { eventName, objectId });
export const SEND_NOTIFICATION_FROM_AUDIT_TRAIL = (eventName, objectId) => UrlParamsReplace('/auditTrail/sendNotification?eventName=:eventName&objectId=:objectId', { eventName, objectId });
export const GET_AUDIT_TRAILS_HISTORY_DATA = (objectId, objectType, events) => UrlParamsReplace('/audit-trails/history?objectId=:objectId&objectType=:objectType&events=:events', { objectId, objectType, events });

// Google map url
export const GET_LOCATION_FROM_URL = UrlParamsReplace('/get-location-from-url');
export const GET_GEOCODE_JSON = UrlParamsReplace('/google-maps/get-geocode-json');
export const GET_STATIC_MAP = (center, zoom, size, color) => UrlParamsReplace('/google-maps/static-map?center=:center&zoom=:zoom&size=:size&color=:color', {
    center, zoom, size, color
});

// sales person
export const GET_SALES_PERSONS = UrlParamsReplace('/sales-people');
export const GET_SALES_PERSON_L2_L3_OPTIONS = (salesPersonId) => UrlParamsReplace('/get-l2-l3-mapping/:salesPersonId', { salesPersonId });
export const GET_SALES_PERSON_BY_TYPE = (type) => UrlParamsReplace('/sales-person/:type', { type });

// whatsapp
export const GET_WHATSAPP_MESSAGES = (offset, limit, filter, search, validate, documentTagFilter, taggedType, dateRange, sort, excludeFile) => UrlParamsReplace('/whatsapp-messages?offset=:offset&limit=:limit&validate=:validate&filter=:filter&search=:search&documentTagFilter=:documentTagFilter&taggedType=:taggedType&dateRange=:dateRange&sort=:sort&excludeFile=:excludeFile', {
    offset, limit, filter, search, validate, documentTagFilter, taggedType, dateRange, sort, excludeFile
});
export const GET_WHATSAPP_MESSAGE = (id) => UrlParamsReplace('/whatsapp-messages/:id', { id });
export const GET_OBJECT_TAGS = (whatsappMessageId) => UrlParamsReplace('/whatsapp-messages/get-document-tag/:whatsappMessageId', { whatsappMessageId });
export const GET_WHATSAPP_DOCUMENT_TAGS = () => UrlParamsReplace('/whatsapp-document-tags');

export const UPDATE_WHATSAPP_MESSAGE = (id) => UrlParamsReplace('/whatsapp-messages/:id', { id });
export const RESCAN_WHATSAPP_DOCUMENT = (id) => UrlParamsReplace('/whatsapp-messages/:id/rescan', { id });
export const CLONE_WHATSAPP_DOCUMENT = (id) => UrlParamsReplace('/whatsapp-messages/:id/clone', { id });
export const TAG_WHATSAPP_DOCUMENT = (id) => UrlParamsReplace('/whatsapp-messages/:id/tag-document', { id });

// delivery trips
export const GET_DELIVERY_TRIPS = (search) => UrlParamsReplace('/delivery-trips?search=:search', { search });
export const GET_DOCUMENT_TYPE_TAGS = (offset, limit, search) => UrlParamsReplace('/document-type-tags?offset=:offset&limit=:limit&search=:search', { offset, limit, search });
export const CREATE_DELIVERY_DOCUMENT = UrlParamsReplace('/delivery-documents');
export const CREATE_CV_EXTRACT_FOR_CUSTOM_CHALLAN = UrlParamsReplace('/cv-extract-custom-challan');
export const GET_DELIVERY_DOCUMENTS_TAGS = (search) => UrlParamsReplace('/delivery-documents/tags?search=:search', { search });
export const GET_PURCHASE_ORDERS_DELIVERIES = (offset, limit, deliveryStatus, documentStatus, search) => UrlParamsReplace('/purchase-orders-deliveries?offset=:offset&limit=:limit&deliveryStatus=:deliveryStatus&documentStatus=:documentStatus&search=:search', {
    offset, limit, deliveryStatus, documentStatus, search
});
export const GET_PURCHASE_DOCUMENTS = (purchaseOrderId) => UrlParamsReplace('/purchase-order/:purchaseOrderId/documents', { purchaseOrderId });
export const GET_DELIVERY_DOCUMENTS = (offset, limit, search, tagMethod) => UrlParamsReplace('/delivery-documents?offset=:offset&limit=:limit&search=:search&tagMethod=:tagMethod', {
    offset, limit, search, tagMethod
});
export const AUTO_TAG_DELIVERY_DOCUMENTS = (id) => UrlParamsReplace('/whatsapp-messages/:id/auto-tag', { id });
export const GET_DELIVERY_DOCUMENT = (id) => UrlParamsReplace('/delivery-documents/:id', { id });
export const UPDATE_DELIVERY_DOCUMENT = (id) => UrlParamsReplace('/delivery-documents/:id', { id });
export const RESEND_DELIVERY_MESSAGE_TO_CUSTOMER = (deliveryTripId, purchaseOrderId) => UrlParamsReplace('/delivery-trip/:deliveryTripId/resend-customers-msg?purchaseOrderId=:purchaseOrderId', { deliveryTripId, purchaseOrderId });
export const RESEND_DELIVERY_MESSAGE_TO_DELIVERY_MANAGER = (vendorId, purchaseOrderId) => UrlParamsReplace('/vendors/:vendorId/resend-delivery-manager-msg?purchaseOrderId=:purchaseOrderId', { vendorId, purchaseOrderId });
export const GET_DELIVERY_TRIP_DETAILS = (offset, limit, search, deliveryStatus, documentStatus, isDeliveryCompleteVerified, acceptanceChallanFilter) => UrlParamsReplace('/delivery-trip-details?offset=:offset&limit=:limit&search=:search&deliveryStatus=:deliveryStatus&documentStatus=:documentStatus&isDeliveryCompleteVerified=:isDeliveryCompleteVerified&acceptanceChallanFilter=:acceptanceChallanFilter', {
    offset, limit, search, deliveryStatus, documentStatus, isDeliveryCompleteVerified, acceptanceChallanFilter
});
export const GET_DELIVERY_TRIP_BY_VPO_REQUIREMENT_ITEM_ID = (purchaseOrderIds, requirementItemId) => UrlParamsReplace('/delivery-trips/req-items/get-delivery-trips', {
    purchaseOrderIds, requirementItemId
});

export const GET_DELIVERY_TRIP_BY_REQUIREMENT_ITEMS_VPO_ID = (requirementItemIds, purchaseOrderId) => UrlParamsReplace('/delivery-trip/deliveries/:purchaseOrderId?requirementItemIds=:requirementItemIds', {
    requirementItemIds, purchaseOrderId
});

export const GET_FULFILMENTS_BY_REQUIREMENT_ITEMS_VPO_ID = (purchaseOrderId) => UrlParamsReplace('/purchaseOrder/:purchaseOrderId/fulfilments', {
    purchaseOrderId
});

export const CANCEL_FULFILMENT = (deliveryId, deliveryTripId) => UrlParamsReplace('/fulfilment/:deliveryId/:deliveryTripId', {
    deliveryId,
    deliveryTripId
});

// sales orders
export const GET_SALES_ORDERS = (offset, limit, search, arisBranchId, departmentId, status, buHeadId) => UrlParamsReplace('/sales-orders?offset=:offset&limit=:limit&search=:search&arisBranchId=:arisBranchId&departmentId=:departmentId&status=:status&buHeadId=:buHeadId', {
    offset, limit, search, arisBranchId, departmentId, status, buHeadId
});
export const CREATE_SALES_ORDER = () => UrlParamsReplace('/sales-orders');
export const GET_SALES_ORDER = (salesOrderId) => UrlParamsReplace('/sales-orders/:salesOrderId', { salesOrderId });
export const GET_SALES_ORDER_ECHALLANS = (salesOrderId) => UrlParamsReplace('/sales-orders/:salesOrderId/echallans', { salesOrderId });
export const DOWNLOAD_SALES_ORDER_ECHALLANS = (salesOrderId) => UrlParamsReplace('/sales-orders/:salesOrderId/download-echallans-pdf', { salesOrderId });
export const CONFIRM_SALES_ORDER = (salesOrderId) => UrlParamsReplace('/sales-orders/:salesOrderId/confirm', { salesOrderId });
export const SAVE_DRAFT_SALES_ORDER = (salesOrderId) => UrlParamsReplace('/sales-orders/:salesOrderId', { salesOrderId });
export const IS_LATEST_SO_AVAILABLE = (salesOrderId) => UrlParamsReplace('/sales-orders/:salesOrderId/check-latest-so', { salesOrderId });
export const SEND_LATEST_SO_PDF = (salesOrderId) => UrlParamsReplace('/sales-orders/:salesOrderId/send-so-pdf', { salesOrderId });
export const VALIDATE_SO_SHORT_CLOSE = (salesOrderId) => UrlParamsReplace('/sales-orders/:salesOrderId/short-close/validate', { salesOrderId });
export const SHORT_CLOSE_SO = (salesOrderId) => UrlParamsReplace('/sales-orders/:salesOrderId/short-close', { salesOrderId });
export const PAUSE_RESUME_SO_ITEMS = (salesOrderId) => UrlParamsReplace('/sales-orders/:salesOrderId/pause-resume-items', { salesOrderId });
export const UPDATE_SO_ACCOUNTING_QUANTITY_TOLERANCE_LIMIT = (salesOrderId) => UrlParamsReplace('/sales-orders/:salesOrderId/accounting-quantity-tolerance-limit', { salesOrderId });
export const UPDATE_SO_L1L4PEOPLE = (salesOrderId) => UrlParamsReplace('/sales-orders/:salesOrderId/sales-people', { salesOrderId });
export const GET_VIRTUAL_PO_DATA = (requirementId) => UrlParamsReplace('/requirements/:requirementId/virtualPOAndPOItems', { requirementId });
export const GET_SO_ONLY_FULFILMENTS = (requirementId) => UrlParamsReplace('/requirements/:requirementId/fulfilments', { requirementId });

// Vendor Bid Sources
export const GET_VENDOR_BID_SOURCES = () => UrlParamsReplace('/vendor-bid-sources');
// site coordinators
export const GET_SITE_COORDINATORS = (requirementId, customerId) => UrlParamsReplace('/siteCoordinators/:requirementId/customer/:customerId', { requirementId, customerId });
export const GET_SALES_ORDER_ITEMS_FOR_ADJUSTMENT = (salesOrderId, customerId) => UrlParamsReplace('/sales-orders/:salesOrderId/exec-adjust/items?customerId=:customerId', { salesOrderId, customerId });
export const UPDATE_SALES_ORDER_ITEMS_FOR_EXEC_ADJUSTMENT = (salesOrderId) => UrlParamsReplace('/sales-orders/:salesOrderId/exec-adjust/customer-prices', { salesOrderId });

// Deal search
export const GET_DEAL_SEARCH_REQUIREMENT = ({
    search, limit, offset, reqCqSoPoId
}) => UrlParamsReplace('/requirements-deal-search?offset=:offset&limit=:limit&search=:search&reqCqSoPoId=:reqCqSoPoId', {
    search, limit, offset, reqCqSoPoId
});
export const GET_DEAL_SEARCH_REQUIREMENT_DETAILS = (requirementId) => UrlParamsReplace('/requirements-deal-search/:requirementId', { requirementId });
export const UPDATE_DEAL_ID = ({ dealId }) => UrlParamsReplace('/deal/:dealId/requirement', { dealId });
export const VALIDATE_DEAL_ID = ({ dealId, customerId, buHeadId }) => UrlParamsReplace('/deal/:dealId/validate?customerId=:customerId&buHeadId=:buHeadId', { dealId, customerId, buHeadId });

export const GET_ALL_DEAL_NUMBERS = ({ search, limit, offset }) => UrlParamsReplace('/deals?offset=:offset&limit=:limit&search=:search', { search, limit, offset });

export const CREATE_DEAL_ID_FOR_REQUIREMENT = ({ requirementId }) => UrlParamsReplace('/deal/requirement/:requirementId', { requirementId });

// Permissions
export const GET_ALL_PERMISSIONS_LIST = ({ offset, limit, search }) => UrlParamsReplace('/permissions?offset=:offset&limit=:limit&search=:search', { search, limit, offset });
export const GET_CUSTOMERS_FOR_PERMISSION = ({ offset, limit, search }) => UrlParamsReplace('/customers-for-permissions?offset=:offset&limit=:limit&search=:search', { search, limit, offset });
export const ADD_USER_PERMISSIONS = () => UrlParamsReplace('/permissions');
export const DELETE_USER_PERMISSIONS = () => UrlParamsReplace('/permissions');
export const GET_USER_FOR_PERMISSION = ({ mobile, mobileCountryCode }) => UrlParamsReplace('/users-for-permission?mobile=:mobile&mobileCountryCode=:mobileCountryCode', { mobile, mobileCountryCode });

// Deal Summary
export const GET_DEAL_SUMMARY_PROJECTED_DATA = ({ customerQuotationId }) => UrlParamsReplace('/deal-summary/:customerQuotationId', { customerQuotationId });
export const GET_DEAL_SUMMARY_GRAPH_DATA = ({ customerQuotationId }) => UrlParamsReplace('/deal-summary-graph/:customerQuotationId', { customerQuotationId });

// Payment Requests
export const GET_VENDOR_PAYMENT_REQUESTS = (details) => UrlParamsReplace(`/vendor-payment-requests?${details.queryString}`);
export const GET_VENDOR_PAYMENT_REQUESTS_EXEC_APPROVAL = ({ status, startDate, endDate }) => UrlParamsReplace('/vendor-payment-requests-exec-approval?status=:status&startDate=:startDate&endDate=:endDate', { status, startDate, endDate });

export const SEND_VENDOR_PAYMENT_REQUESTS = () => UrlParamsReplace('/vendor-payment-requests', {});

export const UPDATE_VENDOR_PAYMENT_REQUEST_FINAL_APPROVED_TO_APPROVED_STATUS = (vendorPaymentRequestId) => UrlParamsReplace('/vpr/:vendorPaymentRequestId/mark-unsent', { vendorPaymentRequestId });

export const DEACTIVATE_PAYMENT_REQUEST = (vendorPaymentRequestId) => UrlParamsReplace('/vpr/:vendorPaymentRequestId', { vendorPaymentRequestId });

export const RESEND_PAYMENT_REQUEST_FOR_BU_APPROVAL = (approvalActionId) => UrlParamsReplace('/vendor-payment-request/:approvalActionId/resend', { approvalActionId });

// TNC ITEMS
export const GET_RFQ_VENDOR_TNC_ITEMS = (rfqVendorId) => UrlParamsReplace('/tnc-items/rfq-vendor/:rfqVendorId', { rfqVendorId });
export const GET_TNC_ITEMS = ({ cqId, rfqVendorId }) => UrlParamsReplace('/tnc-items?cqId=:cqId&rfqVendorId=:rfqVendorId', { rfqVendorId, cqId });
