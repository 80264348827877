import React from 'react';
import PropTypes from 'prop-types';

const BodyStyle = ({
    children, name, type, ...property
}) => {
    const bodyType = {
        large: 'large',
        default: 'default',
        small: 'small'
    };

    const bodyStyle = {
        large: 'Body-Large',
        default: 'Body-Default',
        small: 'Body-Small'
    };

    return (
        <div tag={[bodyType[type], name]} {...property} className= {`${bodyStyle[type]} ${property.className || ''}`}>{children}</div>
    );
};

export default BodyStyle;

BodyStyle.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
};
