import { useState } from 'react';

const Tooltip = ({ text, children, position = 'top', maxWidth = 'none', width = 'fit-content' }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    let tooltipPositionClasses = '';

    switch (position) {
        case 'top':
            tooltipPositionClasses = 'bottom-full left-1/2 transform -translate-x-1/2';
            break;
        case 'bottom':
            tooltipPositionClasses = 'top-full left-1/2 transform -translate-x-1/2';
            break;
        case 'left':
            tooltipPositionClasses = 'right-full top-1/2 transform -translate-y-1/2';
            break;
        case 'right':
            tooltipPositionClasses = 'left-full top-1/2 transform -translate-y-1/2';
            break;
        default:
            tooltipPositionClasses = 'bottom-full left-1/2 transform -translate-x-1/2';
    }

    const tooltipStyle = {
        maxWidth: maxWidth,
        width: width,
    };

    return (
        <div className="relative inline-block" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            {showTooltip && (
                <div className={`absolute z-10 bg-Black-300 text-white text-sm py-2 px-2 rounded-md ${tooltipPositionClasses}`} style={tooltipStyle}>
                    {text}
                    <span className={`absolute w-3 h-3 ${position === 'top' ? 'bottom-0 left-1/2 transform -translate-x-1/2' : ''}${position === 'bottom' ? 'top-0 left-1/2 transform -translate-x-1/2' : ''}${position === 'left' ? 'right-0 top-1/2 transform -translate-y-1/2' : ''}${position === 'right' ? 'left-0 top-1/2 transform -translate-y-1/2' : ''} bg-Black-300 rotate-45`}></span>
                </div>
            )}
        </div>
    );
};

export default Tooltip;
