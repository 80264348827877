import { configureStore } from '@reduxjs/toolkit';
import customerQuotationReducer from './reducers/customerQuotation';
import loaderReducer from './reducers/loader';
import notificationReducer from './reducers/notification';
import tncReducer from './reducers/tnc';

const store = configureStore({
    reducer: {
        loader: loaderReducer,
        notification: notificationReducer,
        customerQuotation: customerQuotationReducer,
        tnc: tncReducer
    }
});

export default store;
