export const DocumentManagerEvents = {
    Document_Manager_Search: "Document_Manager_Search",
    Document_Manager_status_filter: "Document_Manager_status_filter",
    Document_Manager_Tagged_method_filter: "Document_Manager_Tagged_method_filter",
    Document_Manager_Document_tags_filter: "Document_Manager_Document_tags_filter",
    Document_Manager_Date_filter: "Document_Manager_Date_filter",
    Document_Manager_Sort: "Document_Manager_Sort",
    Document_Manager_Continue: "Document_Manager_Continue",
    Document_Manager_Auto_Tag: "Document_Manager_Auto_Tag",
    Document_Manager_Auto_Tag_Accept: "Document_Manager_Auto_Tag_Accept",
    Document_Manager_Auto_Tag_Cancel: "Document_Manager_Auto_Tag_Cancel",
    Document_Manager_clone: "Document_Manager_clone",
    Document_Manager_clone_yes: "Document_Manager_clone_yes",
    Document_Manager_clone_cancel: "Document_Manager_clone_cancel",
    Document_Manager_back_to_the_list: "Document_Manager_back_to_the_list",
    Document_Manager_download: "Document_Manager_download",
    Document_Manager_Previous: "Document_Manager_Previous",
    Document_Manager_Next: "Document_Manager_Next",
    Document_Manager_Discard: "Document_Manager_Discard",
    Deal_Search: "Deal_Search"

}