import React from 'react';
import PropTypes from 'prop-types';

const Span = ({ children, ...property }) => (
    <span className={`text-black  text-xs ${property.className}`} >{children}</span>
);

export default Span;

Span.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
};
