import Text from '../atoms/Text';

function Footer(props) {
    return (
        <div className='fixed bottom-0 z-10 h-15 bg-Gray-100 w-full flex items-center pl-6 '>
            <Text className='text-Gray-600 font-normal text-sm'>2022 © Aris Infra.</Text>
        </div>
    );
}

export default Footer;
