import { CQStatuses, SOStatuses } from "@/helpers/constants";
import moment from "moment";
import { useRouter } from "next/router";
import { Icon, Text } from "../atoms";
import Status from "../atoms/Status";
import { status } from '../config';
const CustomerQuotationDetailsCard = ({
    customerQuotation, salesOrder,
    requirementItem, className, setLocalStorage
}) => {
    const ratio = requirementItem.length ? `${requirementItem.filter((reqItem) => {
        return reqItem.status === 'vendor_selected'
            || reqItem.status === 'customer_approved'
    }).length}/${requirementItem.length}` : ""
    const router = useRouter()
    return (
        <div className="w-full">
            <Text type='sm500v1' className='pb-1.5'>Customer Quotation</Text>
            <div className={`flex flex-col  px-4 py-3.5 bg-white h-[160px] w-full rounded-lg ${className}`}>
                <div className="flex items-center justify-between border-b border-dashed border-Black-20 pb-3.5">
                    <Text type='base500'>{customerQuotation?.customerQuotationNo}</Text>
                    <Icon src='/images/rightArrowv2.svg' onClick={
                        () => {
                            setLocalStorage()
                            router.push(`/customer-quotations/${customerQuotation.id}`)
                        }
                    } className='cursor-pointer' />
                </div>
                <div className="mt-4 flex items-center gap-4">
                    <div>
                        <Text type='xs500'>Vendor</Text>
                        <Status variant={customerQuotation.internalStatus === CQStatuses.VPO_GENERATED ? 'Secondaryv1' : 'Primaryv1'} >
                            {customerQuotation.status === 'quotation' ? `${ratio} Vendor Selected`
                                : status.customerQuotation.label[customerQuotation.internalStatus]}</Status>
                    </div>
                    <div>
                        <Text type='xs500'>Customer</Text>
                        <Status
                            variant={
                                ([SOStatuses.COMPLETED].includes(salesOrder?.status)
                                    || [CQStatuses.SO_CONFIRMED].includes(customerQuotation?.status))
                                    ?
                                    'Secondaryv1' : 'Primaryv1'
                            }
                        >
                            {[SOStatuses.SHORTCLOSED, SOStatuses.COMPLETED, SOStatuses.VOID].includes(salesOrder?.status)
                                ? status.salesOrder.label[salesOrder?.status]
                                : status.customerQuotation.label[customerQuotation?.status]}
                        </Status>
                    </div>
                </div>
                <div className="flex items-center gap-1 whitespace-nowrap mt-4">
                    <Text type='xs500'>Created on <span className="font-normal text-Black-300 ml-px">{moment(customerQuotation.createdAt).format('DD/MM/YYYY')}</span> </Text>
                    <Text type='xs500'> by <span className="font-normal text-Black-300 ml-px">{customerQuotation.creator.fullName}</span> </Text>
                </div>
            </div>
        </div>
    )
}

export default CustomerQuotationDetailsCard