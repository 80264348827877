const isPublic = (route) => {
    const unprotectedRoutes = [
        '/login',
        '/authentication',
        '/404',
        '/delivery-challan',
        '/submit-rates/[rfqVendorId]',
        '/public/resend-rfq/[rfqVendorId]/image-view',
        '/public/customer-quotation/customer-approval',
        '/rfq/[vendorId]/image-view',
        '/rfq/[vendorId]/pdf-view',
        '/public/customer-quotation/[id]',
        '/public/whatsapp/counter-offer/vendors/[vendorId]',
        '/public/customer-quotations/vendor-counter-offer',
        '/public/customer-quotation/[id]/internal-approval-image',
        '/customer-quotations/[id]/final-quotation/pdf',
        '/public/general-terms',
        '/public/purchase-orders/[id]/pdf',
        '/public/purchase-orders/[id]/po-exec-pdf',
        '/public/whatsapp/header-card',
        '/public/send-rfq/image-view',
        '/public/submit-rates',
        '/public/customer-quotation/internal-approval',
        '/public/customer-quotation/internal-approval-image',
        '/public/customer-quotation/customer-counter-offer',
        '/public/quick-quote',
        '/public/quick-quotes/[id]/pdf',
        '/public/purchase-orders/[id]/general-terms',
        '/public/customer-quotation/[id]/general-terms',
        '/public/sales-orders/[id]/pdf',
        '/public/sales-orders/[id]/echallans/pdf',
        '/public/credit-report/[accountId]/pdf',
        '/public/exec-report/[accountId]/pdf',
        '/public/payment-request/pdf',
        '/public/payment-request/no-po-pdf',
        '/public/payment-request/approved-request-table'
    ];
    return unprotectedRoutes.includes(route);
};

export default isPublic;
