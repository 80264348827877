import React from 'react';
import PropTypes from 'prop-types';

const SubHeading = ({
    children, name, type, className, ...property
}) => {
    const subHeadingType = {
        type1: 'type1',
        type2: 'type2',
        type3: 'type3',
        type4: 'type4'
    };

    const subHeadingStyle = {
        type1: 'Subheading-1',
        type2: 'Subheading-2',
        type3: 'Subheading-3',
        type4: 'Subheading-4'
    };

    return (
        <div type={[subHeadingType[type], name]} className= {`${subHeadingStyle[type]} ${className}`} {...property}>{children}</div>
    );
};

export default SubHeading;

SubHeading.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
};
