import PropTypes from 'prop-types';

const Checkbox = ({
    register,
    name = 'checkbox', checked = false, onChange, id = 'checkbox', disabled = false, ...property
}) => (
    <input
        {...(register && { ...register(name) })}
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        className={`${property.className} cursor-pointer rounded-sm `}
    />

);

export default Checkbox;

Checkbox.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    // checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired
};
