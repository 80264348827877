/* eslint-disable no-param-reassign */
import { deepClone } from '@/helpers/common';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    requirementItems: [],
    showOnlySelectedBid: false,
    vendorsForCounterOffer: []

};

const customerQuotationSlice = createSlice({
    name: 'customerQuotation',
    initialState,
    reducers: {
        updateCustomerQuotation(state, action) {
            let newState = deepClone(state);
            newState = { ...newState, ...action.payload };
            return newState;
        },
        updateVendorsForCounterOffer(state, action) {
            state.vendorsForCounterOffer = action.payload;
        },
        resetCustomerQuotation(state, action) {
            state = initialState;
            return state;
        }
    }
});

export const customerQuotationActions = customerQuotationSlice.actions;

export default customerQuotationSlice.reducer;
