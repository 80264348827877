import axios from 'axios';
import {
    getAuth, removeSession
} from './identity.service';

export const postWithOutAuth = (url, entity) => new Promise((resolve, _reject) => {
    axios
        .post(url, entity)
        .then((response) => {
            if (response && response.data) {
                resolve({ status: true, data: response.data });
            }
        })
        .catch((ex) => {
            resolve({ status: false, error: ex?.response?.data?.error, errorMessage: ex?.response?.data?.message });
        });
});

export const postWithAuth = (url, entity) => new Promise((resolve, _reject) => {
    const auth = getAuth();
    const headers = {
        'content-type': 'application/json',
        'x-access-token': auth.token,
        authorization: `Bearer ${auth.token}`
    };

    axios
        .post(url, entity, { headers })
        .then((response) => {
            if (response && response.data) {
                resolve({ status: true, data: response.data });
            }
        })
        .catch((ex) => {
            if (ex?.response?.status === 401) {
                removeSession();
            } else {
                resolve({
                    statusCode: ex?.response?.status, status: false, error: ex?.response?.data?.error, errorMessage: ex?.response?.data?.message
                });
            }
        });
});

export const getWithOutAuth = (url) => new Promise((resolve, _reject) => {
    axios
        .get(url)
        .then((response) => {
            if (response && response.data) {
                resolve({ status: true, data: response.data });
            }
        })
        .catch((ex) => {
            resolve({ status: false, error: ex?.response?.data?.error, errorMessage: ex?.response?.data?.message });
        });
});

export const getWithAuth = (url, token = '') => {
    const headers = {
        'content-type': 'application/json',
        'x-access-token': token,
        authorization: `Bearer ${token}`
    };

    return new Promise((resolve, _reject) => {
        axios
            .get(url, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve({ status: true, data: response.data });
                }
            })
            .catch((ex) => {
                if (ex?.response?.status === 401) {
                    removeSession();
                } else {
                    resolve({
                        statusCode: ex?.response?.status, status: false, error: ex?.response?.data?.error, errorMessage: ex?.response?.data?.message
                    });
                }
            });
    });
};

export const getWithAuthWithToken = (url) => {
    const auth = getAuth();

    const headers = {
        'content-type': 'application/json',
        'x-access-token': auth?.token,
        authorization: `Bearer ${auth?.token}`
    };

    return new Promise((resolve, _reject) => {
        axios
            .get(url, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve({ status: true, data: response.data });
                }
            })
            .catch((ex) => {
                if (ex?.response?.status === 401) {
                    removeSession();
                } else {
                    resolve({ status: false, error: ex?.response?.data?.error, errorMessage: ex?.response?.data?.message });
                }
            });
    });
};

export const deleteWithAuth = (url, entity) => new Promise((resolve, _reject) => {
    const auth = getAuth();

    const headers = {
        'content-type': 'application/json',
        'x-access-token': auth?.token,
        authorization: `Bearer ${auth?.token}`
    };

    axios
        .delete(url, {
            data: entity,
            headers
        })
        .then((response) => {
            if (response && response.data) {
                resolve({ status: true, data: response.data });
            }
        })
        .catch((ex) => {
            if (ex?.response?.status === 401) {
                removeSession();
            } else {
                resolve({ status: false, error: ex?.response?.data?.error, errorMessage: ex?.response?.data?.message });
            }
        });
});

export const putWithAuth = (url, data) => {
    const auth = getAuth();

    const headers = {
        'content-type': 'application/json',
        'x-access-token': auth?.token,
        authorization: `Bearer ${auth?.token}`
    };

    return new Promise((resolve, _reject) => {
        axios
            .put(url, data, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve({ status: true, data: response.data });
                }
            })
            .catch((ex) => {
                if (ex?.response?.status === 401) {
                    removeSession();
                } else {
                    resolve({ status: false, error: ex?.response?.data?.error, errorMessage: ex?.response?.data?.message });
                }
            });
    });
};

export const putWithOutAuth = (url, data) => new Promise((resolve, _reject) => {
    axios
        .put(url, data)
        .then((response) => {
            if (response && response.data) {
                resolve({ status: true, data: response.data });
            }
        })
        .catch((ex) => {
            resolve({ status: false, error: ex?.response?.data?.error, errorMessage: ex?.response?.data?.message });
        });
});
