import { DELIVERY_STATUS } from '@/helpers/constants'
import { useRouter } from 'next/router'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Button, Icon, Text } from "../atoms"
import Searchv1 from "../atoms/Searchv1"
import CommonModal from "../molecules/CommonModal"
import CustomerAndProjectNameWithId from "../molecules/CustomerAndProjectNameWithId"
import CustomerQuotationDetailsCard from "../molecules/CustomerQuotationDetailsCard"
import ItemsAndNamesSection from "../molecules/ItemsAndNamesSection"
import PurchaseOrderDetailsCard from "../molecules/PurchaseOrderDetailsCard"
import RequirementDetailsCard from "../molecules/RequirementDetailsCard"
import SalesOrderDetailsCard from "../molecules/SalesOrderDetailsCard"
const DealSearchPopup = ({ onRequirementClick, isOnReqCqSoPoPage,
  showRequirementDetails, showDealSearch, onDealSearchClick,
  setDealSearchValue, dealSearchValue, fetchMoreData, hasMore, requirements,
  setShowRequirementDetails, setLocalStorage, lastSearch
}) => {
  const router = useRouter()

  return (
    <div>
      <CommonModal
        show={showDealSearch}
        heading='Deal Search'
        onCancel={onDealSearchClick}
        pb='pb-20'
      >
        {
          !showRequirementDetails ? <div className="px-7">
            <Searchv1
              placeholder={'Search by Req ID, Quotation ID, Purchase Order ID, Deal ID or Sales Order ID'}
              onSearch={(e) => setDealSearchValue(e.target.value)}
              value={dealSearchValue}
            />
            {
              (dealSearchValue?.length > 3 || lastSearch) ? <div>
                <Text type='xs500' className='mt-5 !text-sm'>
                  {(!lastSearch) &&
                    <>Results for <span className="text-Black-300 text-xs">'{dealSearchValue}'</span></>
                  }
                </Text>
                <InfiniteScroll
                  dataLength={requirements.rows.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<h4></h4>}
                  endMessage={<></>}
                  scrollableTarget="scrollableDiv"
                >
                  {requirements.rows.map((r, i) => {
                    const customerName = r.projectCustomer.customer.displayName
                    const projectName = r.projectCustomer.project.projectName
                    const icons = [
                      r.formattedId,
                      r?.deal?.dealNumber,
                      r.customerQuotation?.customerQuotationNo,
                      r.customerQuotation?.customerSaleOrderNumber,
                      r.salesOrder?.arisSaleOrderNumber,
                      ...r.purchaseOrder?.map((p) => p.vpoId),
                      ...r.purchaseOrder?.map((p) => p.poNumber)
                    ].filter(i => i)
                    return (
                      <>
                        {i === 0 && isOnReqCqSoPoPage && !dealSearchValue?.length &&
                          < Text type='xs500' className='mt-5 !text-sm'>
                            Current Record
                          </Text >}
                        {lastSearch && (i === 0 && !isOnReqCqSoPoPage || i === 1 && isOnReqCqSoPoPage) &&
                          < Text type='xs500' className='mt-5 !text-sm'>
                            Last searched
                          </Text >}
                        <CustomerAndProjectNameWithId
                          className='mt-4 cursor-pointer'
                          customerName={customerName}
                          icons={icons}
                          projectName={projectName}
                          dealSearchValue={dealSearchValue}
                          onClick={() => onRequirementClick(r)}
                          lastSearch={lastSearch}
                        />
                      </>
                    )
                  })}
                </InfiniteScroll>
              </div> :
                <div className="flex flex-col justify-center items-center w-full" style={{ height: '60vh' }}>
                  <Icon src='/images/SearchIcon_.svg' />
                  <Text type='lg600' className='text-center interFontfamily'>Start searching </Text>
                  <Text type='sm400' className='text-center !text-Gray-503 mt-3 interFontfamily'>Enter Req ID, Quotation ID, Purchase<br /> Order ID or Sales Order ID</Text>
                </div>
            }
          </div> :
            <div>
              <Button onClick={() => setShowRequirementDetails(null)} variant="Linkv1" iconType='backArrow' className='-ml-1 px-7' >Back</Button>
              <ItemsAndNamesSection
                className='mt-5 px-7'
                showRequirementDetails={showRequirementDetails}
              />
              <div className=" flex flex-col gap-9 pb-10 mt-6 px-7 py-[26px] bg-Black-5 min-h-[calc(100vh_-_27.5rem)] rounded-t-[20px] rounded-b-[10px] w-full ">
                <div className="flex items-center gap-x-[30px]">
                  <RequirementDetailsCard
                    requirementData={showRequirementDetails}
                    setLocalStorage={setLocalStorage}
                  />
                  {showRequirementDetails.customerQuotation
                    && <CustomerQuotationDetailsCard
                      customerQuotation={showRequirementDetails.customerQuotation}
                      salesOrder={showRequirementDetails.salesOrder}
                      requirementItem={showRequirementDetails.requirementItem}
                      setLocalStorage={setLocalStorage}
                    />}
                </div>
                {showRequirementDetails.salesOrder
                  && < SalesOrderDetailsCard
                    salesOrderData={showRequirementDetails.salesOrder}
                    cpo={showRequirementDetails.customerQuotation?.customerSaleOrderNumber}
                    purchaseOrders={showRequirementDetails.purchaseOrder}
                    setLocalStorage={setLocalStorage}
                    onDeliveriesClick={
                      () => {
                        setLocalStorage()
                        return router.push(`/deliveries/vpos?step=2&search=${showRequirementDetails.salesOrder.arisSaleOrderNumber}`)
                      }
                    }
                  />}
                <div className="grid grid-cols-2 gap-x-[30px]">
                  {showRequirementDetails.purchaseOrder.map((po, index) => {
                    const totalNoOfCard = showRequirementDetails.purchaseOrder.length
                    let deliveryCount = 0;
                    po.deliveries.forEach((delivery) => {
                      if ([DELIVERY_STATUS.DELIVERED, DELIVERY_STATUS.IN_TRANSIT].includes(delivery.deliveryStatus)) {
                        deliveryCount += parseInt(delivery.tripCount)
                      }
                    })
                    return (
                      <PurchaseOrderDetailsCard
                        totalNoOfCard={totalNoOfCard}
                        index={index}
                        generatedOn={po.createdAt.split('T')[0]}
                        cardPageNo={index}
                        noOfDeliveries={deliveryCount}
                        poId={po.vpoId}
                        poNo={po.poNumber}
                        poStatus={po.status}
                        vendorName={po.vendor.displayName}
                        onHandlePurchaseOrderClick={() => {
                          setLocalStorage()
                          router.push(`/purchase-orders/${po.id}`)
                        }}
                        onDeliveriesClick={
                          () => {
                            setLocalStorage()
                            router.push(`/deliveries/vpos?step=2&search=${po.vpoId}`)
                          }
                        }
                      />
                    )
                  })}
                </div>
              </div>
            </div>
        }
      </CommonModal >
    </div >
  )
}

export default DealSearchPopup