import ReadMoreReact from 'read-more-react';

import PropTypes from 'prop-types';

const TextClasses = {
    normal: 'text-sm font-normal text-Basic-300',
    normalv1: 'text-sm font-normal text-Gray-301',
    normalv2: 'text-Black-300 font-medium text-xs interFontfamily',
    tableRow: 'text-sm font-normal text-Gray-801',
    description: 'font-normal text-sm',
    bodySmall: 'bodySmallText text-xs font-normal text-Gray-501',
    small: 'text-xs font-normal',
    bold: 'text-sm font-bold',
    boldxs: 'text-xs font-bold text-Black-300 interFontfamily',
    bodyLarge: 'text--bodyLarge text-[#172B4D] font-medium text-xs',
    body: 'bodyText',
    labelv1: 'w-auto px-2 h-9 rounded-l border-r text-sm font-normal flex justify-center items-center text-Black-60',
    label: 'labelText text-sm font-medium text-Gray-1000',
    titlelg: 'font-bold text-base text-Black-300 ManropeFontFamily',
    title: 'text-base text-[#14142B] font-medium interFontfamily',
    titlev1: 'text-sm font-semibold text-Black-400 interFontfamily',
    titlev2: 'text-sm font-semibold text-Black-300 interFontfamily',
    titlev3: 'text-base font-medium text-Black-300 interFontfamily',
    titleGray: 'text-base text-[#14142B] font-medium opacity-20',
    subtitle: 'text-lg font-medium text-Gray-702',
    subtitlev1: 'text-xs font-normal text-Gray-503 interFontfamily',
    caption: 'captionText',
    captionSmall: 'captionSmallText',
    paragraph: 'paragraphText',
    bold_v2: 'bold_v2',
    overline: 'text--overline',
    underline: 'font-seimbold text-xs text-primary-500 underline decoration-primary-500  cursor-pointer',
    ModalTitle: 'text-[#373751] text-lg font-semibold text-xl',
    ModalSubtitle: 'text-[#373751] text-base font-medium ',
    box: 'flex justify-center items-center h-10 text-xs font-medium text-Gray-501 bg-primary-501 bg-opacity-10 border-opacity-40 border border-primary-500 rounded min-w-[130px] w-auto px-3',
    priceBox: 'flex justify-center items-center w-auto h-11  bg-Gray-201 font-normal text-sm text-Gray-501 px-2.5 rounded-md ',
    title: 'text-[#14142B] font-bold text-medium',
    salesOrder: 'font-bold text-lg text-[#14142B] border border-[#14142B] py-2 px-4 text-center',
    tncRed: 'font-bold text-Red-100 border border-Red-100 text-center',
    verySmall: 'text-[10px] font-normal',
    xsSemibod: 'font-semibold text-xs text-Black-300 interFontfamily',
    smSemibod: 'font-semibold text-sm text-Black-300 interFontfamily',
    xs400: 'text-xs text-Black-300 font-normal interFontfamily',
    lgSemibod: 'font-semibold text-lg text-Black-300 interFontfamily',
    baseSemibold: 'font-semibold text-base text-Black-300 interFontfamily',
    xs500: 'font-medium text-xs text-Gray-31 leading-[18px] interFontfamily',
    xs500v1: 'font-medium text-[11px] text-Black-300 interFontfamily',
    xs500v2: 'font-medium text-[12px] text-Black-300 interFontfamily',
    xs500v3: 'font-medium text-[12px] text-Black-60 interFontfamily',
    xs500v4: 'font-medium text-xs text-Black-300',
    xs400v1: 'font-normal text-xs text-Black-60 leading-[19.2px] interFontfamily',
    xs400v2: 'font-normal text-xs text-Gray-31 leading-[18px] interFontfamily',
    xs400v3: 'font-normal text-xs text-Black-80 interFontfamily',
    xs400v4: 'text-[10px] font-normal text-Black-80 interFontfamily',
    xs400v5: 'text-[10px] font-normal text-Gray-503',
    xs400v6: 'text-xs font-normal text-Black-300',
    xs700: 'text-xs font-bold text-Black-300',
    sm500: 'font-medium text-sm text-Gray-31 leading-[22px] interFontfamily',
    sm500v0: 'text-sm font-medium text-Black-300',
    sm500v1: 'font-medium text-sm text-Black-300 leading-[22px] interFontfamily',
    sm500v2: 'font-medium text-sm text-Black-60 leading-[22px] interFontfamily',
    sm500v3: 'font-medium text-sm text-Gray-503 leading-[18.2px]',
    sm500v4: 'text-xs text-Black-300',
    sm500v5: 'font-medium text-xs text-Gray-503 leading-[18.2px]',
    sm500v6: 'font-medium text-sm text-Black-300 leading-[14px] interFontfamily',
    base500: 'font-medium text-base text-Black-300 leading-6 interFontfamily',
    base500v1: 'font-medium text-base text-Black-60',
    base400: 'font-normal text-base text-Black-300 interFontfamily',
    base400v1: 'font-normal text-base text-Black-80 ',
    base400v2: 'font-normal text-base text-Black-300 ',
    sm400: 'font-normal text-sm text-Black-80 leading-[21px] interFontfamily',
    sm400v1: 'font-normal text-sm text-Black-300 leading-[21px]',
    sm400v2: 'font-normal text-sm text-Black-60 leading-[21px] interFontfamily',
    xs600: 'font-semibold text-[13px] text-Black-300 interFontfamily',
    xs600v1: 'font-semibold text-xs text-Black-60 interFontfamily',
    sm600: 'font-semibold text-sm text-Black-300 leading-[21px] interFontfamily',
    base600: 'font-semibold text-base text-Black-300 leading-6 interFontfamily',
    lg600: 'font-semibold text-lg text-Black-300 leading-6 interFontfamily',
    xl500: 'font-medium text-[22px] text-Black-300 interFontfamily',
    xl600: 'font-semibold text-xl text-Black-300 interFontfamily',
    pill: 'bg-Black-10 px-2.5 py-[5px] rounded-[30px] text-xs font-medium text-Black-80 interFontfamily',
    description: 'text-Black-80 font-normal text-xs interFontfamily',
    error: 'text-Red-100 text-xs font-medium',
    warning: 'text-yellow-500 text-xs font-medium'
};

/**
 * ## Font-Size Chart
 *
 * | size             | font-size | line-height |
 * | ---------------- | --------- | ----------- |
 * | **bodySmall**    | 14        | 20          |
 * | **body**         | 16        | 24          |
 * | **bodyLarge**    | 18        | 20          |
 * | **label**        | 10        | 12          |
 * | **subtitle**     | 20        | 24          |
 * | **caption**      | 12        | 20          |
 * | **captionSmall** | 12        | 12          |
 * | **paragraph**    | 16        | 24          |
 * | **bold_v2**      | 16        | 24          |
 * | **overline**     | 16        | 20          |
 */

const FontWeight = {
    400: 'font-normal',
    500: 'font-medium',
    600: 'font-semibold',
    700: 'font-bold'
};

const Text = ({
    className, type, isRequired = false, children, minLength, color, fontWeight, iconType, iconClass, ...rest
}) => <div className={`${className ?? ''} ${iconType ? 'flex items-start' : ''}  ${TextClasses[type] || TextClasses.body} ${rest.onClick ? 'font-bod select-none cursor-pointer' : ''} ${color || ''} ${FontWeight[fontWeight] || ''}`} {...rest}>
        {iconType && <span className={`${iconClass} shrink-0`} type={iconType} ></span>
        }
        {
            minLength
                ? <ReadMoreReact min={minLength} ideal={minLength} max={minLength} text={children} readMoreText=".View More" />
                : children
        }
        {isRequired && <span className="required">*</span>}
    </div>;

Text.propTypes = {
    className: PropTypes.string,
    color: '',
    fontWeight: '',
    iconClass: ''
};

Text.propTypes = {
    className: PropTypes.string,
    /**
     * Which type should the button be?
     */
    type: PropTypes.oneOf(['bodySmall', 'body', 'bodyLarge', 'label', 'subtitle', 'caption', 'captionSmall', 'paragraph', 'bold_v2', 'overline', 'ModalHead', 'ModalSubtitle', 'underline', 'box', 'labelv1', 'normalv1', 'priceBox', 'salesOrder', 'tncRed', 'titlelg', 'xs400v1', 'sm500v2']),
    color: PropTypes.string,
    fontWeight: PropTypes.string,
    iconClass: PropTypes.string
};

export default Text;
