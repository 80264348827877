import { invalidCharacters, shortTncAllowedLines } from '../config';

export const adminInvestorColumns = [
    'Contact Number',
    'Name',
    'Registration Date',
    'KYC Status',
    'KYC submitted date'
];
export const kycInvestorColumns = [
    'Name',
    'Mobile Number',
    'Date of Upload',
    'Status',
    'Action'
];
export const steelBidCustomization = [
    'L-10.70M',
    'L-11.00M',
    'L-11.10M'
];
export const adminUrlsToEnableSearch = ['/admin/investors'];
export const kycFilterOptions = [
    {
        label: 'All',
        value: ''
    },
    {
        label: 'Pending',
        value: 'Pending'
    },
    {
        label: 'Verified',
        value: 'Verified'
    },
    {
        label: 'Manual Verification Required',
        value: 'ManualVerificationRequired'
    }
];
export const contactUsHeaderHeading = 'We would love to hear from you';
export const contactUsHeaderDescription = 'Write to us or call us with your feedback or your queries';

export const socialMediaIcons = [
    {
        src: '/images/contact-us/twitter.svg',
        link: 'https://twitter.com/JiraafOfficial'
    },
    {
        src: '/images/contact-us/linkedIn.svg',
        link: 'https://www.linkedin.com/company/jiraafofficial'
    },
    {
        src: '/images/contact-us/instagram.svg',
        link: 'https://www.instagram.com/jiraafofficial/'
    }
];

export const formatIndianNumber = (number, config = {}) => {
    if (number != null && !Number.isNaN(number)) {
        return number.toLocaleString('en-IN', config);
    }
    return 0;
};

export const formatIndianNumberWithFallback = (number, { fallback = '-', prefix = '₹' } = {}) => {
    if (number != null && !Number.isNaN(number)) {
        // fixing decimals to 2 for nanoreport amounts
        return prefix + number.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
    return fallback;
};

export const convertPaisaToRupees = (paisa) => {
    const rupees = paisa / 100;
    return rupees;
};
export const calculatedTransportCharges = (transportChargePerMT, quantity, truckCapacity) => {
    if (quantity > truckCapacity) {
        return transportChargePerMT;
    }
    return (transportChargePerMT * truckCapacity) / quantity;
};
export const getTotalCalculatedTransportCharges = (transportChargePerMT, quantity, truckCapacity) => {
    if (quantity > truckCapacity) {
        return transportChargePerMT * quantity;
    }
    return (transportChargePerMT * truckCapacity);
};
export const supportPhoneNumber = '+91 8953265326';
export const supportEmail = 'support@jiraaf.com';

export const contactCards = [
    {
        icon: '/images/contact-us/call-us.svg',
        label: 'Call Us',
        value: supportPhoneNumber,
        type: 'phone'
    },
    {
        icon: '/images/contact-us/email-us.svg',
        label: 'Email Us',
        value: supportEmail,
        type: 'email'
    }
];

export const panAlreadyExistMessage = 'The PAN you have entered is already associated with another account on Jiraaf. You cannot have the same PAN associated with more than one account with us.';

export const opportunityBreadcrumbs = (value) => [
    { title: 'Opportunity', url: '#' },
    { title: value, active: true }
];

export const transportTermsOptions = [
    {
        value: 'Included',
        label: 'Included'
    },
    {
        value: 'Not Included',
        label: 'Not Included'
    }
];

export const DUE_ON_RECEIPT_TEXT = 'Due on Receipt';

export const isTextDueOnReceipt = (text) => text === DUE_ON_RECEIPT_TEXT;

export const AFTER_SUPPLY_TEXT = 'Against delivery';
export const isAfterSupplyText = (text) => text === AFTER_SUPPLY_TEXT;
export const paymentTermsOptions = [
    {
        value: 5,
        label: '5'
    },
    {
        value: 15,
        label: '15'
    },
    {
        value: 30,
        label: '30'
    },
    {
        value: 45,
        label: '45'
    },
    {
        value: 60,
        label: '60'
    },
    {
        value: DUE_ON_RECEIPT_TEXT,
        label: DUE_ON_RECEIPT_TEXT
    },
    {
        value: AFTER_SUPPLY_TEXT,
        label: AFTER_SUPPLY_TEXT
    },
    {
        value: 0,
        label: 'Custom'
    }
];

export const advancePaymentOptions = [
    {
        value: 0,
        label: '0%'
    },
    {
        value: 50,
        label: '50%'
    },
    {
        value: 100,
        label: '100%'
    },
    {
        value: 0,
        label: 'Custom'
    }
];
export const unloadingOptions = [
    {
        value: 'vendor',
        label: 'In Vendor Scope'
    },
    {
        value: 'customer',
        label: 'In Customer Scope'
    }

];
export const unloadingOptionCategory = 'Steel';

export const staticOpportunity = (id) => id === '2d688a3c-3f76-4003-843a-2b7152c5eb01';

export const addressProofTypes = [
    { name: 'Aadhar Card', id: 'Aadhar' },
    { name: 'Voter Id', id: 'VoterId' },
    { name: 'Passport', id: 'Passport' }
];

export const kycImagePrefix = process.env.NEXT_PUBLIC_ADDRESS_PROOF_PREFIX;

export const bucket = process.env.NEXT_PUBLIC_INVESTOR_KYC_BUCKET;

export const getHeaderAndDescriptionForDoubleUpload = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                header: 'Please upload your Aadhaar Card',
                description: 'Your Details should be clearly readable'
            };
        case 'VoterId':
            return {
                header: 'Please upload your Voter ID',
                description: 'Your Details should be clearly readable'
            };
        case 'Passport':
            return {
                header: 'Please upload your Passport',
                description: 'Your Details should be clearly readable'
            };
        default:
            break;
    }
};

export const getHeaderAndDescriptionForSingleUpload = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                header: 'Please upload your Aadhaar Card',
                description:
                    'Please ensure that both the address and your photo is visible in the same file/image'
            };
        case 'VoterId':
            return {
                header: 'Please upload your Voter ID',
                description:
                    'Please ensure that both the address and your photo is visible in the same file/image'
            };
        case 'Passport':
            return {
                header: 'Please upload your Passport',
                description:
                    'Please ensure that both the address and your photo is visible in the same file/image'
            };
        default:
            break;
    }
};

export const getDoubleUploadData = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                front: {
                    header: 'Upload front side of Aadhaar',
                    defaultImageUrl: '/images/investors/kyc/aadhar-front.png'
                },
                back: {
                    header: 'Upload back side of Aadhaar',
                    defaultImageUrl: '/images/investors/kyc/aadhar-back.png'
                }
            };
        case 'VoterId':
            return {
                front: {
                    header: 'Upload front side of Voter Id',
                    defaultImageUrl: '/images/investors/kyc/voterId-front.png'
                },
                back: {
                    header: 'Upload back side of Voter Id',
                    defaultImageUrl: '/images/investors/kyc/voterId-back.png'
                }
            };
        case 'Passport':
            return {
                front: {
                    header: 'Upload Photo page of Passport',
                    defaultImageUrl: '/images/investors/kyc/passport-front.png'
                },
                back: {
                    header: 'Upload Address page of Passport',
                    defaultImageUrl: '/images/investors/kyc/passport-back.png'
                }
            };
        default:
            break;
    }
};

export const getSingleUploadData = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                defaultImageUrl: '/images/investors/kyc/aadhar-full.png'
            };
        case 'VoterId':
            return {
                defaultImageUrl: '/images/investors/kyc/voterId-full.png'
            };
        case 'Passport':
            return {
                defaultImageUrl: '/images/investors/kyc/passport-full.png'
            };
        default:
            break;
    }
};

export const getSingleUploadBottomText = (addressType) => {
    const addressTypes = {
        Aadhar: 'If you have a two seperate images for Aadhar and wish to upload each side separately, click',
        VoterId:
            'If you have a two seperate images for Voter ID and wish to upload each side separately, click',
        Passport:
            'If you have a two seperate images for Passport and wish to upload each side separately, click'
    };
    return addressTypes[addressType];
};

export const getDoubleUploadBottomText = (addressType) => {
    const addressTypes = {
        Aadhar: "Don't have a two-sided for Aadhaar?\nTo upload a single page Aadhaar click",
        VoterId:
            "Don't have a two seperated images for Voter ID?\nTo upload a single file of Voter ID click",
        Passport:
            "Don't have a two seperated images for Passport?\nTo upload a single file of Passport click"
    };
    return addressTypes[addressType];
};

export const allowedFileUploads = 'image/png, image/jpeg, image/jpg, application/pdf, image/tiff';

export const defaultUploadedPdfIcon = '/images/investors/kyc/default-pdf-icon.svg';

export const SYSTEM_ROLE_TYPE = 'System';

export const sortOptions = {
    rfq_vq_cq: [
        {
            label: 'Sort by Project Name',
            value: 'projectName',
            sortByTime: false
        },
        {
            label: 'Sort by Customer Name',
            value: 'organisationName',
            sortByTime: false
        },
        {
            label: 'Sort by Date Created',
            value: 'createdAt',
            sortByTime: true
        }
    ],
    purchaseOrders: [
        {
            label: 'Sort by Project Name',
            value: 'projectName',
            sortByTime: false
        },
        {
            label: 'Sort by Customer Name',
            value: 'customerOrganisationName',
            sortByTime: false
        },
        {
            label: 'Sort by Vendor Name',
            value: 'vendorOrganisationName',
            sortByTime: false
        },
        {
            label: 'Sort by Date Created',
            value: 'createdAt',
            sortByTime: true
        }
    ]
};

export const CQ_VALIDATIONS = {
    existingMsg: {
        errorMessage: 'existing customer quotations.',
        header: 'There are more recent quotes available for this customer.',
        note: 'More recent quotes are available for this customer which may contain latest data. We recommend to clone from latest data for best experience.'
    },
    olderMsg: {
        errorMessage: 'older customer quotation.',
        header: 'Cannot clone this Quotation.',
        note: 'This quote is more than 28 days old. To proceed , please select a more recent quote.'
    },
    validDays: 28
};
export const whatsAppCountryCode = {
    IND: '91',
    NEP: '977'
};

export const sendToCustomerEmailData = (customerQuotationNo) => ({
    emailBody: `
    <div>
        <div>Kindly click on the button below to view and accept the quote.</div>
        <br>
    </div>`,
    emailSubject: 'Best Offer -'
});

export const tableEventTypes = {
    sort: 'sort',
    filter: 'filter',
    search: 'search',
    reset: 'reset'
};

export const whatsAppRedirectUrl = (contact) => `https://wa.me/${contact ?? process.env.NEXT_PUBLIC_GUPSHUP_SENDER_CONTACT}`;

export const VENDOR_UNAVAILABLE_TEXT = 'vendor_unavailable';
export const isTextVendorUnavailable = (text) => text === VENDOR_UNAVAILABLE_TEXT;

export const RATE_UNAVAILABLE_TEXT = 'rate_unavailable';
export const isTextRateUnavailable = (text) => text === RATE_UNAVAILABLE_TEXT;

export const ITEM_NOT_INCLUDED = 'Item not Included';
export const RATE_UNAVAILABLE_LABEL_TEXT = 'No Vendor Bids Available';

export const VENDOR_SELECTED_TEXT = 'vendor_selected';

export const SELECTED_TEXT = 'selected';
export const VENDOR_NOT_SELECTED_TEXT = 'Vendor not selected';
export const RECEIVED_TEXT = 'received';
export const isTextSelected = (text) => text === SELECTED_TEXT;

export const CQ_RFQ_ADD_VENDOR = 'Cq Rfq Add Vendor';
export const isTextCqRfqAddVendor = (text) => text === CQ_RFQ_ADD_VENDOR;

export const INACTIVE_URL_ERROR_TEXT = 'No longer allowed to submit!';

export const auditTrailEvents = {
    sendQuotationForInternalApproval: 'sendQuotationForInternalApproval',
    sendQuotationToCustomer: 'sendQuotationToCustomer',
    resendQuotationToCustomer: 'resendQuotationToCustomer'
};
export const CQStatuses = {
    QUOTATION_DRAFT: 'quotation',
    CUSTOMER_PENDING: 'customer_pending',
    CUSTOMER_APPROVED: 'customer_approved',
    QUOTATION_SENT: 'quotation_sent',
    CPO_RECEIVED: 'quotation_received',
    DRAFT: 'draft',
    INTERNAL_PENDING: 'internal_pending',
    INTERNAL_APPROVED: 'internal_approved',
    VPO_GENERATED: 'vpo_generated',
    DRAFT_CONFIRMED: 'draft_confirmed',
    SO_DRAFT: 'so_draft',
    SO_CONFIRMED: 'so_confirmed',
    SO_SHORT_CLOSED: 'so_short_closed',
    INCONSISTENT_DATA: 'inconsistent_data'
};
export const VQStatuses = {
    NEW: 'new',
    SELECTED: 'selected',
    RECEIVED: 'received',
    RATE_UNAVAILABLE: 'rate_unavailable',
    EXPIRED: 'expired'
};

export const SOStatuses = {
    DRAFT: 'draft',
    CONFIRMED: 'confirmed',
    SHORTCLOSED: 'short_closed',
    VOID: 'void',
    COMPLETED: 'completed',
    NO_ITEM_INCLUDED: 'no_item_included'
};

export const InternalGenericMessage = {
    SUCCESS: 'Response received!',
    INVALID: "You cannot take any action on current quotation as it has been updated.You'll receive the updated quotation shortly."
};

export const ReqItemStatuses = {
    NEW: null,
    VENDOR_SELECTED: 'vendor_selected',
    CUSTOMER_APPROVED: 'customer_approved',
    VENDOR_NOT_SELECTED: 'vendor_not_selected'
};

export const ReqItemSoDraftStatuses = {
    NEW: null,
    SELECTED_FOR_SO: 'selected_for_so',
    NOT_SELECTED_FOR_SO: 'not_selected_for_so'
};

export const getPyamentTermsObject = (value) => {
    const val = paymentTermsOptions.find((paymentTerm) => paymentTerm.value === value);
    return val || { label: 'Custom', value };
};

export const getCustomOptionObject = (option, value) => {
    const val = option.find((item) => item.value == value);
    return val || { label: 'Custom', value };
};
export const getAdvancePaymentObject = (value) => {
    const val = advancePaymentOptions.find((advancePayment) => advancePayment.value === value);
    return val || { label: 'Custom', value };
};

export const getTermsForPayment = (terms, days) => {
    const regex = /Payment<.strong> - (.*?) days\b/;
    const replacement = `Payment</strong> - ${days} days`;

    return terms.replace(regex, replacement);
};

export const isQuillEditable = (text) => {
    const lines = text.trim().split('\n');
    if (lines[lines.length - 1] === '') {
        lines.pop();
    }
    return lines.length < shortTncAllowedLines + 1;
};

export const isTnCInvalid = (terms) => {
    const lines = terms.trim().split('</div>');

    return lines.length > shortTncAllowedLines;
};

export const checkIsRateFreezed = (status) => [CQStatuses.QUOTATION_SENT, CQStatuses.CUSTOMER_APPROVED, CQStatuses.CPO_RECEIVED, CQStatuses.SO_DRAFT, CQStatuses.SO_CONFIRMED, CQStatuses.SO_SHORT_CLOSED].includes(status);

// This function is also written in delivery ui. replicate the changes
export const getParsedTC = ({
    terms, days, advancePayment, transport, unloadingScope
}) => {
    let parsedTerms = terms || '';
    if (!advancePayment || Number(advancePayment) === 0) {
        parsedTerms = parsedTerms.replace(/<div><strong>Advance Payment<\/strong> - {{__advancePayment}}%<\/div>/, '');
        parsedTerms = parsedTerms.replace(/<div><strong>Advance<\/strong> - {{__advancePayment}}%<\/div>/, '');
    }
    if (Number(advancePayment) === 100) {
        parsedTerms = parsedTerms.replace(/<div><strong>Payment<\/strong> - {{__days}} days<\/div>/, '');
        parsedTerms = parsedTerms.replace(/<div><strong>Net<\/strong> - {{__days}} days<\/div>/, '');
    }
    if (days) {
        if (isTextDueOnReceipt(days) || isAfterSupplyText(days)) {
            parsedTerms = parsedTerms.replace('{{__days}} days', days);
        } else { parsedTerms = parsedTerms.replace('{{__days}}', days); }
    }
    if (advancePayment) {
        parsedTerms = parsedTerms.replace('{{__advancePayment}}', advancePayment);
    }
    if (transport) {
        if (transport === 'Transport') {
            parsedTerms = parsedTerms.replace(/<div><strong>Transportation<\/strong> - {{__transport}}<\/div>/, '');
        } else {
            parsedTerms = parsedTerms.replace('{{__transport}}', transport);
        }
    }
    if (unloadingScope) {
        parsedTerms = parsedTerms.replace('{{__unloadingScope}}', unloadingScope);
    }
    return parsedTerms;
};

export const getParsedTCWhatsapp = ({
    terms, days, advancePayment, transport, unloadingScope
}) => {
    let parsedTerms = getParsedTC({
        terms, days, advancePayment, transport, unloadingScope
    });
    parsedTerms = parsedTerms.replace(/<strong>/g, '<strong style="font-weight: 500;">');
    parsedTerms = parsedTerms.replace(/<div>/g, '<div style="color: #364054;font-size: 12px;font-family: Inter; font-weight: 400; line-height: 18px;">');

    return parsedTerms;
};

export const getParsedTCWhatsappV2 = (parsedTerms) => {
    parsedTerms = parsedTerms.replace(/<strong>/g, '<strong style="font-weight: 500;">');
    parsedTerms = parsedTerms.replace(/<div>/g, '<div style="color: #364054;font-size: 12px;font-family: Inter; font-weight: 400; line-height: 18px;">');

    return parsedTerms;
};

export const getParsedTCPdf = ({
    terms, days, advancePayment, transport, unloadingScope
}) => {
    let parsedTerms = getParsedTC({
        terms, days, advancePayment, transport, unloadingScope
    });
    parsedTerms = parsedTerms.replace(/<strong>/g, '<strong style="font-weight: 600; color:#364054;">');
    parsedTerms = parsedTerms.replace(/<div>/g, '<div style="color: #364054;font-size: 12px;font-family: Inter; font-weight: 400; line-height: 18px;">');

    return parsedTerms;
};
export const checkInvalidCharsInTnC = (terms, type) => {
    const element = document.createElement('div');
    element.innerHTML = terms;
    const text = element.innerText;
    const invalidCharactersUsed = invalidCharacters.filter((char) => text.includes(char));
    if (invalidCharactersUsed.length) {
        return `Please remove invalid characters like "${invalidCharactersUsed.join(', ')}" from ${type} T&C to proceed further.`;
    }
    return false;
};

export const checkInvalidCharsInYupValidator = {
    name: 'is-not-allowed',
    message: ({ value }) => {
        const invalidCharactersUsed = invalidCharacters.filter((char) => value?.includes(char));
        return `Please remove invalid characters like "${invalidCharactersUsed.join(', ')}" to proceed further.`;
    },
    test: (value) => invalidCharacters.every((char) => !value?.includes(char))
};

export const isTCPaymentTermsValid = (days, advancePayment, type = '', isDueOnReceivedVendorTnC = false, isAfterSupplyCustomerTnC = false) => {
    if (!days && !advancePayment) {
        return `Please provide ${type} T&C to proceed further!`;
    }
    if (Number(advancePayment) === 100 && days && Number(days) !== 0) {
        return `${type} payment terms days should not be specified at full advance payment`;
    }
    if (type === 'vendor' && Number(advancePayment) !== 100 && days < 1 && !isDueOnReceivedVendorTnC) {
        return `${type} payment terms days should be positive!`;
    }
    if (type === 'customer' && Number(advancePayment) !== 100 && days < 1 && !isAfterSupplyCustomerTnC) {
        return `${type} payment terms days should be positive!`;
    }
    if (!/^(100(\.00)?|([1-9]?\d(\.\d\d?)?))$/.test(advancePayment)) {
        return `${type} advance payment should be between 0 and 100! (upto 2 decimal places)`;
    }
    return null;
};
export const isUnloadingScopeValid = (unloadingScope, category) => {
    if (category === unloadingOptionCategory && !unloadingScope) {
        return 'Please provide unloading scope to proceed further!';
    }
    return null;
};

export const SEND_QUOTATION_TO_CUSTOMER_TEXT = 'sendQuotationToCustomer';

export const approvalHistoryEventNames = {
    CUSTOMER_QUOTATION_INTERNALLY_REJECTED: 'customerQuotationInternallyRejected',
    SEND_QUOTATION_FOR_INTERNAL_APPROVAL: 'sendQuotationForInternalApproval',
    RFQ_AND_CUSTOMER_QUOTATION_CREATED: 'rfqAndCustomerQuotationCreated',
    CUSTOMER_QUOTATION_APPROVED_BY_CUSTOMER: 'customerQuotationApprovedByCustomer',
    SEND_QUOTATION_TO_CUSTOMER: 'sendQuotationToCustomer',
    CUSTOMER_QUOTATION_INTERNALLY_APPROVED: 'customerQuotationInternallyApproved',
    CUSTOMER_QUOTATION_NEGOTIATED_BY_CUSTOMER: 'customerQuotationNegotiatedByCustomer'
};

export const CUSTOMER_QUOTATION_TEXT = 'customerQuotation';

export const CQ_EDIT_ACTION_MESSAGES = {
    customer_pending: 'Pending Customer approval link will be deactivated.',
    internal_pending: '',
    internal_approved: ''

};
export const inactiveUrlMessage = {
    buHead: 'Sorry! The details of this quotation have been updated. You’ll receive the latest Quotation shortly.',
    customer: 'Sorry! The details of this quotation have been updated. You’ll receive the latest Quotation shortly.',
    vendor: 'Sorry! The details of this RFQ have been updated. You’ll receive the latest RFQ shortly.'
};

export const cqStatusOrder = ['quotation', 'customer_pending', 'customer_approved', 'quotation_sent', 'quotation_received'];

export const mapAcceptedCategories = ['RMC', 'AGGREGATE'];
// please update these changes in API as well
export const WHATSAPP_MESSAGE_TYPES = {
    TEXT: 'text',
    IMAGE: 'image',
    FILE: 'file',
    AUDIO: 'audio',
    VIDEO: 'video',
    CONTACT: 'contact',
    LOCATION: 'location',
    BUTTON_REPLY: 'button_reply'
};

// please update these changes in API as well
export const WHATSAPP_MESSAGE_STATUSES = {
    PENDING: 'pending',
    NEW: 'new',
    FILED: 'filed',
    DISCARDED: 'discarded',
    INVALID_FILE: 'invalid_file',
    PARTIALLY_FILED: 'partially_filed'
};

// please update these changes in API as well
export const WHATSAPP_MESSAGE_FILE_TYPES = {
    IMAGEJPEG: 'image/jpeg',
    IMAGEJPG: 'image/jpg',
    IMAGEPNG: 'image/png',
    PDF: 'application/pdf'
};

export const WHATSAPP_MESSAGE_STATUS_SUBSTITUTES = {
    pending: 'Processing',
    new: 'New',
    filed: 'Filed',
    discarded: 'Discarded',
    invalid_file: 'Invalid File',
    partially_filed: 'Partially Filed'
};
export const WHATSAPP_MESSAGE_STATUS_COLOR = {
    pending: 'Intermediate',
    new: 'Newv1',
    filed: 'Complete',
    discarded: 'Error',
    invalid_file: 'Error',
    partially_filed: 'Intermediate'
};

export const SALES_ORDER_STATUSES = {
    DRAFT: 'draft',
    CONFIRMED: 'confirmed',
    SHORTCLOSED: 'short_closed',
    VOID: 'void',
    COMPLETED: 'completed',
    NO_ITEM_INCLUDED: 'no_item_included'
};

export const DELIVERY_STATUSES_COLORS = {
    pending: 'Intermediate',
    initiated: 'Newv1',
    inTransit: 'Intermediate',
    delivered: 'Complete',
    canceled: 'Error'
};

export const DELIVERY_STATUS = {
    PENDING: 'pending',
    IN_TRANSIT: 'inTransit',
    DELIVERED: 'delivered',
    CANCELLED: 'canceled'
};

export const purchaseOrderStatuses = {
    deliveryStatuses: {
        pending: 'Pending',
        notInitiated: 'Not Initiated',
        initiated: 'Initiated',
        inTransit: 'In Transit',
        delivered: 'Delivered',
        canceled: 'Cancelled'
    },
    variant: {
        pending: 'Intermediate',
        notInitiated: 'Newv1',
        initiated: 'Newv1',
        inTransit: 'Intermediate',
        delivered: 'Complete',
        canceled: 'Error'
    },
    documentStatuses: {
        pending: 'Pending',
        filed: 'Filed'
    },
    filterOptions: {
        deliveryStatuses: [
            { label: 'Delivery Initiated', value: 'delivery-initiated' },
            { label: 'Delivery In Transit', value: 'delivery-inTransit' },
            { label: 'Delivery Completed', value: 'delivery-delivered' }
        ],
        documentStatuses: [
            { label: 'Document Pending', value: 'document-pending' },
            { label: 'Document Filed', value: 'document-filed' },
            { label: 'All', value: '' }
        ]
    },
    whatsAppFilterOptions: [
        { label: 'Processing', value: 'pending' },
        { label: 'New', value: 'new' },
        { label: 'Filed', value: 'filed' },
        { label: 'Partially Filed', value: 'partially_filed' },
        { label: 'Discarded', value: 'discarded' },
        { label: 'All', value: '' }
    ]
};

export const deliveryTripStatuses = {
    deliveryStatuses: {
        pending: 'Pending',
        inTransit: 'In Transit',
        delivered: 'Delivered',
        canceled: 'Cancelled',
        abandoned: 'Abandoned',
        verifiedDC: 'Verified DC'
    },
    filterOptions: {
        deliveryStatuses: [
            { label: 'Delivery In Transit', value: 'delivery-inTransit' },
            { label: 'Delivery Completed', value: 'delivery-delivered' },
            { label: 'Delivery Cancelled', value: 'delivery-canceled' },
            { label: 'Verified DC', value: 'verifiedDC-true' },
            { label: 'All', value: '' }
        ]
    },
    acceptanceMode: {
        AUTOMATED: 'automated',
        MANUAL: 'manual'
    }
};

export const getThankYouMessageForFinalPdf = ({ isTypeOpenQuantity = false }) => {
    let message = 'Thank you very much for giving us the opportunity to quote and hope to serve your valued order soon.';
    if (isTypeOpenQuantity) {
        message = message.replace('quote', 'share our rate proposal');
    }
    return message;
};

export const tagMethods = (method) => {
    const methods = { ai: 'AI', agent: 'Agent' };

    return methods[method];
};

export const designations = {
    deliveryManager: 'Delivery Manager'
};

export const AUTO_APPROVE_CPO_USER_ID = '5274f00f-8dd0-498f-b32d-248fed17b94d';

export const SELF_FULLFILMENT_VENDOR = '25cad672-65f5-4019-acfd-1faa6c465402';

export const AUTO_APPROVE_CPO_CONTACT_DETAILS_ID = 'd4f5decc-2f99-4e26-a7c1-d63a1ee5fde4';

export const OPEN_TEXT = 'Open';

export const isTextOpen = (text) => text === OPEN_TEXT;

export const ROLES = {
    PARTNER_ADMIN: 'PartnerAdmin'
};

export const documentationNameOptions = [
    {
        value: 'custom',
        label: 'Custom'
    }
];

export const POStatuses = {
    DRAFT: 'draft',
    CONFIRMED: 'confirmed',
    SHORTCLOSED: 'short_closed',
    SENT: 'sent',
    PAUSED: 'paused',
    VOID: 'void',
    CLOSED: 'closed'
};

export const PO_DELIVERY_STATUS = {
    NOT_INITIATED: 'notInitiated',
    INITIATED: 'initiated',
    COMPLETED: 'completed',
    IN_TRANSIT: 'inTransit',
    DELIVERED: 'delivered',
    CANCELLED: 'canceled'
};

export const getCurrentFinancialYear = () => {
    const today = new Date();
    // getMonth() gives current month index (0-11)
    const currentMonth = today.getMonth();
    let financialYear = '';
    // greater than march month (index of march is 2)
    if (currentMonth > 2) {
        const nextYear1 = (today.getFullYear() + 1).toString();
        financialYear = today.getFullYear().toString().slice(2) + nextYear1.slice(2);
    } else {
        const nextYear2 = today.getFullYear().toString();
        financialYear = (today.getFullYear() - 1).toString().slice(2) + nextYear2.slice(2);
    }
    return financialYear;
};

const getLastTwoDigits = (num) => num.toString().slice(-2);

export const getCurrentFinancialYearWithHyphen = () => {
    let fiscalyear = '';
    const today = new Date();
    if ((today.getMonth() + 1) <= 3) {
        fiscalyear = `${getLastTwoDigits(today.getFullYear() - 1)}-${getLastTwoDigits(today.getFullYear())}`;
    } else {
        fiscalyear = `${getLastTwoDigits(today.getFullYear())}-${getLastTwoDigits(today.getFullYear() + 1)}`;
    }
    return fiscalyear;
};

export function getCustomerName({
    tradeName, organisationName, documentationName, showLegalName = true
}) {
    const tradeNameDisplay = tradeName || organisationName;
    return documentationName || (showLegalName ? organisationName : tradeNameDisplay);
}

export function getVendorName({
    tradeName, organisationName, documentationName, showLegalName = true
}) {
    const tradeNameDisplay = tradeName || organisationName;
    return documentationName || (showLegalName ? organisationName : tradeNameDisplay);
}

export function getDocumentationName({ documentationName, organisationName }) {
    return documentationName || organisationName;
}

export function getDisplayNameForInternalUse({ displayName }) {
    return displayName;
}

export const DELIVERY_ACCEPTANCE_CHANNEL_SUBSTITUTES = {
    email_link: 'E-mail link',
    whatsapp_link: 'WhatsApp link',
    agent_tagged_challan: 'Cara tagged',
    cara_tagged_challan: 'Cara tagged',
    customer_delivery_app: 'Customer Delivery App'
};

export const DELIVERY_ACCEPTANCE_CHANNEL_OPTIONS = [{
    isSelected: false,
    label: 'E-mail link',
    value: 'email_link'

},
{
    isSelected: false,
    label: 'WhatsApp link',
    value: 'whatsapp_link'

},
{
    isSelected: false,
    label: 'Cara tagged',
    value: 'agent_tagged_challan,cara_tagged_challan'

},
{
    isSelected: false,
    label: 'Customer Delivery App',
    value: 'customer_delivery_app'

}];
export function formatGeneralTC(htmlString) {
    try {
        // Create a new HTML element to parse the HTML string
        const div = document.createElement('div');
        div.innerHTML = htmlString;

        // Get the original list and the 'ql-indent-1' list items
        const originalList = div.querySelector('ol');
        const indent1ListItems = div.querySelectorAll('li.ql-indent-1');

        if (indent1ListItems.length === 0) {
            // return original TC if there is not sublist
            return (htmlString);
        }
        // Create a new ordered list for sublist with sequencing lower-roman
        const newOL = document.createElement('ol');
        newOL.setAttribute('type', 'i');

        // Iterate through the 'ql-indent-1' list items and add them to the new list
        indent1ListItems.forEach((item) => {
            const newLI = document.createElement('li');
            newLI.textContent = item.textContent;
            newOL.appendChild(newLI);
            item.parentNode.removeChild(item);
        });

        // Insert the new ordered list after the third element
        const thirdLi = originalList.querySelectorAll('li')[2];
        thirdLi.parentNode.insertBefore(newOL, thirdLi.nextSibling);

        // Get the HTML content of the transformed element
        const transformedContent = div.innerHTML;
        return transformedContent;
    } catch (err) {
        console.log('Error happened when converting long terms into the required format', err);
    }
}

export const PO_REALLOCATED_ERROR_MESSAGE = 'This Purchase Order has been disabled for any action. Reallocation is in progress.';
export const CLASSIFICATION_DOCUMENT_SOURCE = {
    VENDOR: 'vendor',
    CUSTOMER: 'customer',
    ARIS: 'aris'
};

export function areFloatsEqual(a, b, tolerance = 1e-9) {
    return Math.abs(a - b) < tolerance;
}

export const VENDOR_PSEUDO_TRANSPORT_TYPE_LABEL = {
    material_transport: 'Material + Transport',
    material: 'Material',
    transport: 'Transport',
    service: 'Service'
};

export const VENDOR_PSEUDO_TRANSPORT_TYPE_OPTIONS = [
    { label: 'Material + Transport', value: 'material_transport' },
    { label: 'Material only', value: 'material' },
    { label: 'Transport only', value: 'transport' }
];

export const VENDOR_PSEUDO_TRANSPORT_TYPE = {
    MATERIAL_TRANSPORT: 'material_transport',
    MATERIAL: 'material',
    TRANSPORT: 'transport',
    SERVICE: 'service'
};

export const VENDOR_TRANSPORT_TYPE = {
    INCLUDED: 'Included',
    EXCLUDED: 'Excluded',
    EX_SITE: 'Ex-site',
    TRANSPORT: 'Transport',
    SERVICE: 'Service'
};

export const CUSTOMER_TRANSPORT_TYPE = {
    INCLUDED: 'Included',
    EXCLUDED: 'Excluded',
    EX_SITE: 'Ex-site'
};

export const accountingQuantityOptions = {
    shippedQuantity: 'shippedQuantity',
    receivedQuantity: 'receivedQuantity'
};

export const PERMISSION_CONSTANT = {
    NANO_REPORT: 'nano-report',
    NANO_REPORT_CUSTOMER: 'nano-report-customer',
    NANO_REPORT_VENDOR: 'nano-report-vendor',
    NANO_REPORT_CRM: 'nano-report-crm',
    NANO_REPORT_CUSTOMER_CASH: 'nano-report-customer-cash',
    NANO_REPORT_CUSTOMER_CREDIT: 'nano-report-customer-credit',
    NANO_REPORT_CUSTOMER_FLOW: 'nano-report-customer-flow',
    DELIVERY_WHATSAPP_RECIPIENT: 'delivery-whatsapp-recipient',
    EDIT_KYC_INFO: 'edit-kyc-info',
    PARTNER_ADMIN: 'partner-admin',
    NANO_REPORT_CUSTOMER_EXEC_REPORT: 'nano-report-customer-exec-report',
    PAYMENT_REQUEST: 'payment-request',
    CUSTOMER_FINANCIAL_REPORT: 'customer-financial-report',
    VENDOR_PAYMENT_REQUEST: 'vendor-payment-request',
    UPDATE_L1L4: 'update-l1-l4',
    VENDOR_PAYMENT_REQUEST_READ_ONLY: 'af-payment-request-read-only'
};

export function formatListWithAnd(array) {
    if (array.length === 0) {
        return '';
    } if (array.length === 1) {
        return array[0];
    } if (array.length === 2) {
        return `${array[0]} and ${array[1]}`;
    }
    const lastItem = array.pop();
    const formattedList = array.join(', ');
    return `${formattedList}, and ${lastItem}`;
}

export const reqCqSoPoPage = ['/requirements/[id]', '/customer-quotations/[id]', '/sales-orders/[id]', '/purchase-orders/[id]'];

export const tncSettings = {
    main: 'main',
    general: 'general',
    material: 'material',
    transport: 'transport',
    service: 'service'
};

export const TransportTypes = {
    included: 'Included',
    excluded: 'Excluded',
    exsite: 'Ex-site'
};

export const tncSettingFields = {
    paymentTerm: 'paymentTerm',
    transportation: 'transportation',
    unloading: 'unloading',
    offerValidity: 'offerValidity'
};
export const vendorPaymentRequestStatus = {
    draft: 'Draft',
    waiting_bu_approval: 'Approval Pending',
    bu_approved: 'Approved',
    bu_rejected: 'Rejected',
    sent_final_approval: 'Final Approval',
    bu_approval_expired: 'Expired',
    deactivated: 'Deactivated'
};

export const APPROVAL_ACTION_STATUS_SUBSTITUTES = {
    not_sent: 'Not Sent',
    waiting: 'Approval Pending',
    approved: 'Approved',
    rejected: 'Rejected',
    expired: 'Expired'
};

export const VENDOR_PAYMENT_REQUEST_STATUSES = {
    DRAFT: 'draft',
    WAITING_BU_APPROVAL: 'waiting_bu_approval',
    BU_APPROVED: 'bu_approved',
    BU_REJECTED: 'bu_rejected',
    SENT_FINAL_APPROVAL: 'sent_final_approval',
    BU_APPROVAL_EXPIRED: 'bu_approval_expired',
    DEACTIVATED: 'deactivated'
};

export const APPROVAL_ACTION_STATUSES = {
    NOT_SENT: 'not_sent',
    WAITING: 'waiting',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    EXPIRED: 'expired'
};

export const APPROVAL_ACTION_STATUS_VARIANT = {
    not_sent: 'Newv1',
    waiting: 'Intermediate',
    approved: 'Complete',
    rejected: 'Error',
    expired: 'Error'
};

export const VENDOR_PAYMENT_REQUEST_VARIANT = {
    draft: 'Newv1',
    waiting_bu_approval: 'Intermediate',
    bu_approved: 'Complete',
    bu_rejected: 'Error',
    sent_final_approval: 'Complete',
    bu_approval_expired: 'Error',
    deactivated: 'Error'
};

export const arisBranches = [
    'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Nagpur', 'Nasik',
    'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Pune',
    'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram',
    'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Delhi',
    'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Mumbai'
];

export const extractState = (branchName) => arisBranches.find((state) => branchName?.includes(state)) || null;

export const salesPersonType = {
    founder: 'founder', businessOwner: 'business_owner', teamLead: 'team_lead', salesPerson: 'sales_person', vendorApprover: 'vendor_approver'
};

export const readyForExecApprovalVPRStatus = ['bu_approved', 'sent_final_approval'].join(',');

export const CLOUD_PLATFORMS = [
    {
        id: 1,
        name: 'ArisInfra Solutions',
        logo: '/images/arislogov2.svg',
        url: process.env.NEXT_PUBLIC_UI_URL,
        identifer: 'arisFlow',
        className: 'h-5'
    },
    {
        id: 2,
        name: 'BuildMex',
        logo: '/images/buildmexlogov2.svg',
        url: process.env.NEXT_PUBLIC_BUILD_MEX_UI_URL,
        identifer: 'buildmex',
        className: 'h-[18px]'
    },
    {
        id: 3,
        name: 'Trading',
        logo: '/images/ArisTrading.svg',
        url: process.env.NEXT_PUBLIC_TRADING_UI_URL,
        identifer: 'trading',
        className: 'h-5'
    },
    {
        id: 4,
        name: 'WhiteRoots',
        logo: '/images/whiteroot_logo.svg',
        url: process.env.NEXT_PUBLIC_WHITE_ROOT_UI_URL,
        identifer: 'whiteroot',
        className: ''
    },
    {
        id: 5,
        name: 'Aris Construction Materials',
        logo: '/images/Aris-CM.svg',
        url: process.env.NEXT_PUBLIC_ARIS_CM_UI_URL,
        identifer: 'ariscm',
        className: ''

    }
];

export const errorConstants = {
    FORBIDDEN: 'forbidden'
};

export const isTextVendor = (text) => text === 'vendor';

export const isTextCustomer = (text) => text === 'customer';
export const vendorPaymentRequestDecimalPoint = 2;
